import { formatDateForCatchupDays } from './date'

function sortCathupsListByStartDate(list) {
  return list.sort((a, b) => {
    const aDate = a.startSec * 1000
    const bDate = b.startSec * 1000

    if (aDate === bDate) {
      return a.name > b.name ? 1 : -1
    }

    return aDate - bDate
  })
}

/**
 * Return new List {Array} with 'parts of cathups chunks'
 * @param {array} sortedList by startDate
 * @returns {Array} map with "part" value
 */
function addPartsToCatchups(sortedList) {
  const list = sortedList.map((item, i, array) => {
    if (item.name.includes('-')) {
      const name = item.name.split('-')
      const char = name.pop()

      if (!isNaN(Number(char))) {
        item.part = Number(char) + 1
        item.name = name.shift()

        const previous = i ? i - 1 : 0
        array[previous].part = Number(char)
      }
    }

    return item
  })

  return list
}

/**
 * Return map {Array} by Days
 * @param {array} catchupsList - sorted data (from serever); list of programs
 * @returns {Array} map by DaysString like a '2021-11-15'
 */
export function mapCatchupsToDays(catchupsList) {
  const days = {}
  const sortedList = addPartsToCatchups(
    sortCathupsListByStartDate(catchupsList)
  )

  sortedList.forEach((item) => {
    const key = formatDateForCatchupDays(item.startSec * 1000)

    if (days[key]) {
      days[key].push(item)
    } else {
      days[key] = []
      days[key].push(item)
    }
  })

  return days
}
