import React from 'react'
import { useStore } from 'effector-react'
import { $form, $id } from '/models/registration'
import { MessageContainer } from '/components/login/components/error_components'
import { VerifyAccount } from '/components/login/components/forms/VerifyAccount'
import { SignIn } from '/components/login/components/forms/SignIn'
import { Login } from '/components/login/Login'
import { SignUp } from '/components/login/components/forms/SignUp'
import { ResetPasswordForm } from '/components/login/components/forgotPassword/ResetPassword'
import { VerifyCodeForm } from '/components/login/components/forgotPassword/VerifyCode'
import { ChangePasswordForm } from '/components/login/components/forgotPassword/ChangePassword'
import { FORM_TYPE } from '/utils/constants'

import s from '../../login.module.scss'

export const FormBox = (): JSX.Element => {
  const form = useStore($form)
  const id = useStore($id)

  const switchForms = (param) => {
    switch (param) {
      case FORM_TYPE.SIGN_IN:
        return <SignIn />

      case FORM_TYPE.SIGN_UP:
        return <SignUp />

      case FORM_TYPE.RESET_PASSWORD:
        return <ResetPasswordForm />

      case FORM_TYPE.VERIFY_RESET_CODE:
        return <VerifyCodeForm />

      case FORM_TYPE.CHANGE_PASSWORD:
        return <ChangePasswordForm />

      default:
        return null
    }
  }

  return (
    <>
      {/* hack for old Login form */}
      {form === FORM_TYPE.LOGIN ? (
        <Login />
      ) : (
        <div className={s.loginWrap}>
          {id ? <VerifyAccount id={id} /> : switchForms(form)}

          {/* hack for old Login form */}
          {form !== FORM_TYPE.LOGIN && <MessageContainer />}
        </div>
      )}
    </>
  )
}
