import { replaceSizeHelper, removeDuplicateObjects } from '/utils/helpers'
import { TvChannel } from '../interfaces'

/**
 * @param {string} src - url for content resources
 * @returns {function} return decorate function
 */
export function getChannelLogo(src: string) {
  return replaceSizeHelper(src, '170x100')
}

/**
 * @param {string} src - url for content resources
 * @returns {function} return decorate function
 */
export function getChannelLogoSmall(src: string) {
  return replaceSizeHelper(src, `53x31`)
}

/**
 * Function adds recently watched Live channels
 *
 * @param {array} channelsList - list of channels
 * @param {number} channel - channel to add
 * @returns {array} return array of channels
 */
export const addRecentlyWatchedChannel = (
  channelsList: TvChannel[],
  channel: TvChannel
): TvChannel[] => {
  if (channel.locked) return channelsList
  const list = [channel].concat(channelsList)
  return removeDuplicateObjects(list)
}
