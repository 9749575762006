import { domain } from '.'
import i18n from '/services/locales/i18n'
import { config } from '/config'

const { createEvent, createStore } = domain('settings')

export const setAppLanguage = createEvent<string>()

export const $appLanguage = createStore<string>(i18n.language).on(
  setAppLanguage,
  (_, code) => code
)

export const $isAppLanguageEnabled = config.APP_LANGUAGE_ENABLED
