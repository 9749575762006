import { categoriesPath, Route } from '~/root/routes'
import { TvHomePage } from '/components/tv/pages/home'
import { TvDetailsPage } from '/components/tv/pages/details'
import { VodCategoryPage } from '/components/tv/pages/category'

export const path = `tv`

export const tvRoutes = (tvEnabled: boolean): Route[] => [
  {
    path: `/${path}`,
    name: 'Live',
    component: TvHomePage,
    enable: tvEnabled,
    main: true,
  },
  {
    path: `/${path}/:channelId`,
    name: 'Live',
    component: TvDetailsPage,
    enable: tvEnabled,
  },
  {
    path: `/${path}/${categoriesPath}/:categoryId`,
    name: 'Live',
    component: VodCategoryPage,
    enable: tvEnabled,
  },
  {
    path: `/${path}/${categoriesPath}/:categoryId/:channelId`,
    name: 'Live',
    component: TvDetailsPage,
    enable: tvEnabled,
  },
]
