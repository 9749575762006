import videojs from 'video.js'

import './Rewind.scss'

const Button = videojs.getComponent('Button')
const Component = videojs.getComponent('Component')

class Rewind extends Button {
  controlText_ = 'Rewind'

  buildCSSClass() {
    return `vjs-rewind-control ${super.buildCSSClass()}`
  }
}

Rewind.prototype.controlText_ = 'Rewind'
Component.registerComponent('Rewind', Rewind)
