import React from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { setModal } from '/components/common/modal/model'
import { Carousel } from '../common/carousel/Carousel'
import { CardTypes } from '/utils/helpers'
import { useNavigate } from '@reach/router'
import { TvChannel } from '/components/tv/interfaces'
import { TvChannelCard } from '../common/cards/tv-channel-card'
import { GEOBLOCK_MESSAGE } from '/utils/constants'

interface TvCarouselProps {
  id: string
  title: string
  cardType: number
  carousel: { content: any[] }
  handleMovieClick?: (channel: TvChannel) => void
}

const TvCarouselContainer = ({
  id,
  title = '',
  carousel,
  cardType,
  handleMovieClick,
}: TvCarouselProps) => {
  const maxLength = 36
  const navigate = useNavigate()
  const { t } = useTranslation()

  const handleClick = (channel) => {
    if (channel?.blockedByAcl) {
      setModal({
        text: t(`common:message.${GEOBLOCK_MESSAGE}`, {
          item: `"${channel.name}"`,
        }),
      })
      return
    }

    handleMovieClick && handleMovieClick(channel)
    navigate(`/tv/categories/0/${channel.id}`)
  }

  return carousel?.content?.length ? (
    <Carousel
      id={id}
      key={"'featuredChannels'"}
      carouselTitle={title}
      className='featured'
      showAllOption={false}
    >
      {carousel &&
        carousel.content
          ?.slice(0, maxLength)
          .map(({ channel }) => (
            <TvChannelCard
              className={
                cardType === CardTypes.tvChannelFeaturedCard
                  ? 'tv-channel-card_featured'
                  : ''
              }
              key={`${channel.id}/${channel.name}`}
              channelTitle={`${channel.channelNumber}. ${channel.name}`}
              channelLogoUrl={channel.logoUrl}
              handleClick={() => handleClick(channel)}
              locked={channel.locked}
            />
          ))}
    </Carousel>
  ) : null
}

function mapStateToProps(state) {
  return {
    carousel: state.views.tv.carousels?.find(
      (item) => item.type === 'TV_CHANNEL'
    ),
  }
}

const mapDispatchToProps = {}

export const TvCarousel = connect(
  mapStateToProps,
  mapDispatchToProps
)(TvCarouselContainer)
