import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '~/components/common/button'
import { TvShow } from '../../interfaces'

import './styles.scss'

interface TvShowInfoProps {
  tvShow: TvShow
}

export const TvShowInfo = (props: TvShowInfoProps): JSX.Element => {
  const { ageRatings, year, directors, stars, description, name } = props.tvShow
  const [isOpen, setIsOpen] = useState(false)

  const { t } = useTranslation('tvshow')

  const toggleAccordion = () => setIsOpen((prev) => !prev)
  return (
    <div className='accordeon-info'>
      <p className='accordeon-info__title short-info'>
        {ageRatings ? <span className=''>{ageRatings}</span> : null}
        {year ? <span className=''>{year}</span> : null}
      </p>
      {directors || stars || description ? (
        <>
          <div
            className={`accordeon-info__wrapper ${isOpen ? 'open' : 'compact'}`}
          >
            {directors && (
              <>
                <h3>{t('Directors')}</h3>
                <p className='info-paragraph'>{directors}</p>
              </>
            )}
            {stars && (
              <>
                <h3>{t('Stars')}</h3>
                <p className='info-paragraph'>{stars}</p>
              </>
            )}
            {description && (
              <>
                <h3>{t('Description')}</h3>
                <p className='info-paragraph'>{description}</p>
              </>
            )}
          </div>
          <Button
            handleClick={toggleAccordion}
            title={
              <>
                <span className='button__text'>{`${name} ${t(
                  'Details'
                )}`}</span>
                <FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} />
              </>
            }
            className='accordeon-info__toggler '
          />
        </>
      ) : null}
    </div>
  )
}
