import { faHeart } from '@fortawesome/pro-regular-svg-icons'
import { faHeart as faHeartSolid } from '@fortawesome/pro-solid-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { memo } from 'react'
import Image from '../../Image'
import { useStore } from 'effector-react'
import { $backendVersion } from '/models/account'

import '../styles.scss'

export interface ICardProps {
  imageUrl: string
  title: string
  handleClick: () => void
  favorite?: boolean
  handleFavoriteClick?: () => void
}

const HorizontalCardComponent = (props: ICardProps): JSX.Element => {
  const { imageUrl, title, handleClick, favorite, handleFavoriteClick } = props
  const backendVersion = useStore($backendVersion)
  return (
    <div className='card card-horizontal' onClick={handleClick}>
      <Image imageUrl={imageUrl} defaultUrl={'/img/noContentIcon.svg'} />

      <div className='show-on-hover'>{title}</div>
      {backendVersion[0] >= '1.42' && handleFavoriteClick && (
        <div
          className={`add_to_favorite ${favorite ? 'liked' : ''}`}
          onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            event.stopPropagation()
            handleFavoriteClick()
          }}
        >
          <FontAwesomeIcon icon={favorite ? faHeartSolid : faHeart} />
        </div>
      )}
    </div>
  )
}

export const HorizontalCard = memo(HorizontalCardComponent)
