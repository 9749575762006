import common from './common.json'
import auth from './auth.json'
import home from './home.json'
import tv from './tv.json'
import vod from './vod.json'
import tvshow from './tvshow.json'
import catchup from './catchup.json'
import epg from './epg.json'
import settings from './settings.json'

export default { common, auth, home, tv, vod, tvshow, catchup, epg, settings }
