import { domain, forward, guard, combine } from '.'
import { $account } from './account'
import { config } from '/config'

// create child domain
const { createEvent, createEffect, createStore } = domain('push-loader')

// *
// * events
// *

export const importPushModule = createEvent()

// *
// * effects
// *

export const importPushModuleFx = createEffect(
  () => import('../components/push')
)

// *
// * stores
// *

export const $pushModule = createStore<any>(null).on(
  importPushModuleFx.doneData,
  (_, module) => module
)

// *
// * connections
// *

forward({
  from: importPushModule,
  to: importPushModuleFx,
})

guard({
  source: combine(
    $account,
    $pushModule,
    (account, module) =>
      module == null &&
      account != null &&
      config.APP_FIREBASE_ENABLED &&
      config.APP_FIREBASE_MESSAGING_ENABLED
  ),
  filter: (cond) => cond,
  target: importPushModule,
})
