import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { Link, navigate, RouteComponentProps, useLocation } from '@reach/router'
import { useStore } from 'effector-react'
import { $chatModule } from '/models/chat'
import { useTranslation } from 'react-i18next'
import { Player } from '/components/common/player/Player'
import { PinLockContainer } from '../../common/player/PinLock'
import {
  getTvActiveChannel,
  getTvActiveProgram,
  getTvCategory,
  getTvChannelById,
  getAllTvChannels,
} from '/components/tv/store/selectors'
import {
  getChannelLogo,
  getRandomLockedChannelPoster,
  removeDuplicateValues,
  parseJson,
  getChannelLogoSmall,
} from '/utils/helpers'
import { pingLiveRewindSupportUrl } from '/services/api/v3/channels'
import { usePrevious } from '/utils/hooks'
import { Popup } from '/components/common/popups'
import Image from '~/components/common/Image'
import { Button } from '/components/common/button'

import { actions as tvActions } from '/components/tv/store'
import './styles.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock } from '@fortawesome/pro-solid-svg-icons'
import { PlayerPreloader } from '~/components/common/Preloaders'
import Storage from '/utils/storage'
import { hidePreloader, showPreloader } from '~/models/preloader'
import { GEOBLOCK_MESSAGE } from '/utils/constants'

interface Props extends RouteComponentProps, ConnectedProps<typeof connector> {
  categoryId: string
  channelId: string
}

function TvDetails(props: Props) {
  const {
    category,
    channel,
    categoryId = 0,
    activeChannel,
    getChannel,
    setActiveCategory,
    setActiveChannel,
    rewindTvChannel,
    activeProgram,
    channels,
    unlock,
    channelId,
  } = props

  const REWIND_STEP = 30000 // 30 sec
  const liveRewindSupportInterval = 5000

  const [liveRewind, setLiveRewind] = useState(false)
  const [liveRewindFromSec, setLiveRewindFromSec] = useState(null)

  const prevChannel: any = usePrevious({ channel })

  const ChatModule = useStore($chatModule)
  const [isChatActive, setChatActive] = useState(false)

  const { t } = useTranslation()

  const handleChat = () => setChatActive((isChatActive) => !isChatActive)

  const pageUrl = useLocation()

  useEffect(() => {
    const previousChannel: any = prevChannel?.channel
    const fromSec = activeChannel?.fromSec
    setLiveRewindFromSec(fromSec || null)
    if (previousChannel?.id !== activeChannel?.id && liveRewind) {
      setLiveRewind(false)
    }

    let timer = 0
    const continueUrl = activeChannel?.continueUrl || ''
    if (liveRewind && continueUrl) {
      timer = setInterval(() => {
        pingLiveRewindSupportUrl(continueUrl)
      }, liveRewindSupportInterval)
    }
    return () => clearInterval(timer)
  }, [activeChannel])

  useEffect(() => {
    pageUrl.pathname.includes('//') && navigate('/not_found')

    return () => {
      setActiveChannel({ ...activeChannel, channelUrl: null })
    }
  }, [])

  const saveRecentChannelsIds = (channel) => {
    if (channel.locked) return
    const existedIds = Storage.getItem('recentTVChannelsIds')
    let list
    if (existedIds) {
      list = parseJson(existedIds) || []
    }
    Storage.setItem(
      'recentTVChannelsIds',
      removeDuplicateValues([channel.id].concat(list || []))
    )
  }

  useEffect(() => {
    if (!channels?.length) {
      showPreloader()
    } else {
      if (categoryId) {
        category && channel
          ? (getChannel(channel), saveRecentChannelsIds(channel))
          : navigate('/not_found')
      } else {
        const channel = channels.find((c) => c.id === Number(channelId))
        channel
          ? (setActiveCategory(0),
            getChannel(channel),
            saveRecentChannelsIds(channel))
          : navigate('/not_found')
      }
    }
    return hidePreloader()
  }, [channels])

  const rewindBack = useCallback(() => {
    setLiveRewind(true)
    const fromTime = (liveRewindFromSec || Date.now()) - REWIND_STEP
    rewindTvChannel({ channel: activeChannel, startTime: fromTime }).then(() =>
      unlock()
    )
  }, [activeChannel, liveRewindFromSec])

  const rewindForward = useCallback(() => {
    const now = Date.now()
    const fromTime = liveRewindFromSec || now
    const nextRewindTime = fromTime + REWIND_STEP

    if (nextRewindTime + REWIND_STEP >= now) {
      if (liveRewind) {
        getChannel(activeChannel)
      }
    } else {
      rewindTvChannel({
        channel: activeChannel,
        startTime: nextRewindTime,
      }).then(() => unlock())
    }

    setLiveRewind(true)
  }, [liveRewind, getChannel, activeChannel, rewindTvChannel])

  const lockedChannelUrl = useMemo(
    () => getRandomLockedChannelPoster(),
    [activeChannel.id]
  )

  return channel && activeChannel ? (
    <div className='tv-channel-player-page'>
      <div
        className={`tv-channel-player-page__content ${
          isChatActive ? 'scrolable' : 'fullpage'
        }`}
      >
        <div className='page-search-breadcrumbs-wrapper'>
          <div className='breadcrumbs'>
            <Link to={'/tv'}>{t('Live')}</Link>
            {category && (
              <Link to={`/tv/categories/${category?.id}`}>
                {category.name === 'All' ? t('All') : category.name}
              </Link>
            )}
            <span>{`${activeChannel.channelNumber}. ${activeChannel.name}`}</span>
          </div>
        </div>
        <div className='tv-channel-player__outer'>
          <div className='tv-channel-player__inner'>
            {activeChannel?.blockedByAcl ? (
              <Popup className='player-popup'>
                <h1 className='popup-title'>{t('common:Sorry')}!</h1>
                <p className='popup-text'>
                  {t(`common:message.${GEOBLOCK_MESSAGE}`, {
                    item: `"${activeChannel.name}"`,
                  })}
                </p>
              </Popup>
            ) : activeChannel.locked ? (
              <PinLockContainer
                autoFocus={true}
                title={`${activeChannel.channelNumber}. ${activeChannel.name}`}
              />
            ) : activeChannel.channelUrl ? (
              <Player
                src={activeChannel.channelUrl}
                drm={activeChannel.drm}
                skin='tv'
                hasLiveRewind={activeChannel.liveRewind}
                rewindForward={rewindForward}
                rewindBack={rewindBack}
                contentId={activeChannel.id}
                contentTitle={activeChannel.name}
                contentLive={true}
              />
            ) : liveRewind ? (
              <Popup className='player-popup'>
                <p className='popup-text'>
                  {t('message.LiveRewind for this Channel is unavailable now.')}
                  <br />
                  {t('message.Please reload the page to continue watching')}
                  <br />
                  {`${activeChannel.channelNumber}. ${activeChannel.name}`}
                </p>
                <Button
                  title={t('button.Reload')}
                  className='popup-button button-small button-ghost'
                  handleClick={() => getChannel(activeChannel)}
                />
              </Popup>
            ) : (
              <Popup className='player-popup'>
                <PlayerPreloader />
              </Popup>
            )}
            {ChatModule &&
            channel &&
            channel.chatAllowed &&
            !activeChannel.locked ? (
              <ChatModule.ChatButtonContainer
                channelId={channel.id}
                handleChat={handleChat}
              />
            ) : null}
          </div>
          <div className='channel-info'>
            <div
              className={`channel__logo ${
                activeChannel.locked ? 'channel__logo--locked' : ''
              }`}
            >
              {activeChannel.locked ? (
                <img src={lockedChannelUrl} />
              ) : channel?.logoUrl ? (
                <Image
                  imageUrl={getChannelLogo(channel?.logoUrl!)!}
                  secondaryImageUrl={getChannelLogoSmall(channel.logoUrl!)!}
                  defaultUrl={'/img/noLogo.svg'}
                  className='channel__logo-img'
                />
              ) : (
                <img src={'/img/noLogo.svg'} />
              )}{' '}
              {activeChannel.locked ? (
                <FontAwesomeIcon icon={faLock} className='locked-icon' />
              ) : null}
            </div>

            <div>
              <div className='channel__title'>{activeChannel.name}</div>
              {activeProgram ? (
                <div className='channel__short-info'>{activeProgram.name}</div>
              ) : null}
            </div>
          </div>
          {/* <div className='channel__what-is-next'>
              <div className='what-is-next__title'>{'What is next'}</div>
              <p>
                <span>08:30 PM &#8212; 09:20 PM</span>
                <span>&#8213;</span>
                <span>Program Name</span>
              </p>
              <p>
                <span>08:30 PM &#8212; 09:20 PM</span>
                <span>&#8213;</span>
                <span>Program Name</span>
              </p>
              <p>
                <span>08:30 PM &#8212; 09:20 PM</span>
                <span>&#8213;</span>
                <span>Program Name</span>
              </p>
              <p>
                <span>08:30 PM &#8212; 09:20 PM</span>
                <span>&#8213;</span>
                <span>Program Name</span>
              </p>
              <p>
                <span>08:30 PM &#8212; 09:20 PM</span>
                <span>&#8213;</span>
                <span>Program Name</span>
              </p>
              <p>
                <span>08:30 PM &#8212; 09:20 PM</span>
                <span>&#8213;</span>
                <span>Program Name</span>
              </p>
            </div> */}
        </div>
      </div>
      <aside className={`aside__container ${isChatActive ? 'open' : ''}`}>
        {ChatModule && channel && channel.chatAllowed && (
          <ChatModule.ChatContainer
            channelId={channel.id}
            isChatActive={isChatActive}
            handleChat={() => setChatActive(false)}
          />
        )}
      </aside>
    </div>
  ) : (
    <> </>
    // <NoContent
    //   icon={<FontAwesomeIcon icon={faVideoSlash} className='noContentIcon' />}
    //   title={t('No content')}
    // >
    // <div className='tv-channel-player-page'>
    //   <div className='page-search-breadcrumbs-wrapper'>
    //     <TvSearchBarContainer />
    //   </div>
    // </div>
    // </NoContent>
  )
}

function mapStateToProps(state, props) {
  return {
    channel: getTvChannelById(state, props),
    activeChannel: getTvActiveChannel(state),
    category: getTvCategory(state, props),
    activeProgram: getTvActiveProgram(state),
    channels: getAllTvChannels(state),
  }
}

const mapDispatchToProps = {
  getChannel: tvActions.changeChannel,
  setActiveChannel: tvActions.setActiveChannel,
  setActiveCategory: tvActions.setActiveCategory,
  rewindTvChannel: tvActions.rewindTvChannel,
  unlock: tvActions.unlockChannel,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export const TvDetailsPage = connector(TvDetails)
