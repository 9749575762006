import React from 'react'
import './styles.scss'

export const EmptyCarousel = () => {
  const itemQuantity =
    window.innerWidth > 1024
      ? Math.round(window.innerWidth / 178)
      : Math.round(window.innerWidth / 127)
  return (
    <div className={'empty-carousel'}>
      <div className='empty-carousel__title'></div>
      <div className='empty-carousel__content'>
        {Array.from(Array(itemQuantity)).map((i) => (
          <div key={`${i}__${i}`} className='empty-carousel__card' />
        ))}
      </div>
    </div>
  )
}
