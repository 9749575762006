import { withTranslation } from 'react-i18next'
import { faLockAlt, faUserCircle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { ErrorMessage } from './ErrorMessage'
import { customize } from '/config'

class LoginBox extends React.Component {
  emailRef = null
  passwordRef = null

  submit = (event) => {
    const { doLogin } = this.props
    event.preventDefault()

    const email = this.emailRef.value
    const password = this.passwordRef.value
    if (email && password) {
      doLogin({ email, password })
    }
  }
  render() {
    const { loginError, t } = this.props
    return (
      <div
        key='providerId'
        className={`login-inner ${loginError ? 'errorBg' : ''}`}
      >
        <img src={customize.images.logo} className='noraGoLogo' />
        <form key='unpw' onSubmit={this.submit} className='login-form'>
          <div className='login-form__field'>
            <FontAwesomeIcon icon={faUserCircle} className='field-icon' />
            <div className='label'>
              <input
                ref={(node) => (this.emailRef = node)}
                id='email'
                type='email'
                pattern='.+@.+\..+$'
                title={t('auth:Please, provide a valid email address')}
                className='validate'
                placeholder={' '}
              />
              <label htmlFor='email'>{t('auth:Email')}</label>
            </div>
          </div>
          <div className='login-form__field'>
            <FontAwesomeIcon icon={faLockAlt} className='field-icon' />
            <div className={`label `}>
              <input
                ref={(node) => (this.passwordRef = node)}
                id='password'
                type='password'
                className={`validate`}
                placeholder={' '}
              />
              <label htmlFor='password'>{t('auth:Password')}</label>
            </div>
          </div>
          <button type='submit' className='button-small button-primary'>
            {t('auth:Login')}
          </button>
          {loginError && <ErrorMessage error={loginError} />}
        </form>
      </div>
    )
  }
}

export default withTranslation()(LoginBox)
