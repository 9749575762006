import { createDomain } from 'effector'
import { createReEffectFactory } from 'effector-reeffect'

export const root = createDomain('')

// debug logger for effector
// enable effector logger only if VITE_EFFECTOR_LOGGER variable passed (with any value)
if (
  process.env.NODE_ENV !== 'production' &&
  import.meta.env.VITE_EFFECTOR_LOGGER
) {
  import('effector-logger/attach').then(({ attachLogger }) => {
    attachLogger(root, { inspector: 'disabled' })
  })
}

const createReEffect = createReEffectFactory(root.createEffect)
const { createStore, createEffect, createEvent } = root

// export utility methods and constants
export * from 'effector'
export * from 'effector-reeffect'

// export root domain methods
export { createStore, createEvent, createEffect, createReEffect }

// export helper function to create child domain
export const domain = (name?: string) => {
  const child = root.createDomain(name)

  const { createStore, createEffect, createEvent } = child
  const createReEffect = createReEffectFactory(child.createEffect)

  return {
    createStore,
    createEvent,
    createEffect,
    createReEffect,
  }
}
