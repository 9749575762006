import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useTranslation } from 'react-i18next'

// https://setplexapps.atlassian.net/wiki/spaces/MW/pages/519700693/WBS+Gateway+Response+Error+codes
const ERR_CODE_MESSAGE = {
  WG0006: 'There is no prepaid slot for an app', // NOT_ENOUGH_ACTIVE_SLOTS
  WG0008: 'Wrong username or password', // BAD_CREDENTIALS
  WG0009: 'Device is disabled', // DEVICE_DISABLED
  WG0016: 'Web application is disabled for the package', // PLATFORM_NOT_ALLOWED_FOR_NETWORK
  WG0018: 'Your IP is locked for {{time}}. Please wait and try again', // LOCKED_IP_ADDRESS
  WG0019: 'API is blocked. Please contact your provider', // PROVIDER_DISABLED
  WG0020: 'Web application failed to login', // WEB_DEVICE_NOT_CREATED
  WG0021: 'Web application is not allowed to run on this domain', // INVALID_REFERER
  WG0022: 'Email is not unique', // SUBSCRIBER_EMAIL_IS_NOT_UNIQUE
  WG0023: 'There is no active subscription', // SUBSCRIPTION_IS_NOT_ACTIVE
  // 503: 'We are performing an upgrade in our system to improve our services. Please be patient we will be back shortly.',
}

export const ErrorMessage = ({ error }) => {
  const { code, payload, message: respMessage } = error
  const { t } = useTranslation('auth')

  let message
  if (ERR_CODE_MESSAGE[code]) message = t(`message.${ERR_CODE_MESSAGE[code]}`)
  else if (respMessage)
    message = t('message.Sorry, error has occurred') + respMessage
  else message = t('message.Sorry, unknown error has occurred')

  if (payload && code === /* EC55 */ 'WG0018') {
    const min = (payload.remainsLockSeconds / 60) | 0
    const sec = payload.remainsLockSeconds % 60
    message = t(`message.${ERR_CODE_MESSAGE.WG0018}`, { time: `${min}:${sec}` })
  }

  return (
    <p key='errorMessage' className='errorMsg'>
      <FontAwesomeIcon icon={faExclamationTriangle} />
      {message}
    </p>
  )
}
