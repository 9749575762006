import React from 'react'
import './index.scss'

interface PopupProps {
  className?: string
  children: JSX.Element | JSX.Element[]
}

export const Popup = ({
  className = '',
  children,
}: PopupProps): JSX.Element => {
  return (
    <div className={`popup ${className}`}>
      <div className='popup-content'>{children}</div>
    </div>
  )
}
