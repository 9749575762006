import React, { useEffect } from 'react'
import { useStore } from 'effector-react'
import { $activeProfileId } from '../../models/profiles'
import {
  getContinueWatchingFx,
  $continueWatching,
} from '../../models/continueWatching'
import { useNavigate } from '@reach/router'
import { useTranslation } from 'react-i18next'
import { Carousel } from '../common/carousel/Carousel'
import { HorizontalCard } from '../common/cards/horizontal-card/HorizontalCard'
import { getHorizontalVODLogo, getVODLogo } from '/components/vod/helpers'
import { ContentType } from '/components/home/constants'

export const ContinueWatchingCarousel = ({ id }) => {
  const maxLength = 36
  const navigate = useNavigate()
  const continueWatching = useStore($continueWatching)
  const activeProfileId = useStore($activeProfileId)
  const { t } = useTranslation('home')

  useEffect(() => {
    getContinueWatchingFx()
  }, [activeProfileId])

  const handleClick = (item) => {
    if (item.contentType === ContentType.EPISODE) {
      return navigate(
        `shows/categories/0/${item.tvShowId}/${item.seasonId}/${item.contentId}`
      )
    }
    return navigate(`vods/categories/0/${item.contentId}`)
  }

  return continueWatching && continueWatching.length ? (
    <Carousel
      id={id}
      carouselTitle={t('Continue watching')}
      className='featured'
      showAllOption={false}
    >
      {continueWatching.slice(0, maxLength).map((item) => (
        <HorizontalCard
          key={`${item.contentId}-${item.contentName}`}
          title={
            item.contentType === ContentType.VOD
              ? item.contentName
              : item.tvShowName
          }
          imageUrl={
            item.contentType === ContentType.VOD
              ? getHorizontalVODLogo(item.landscapeImageUrl) ||
                getVODLogo(item.portraitImageUrl)
              : item.landscapeImageUrl || item.portraitImageUrl
          }
          handleClick={() => handleClick(item)}
        />
      ))}
    </Carousel>
  ) : null
}
