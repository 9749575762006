import React, { useEffect, ReactElement, memo } from 'react'
import { useIntersectionObserver } from '/utils/hooks'

interface Props {
  page: number
  load: () => void
}

export const IntersectionObserver = ({ load, page }: Props): ReactElement => {
  const [setNode, entry, setDependence] = useIntersectionObserver({
    threshold: 1,
  })

  useEffect(() => {
    if (entry && entry.isIntersecting && entry.intersectionRatio === 1) {
      load()
    }
  }, [entry])

  useEffect(() => {
    setDependence(page)
  }, [page])

  return <div ref={setNode} style={{ marginBottom: '1px' }} />
}

export const IntersectionObserverTarget = memo(IntersectionObserver)
