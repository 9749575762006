import React from 'react'
import { combine } from 'effector'
import { useStore } from 'effector-react'
import { $errorWG0065, $registrationError } from '/models/registration'
import {
  $forgotPassError,
  $warning,
} from '/components/login/models/forgotPassword'
import { $loginError } from '/models/login'
import { ErrorBlock } from '/components/login/components/error_components/ErrorBlock'
import { WarningBlock } from '/components/login/components/error_components/WarningBlock'

const $error = combine(
  $registrationError,
  $loginError,
  $forgotPassError,
  (regError, loginError, forgotPassError) =>
    regError || loginError || forgotPassError || null
)

export const MessageContainer = (): JSX.Element => {
  const error = useStore($error)
  const errorWG0065 = useStore($errorWG0065)
  const warning = useStore($warning)

  return (
    <>
      {error && !errorWG0065 && <ErrorBlock error={error} />}
      {warning && <WarningBlock warning={warning} />}
    </>
  )
}
