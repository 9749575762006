import React, { useCallback, useContext, useEffect, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { Link, useLocation, useNavigate } from '@reach/router'
import { useTranslation } from 'react-i18next'
import { Grid } from '/components/common/grid-cards-layout'
import { showPreloader, hidePreloader } from '/models/preloader'
import { actions as tvActions } from '/components/tv/store'
import {
  getContentById,
  getTvCategories,
  getTvCategory,
  getTvContent,
} from '/components/tv/store/selectors'
import { TvSearchBarContainer } from '../TvSearchBar'
import { NoContent } from '/components/common/no-content'

import './styles.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock } from '@fortawesome/pro-solid-svg-icons'
import { faSearch } from '@fortawesome/pro-regular-svg-icons'
import {
  GridContext,
  GridContextType,
} from '~/components/common/grid-cards-layout/grid-context'
import { TvChannelCard } from '~/components/common/cards/tv-channel-card'

import { setModal } from '/components/common/modal/model'
import { GEOBLOCK_MESSAGE } from '/utils/constants'
import { categoriesPath } from '~/root/routes'

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  categoryId: string // from router
}

export const VodCategory = (props: Props) => {
  const {
    category,
    channels,
    setActiveCategory,
    content,
    search,
    categoryId,
    categories,
  } = props

  const { getGridActivePosition } = useContext(GridContext) as GridContextType
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [pagination, setPagination] = useState({
    page: 1,
    pages: 0,
    total: 0,
    offset: 100,
  })
  const pageUrl = useLocation()

  const [searchResult, setSearchResult] = useState([])

  useEffect(() => {
    pageUrl.pathname.includes('//') && navigate('not_found')
    return () => {
      setSearchResult([])
    }
  }, [])

  useEffect(() => {
    search
      ? setSearchResult(
          content[0].filter((ch) => {
            const query = +ch.channelNumber + ch.name
            return query.toLowerCase().includes(search.toLowerCase())
          })
        )
      : setSearchResult([])
  }, [search])

  useEffect(() => {
    const result = search ? searchResult : channels
    if (result?.length) {
      const pages = Math.ceil(result.length / pagination.offset)
      const page =
        !search && category && getGridActivePosition(`tv_${category.id}`)
          ? Math.floor(
              getGridActivePosition(`tv_${category.id}`) / pagination.offset
            ) + 1
          : 1
      setPagination({ ...pagination, page: page, total: result.length, pages })
    }
  }, [search, searchResult, channels])

  useEffect(() => {
    if (category) setActiveCategory(category.id)
  }, [category])

  useEffect(() => {
    if (categoryId === '0') {
      setActiveCategory(0)
    } else {
      const currentCategory = categories.find(
        (c) => c.id === Number(categoryId)
      )
      currentCategory
        ? setActiveCategory(currentCategory)
        : navigate('/not_found')
    }
  }, [categories])

  const getItemsByPage = useCallback(
    (id, page) => {
      showPreloader()
      setPagination({ ...pagination, page })
      hidePreloader()
    },
    [pagination]
  )

  const handleClick = useCallback(
    (categoryId, channel) => () => {
      if (channel?.blockedByAcl) {
        setModal({
          text: t(`common:message.${GEOBLOCK_MESSAGE}`, {
            item: `"${channel.name}"`,
          }),
        })
        return
      }

      let url = `/tv/categories/${categoryId}`
      if (channel) url = `/tv/categories/${categoryId}/${channel.id}`
      return navigate(url)
    },
    [category, channels]
  )

  const result = search ? searchResult : channels

  return channels?.length ? (
    <div className='content row  nomargin'>
      <div className='page-search-breadcrumbs-wrapper'>
        <div className='breadcrumbs'>
          <Link to={'/tv'}>{t('Live')}</Link>
          {category && (
            <Link to={`/tv/categories/${category.id}`}>
              {category.name === 'All' ? t('All') : category.name}
            </Link>
          )}
        </div>
        <TvSearchBarContainer />
      </div>

      {result && result.length && pagination && pagination.pages ? (
        <Grid
          id={Number(category?.id)}
          contextSelector={`tv_${category?.id}`}
          page={{
            number: pagination.page,
            last: !(pagination.pages > pagination.page),
          }}
          className={'tv-channel-cards'}
          getItemsByPage={getItemsByPage}
          title={
            search
              ? t('Search Result ({{length}})', {
                  length: searchResult.length,
                })
              : category?.name === 'All'
              ? t('All')
              : category?.name
          }
        >
          {result.slice(0, pagination.offset * pagination.page).map((ch) => {
            return (
              <TvChannelCard
                channelTitle={`${ch.channelNumber}. ${ch.name}`}
                key={`${ch.id}/${ch.name}`}
                handleClick={handleClick(category?.id, ch)}
                channelLogoUrl={ch.logoUrl}
                locked={ch.locked}
              />
            )
          })}
        </Grid>
      ) : (
        <NoContent
          key={'empty'}
          icon={<FontAwesomeIcon icon={faSearch} />}
          title={t('No search results found')}
        />
      )}
    </div>
  ) : (
    <></>
  )
}

function mapStateToProps(state, props) {
  return {
    category: getTvCategory(state, props),
    channels: getContentById(state, props),
    content: getTvContent(state),
    search: state.views.tv.search,
    categories: getTvCategories(state),
  }
}

const mapDispatchToProps = {
  setActiveCategory: tvActions.setActiveCategory,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export const VodCategoryPage = connector(VodCategory)
