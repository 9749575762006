import { VALIDATION_ERROR_MESSAGE } from '/utils/constants'
import s from '/components/login/login.module.scss'

/**
 * @param {string} src - url for content resources
 * @param {string} template - template for replace
 * @returns {string} new url with replace {{size}}
 */
export function replaceSizeHelper(src: string | undefined, template: string) {
  return src ? src.replace('{{size}}', template) : ''
}

/**
 * @param {string} src - url for content resources
 * @returns {function} return decorate function
 */
export function getChannelLogo(src?: string) {
  return replaceSizeHelper(src, '170x100')
}

/**
 * @param {string} src - url for content resources
 * @returns {function} return decorate function
 */
export function getChannelLogoSmall(src?: string) {
  return replaceSizeHelper(src, '53x31')
}

/**
 * Get random subcategory poster
 * @returns {string}
 */
export function getRandomPoster(): string {
  return `../img/subcategoriesPosters/cards${Math.floor(
    Math.random() * (15 - 1) + 1
  )}.jpg`
}

/**
 * Get random lockedchannel poster
 * @returns {string}
 */
export function getRandomLockedChannelPoster(): string {
  return `/img/lockedChannelsPosters/Locked_${Math.floor(
    Math.random() * (9 - 1) + 1
  )}.jpg`
}

/**
 * Replaces tokens inside text to their values
 * @param {string} text - text, containing tokens, like
 *                 $firstName, $lastName and so on
 * @param {object} tokens - map with tokens and values, like
 *                 { '$firstName': 'Victor', '$lastName': 'Didenko' }
 */
export function replaceTokens(
  text: string,
  tokens: { [token: string]: string }
) {
  if (!text || !tokens) return text
  const keys = Object.keys(tokens).sort((k1, k2) => k2.length - k1.length)
  // only $ is escaped, maybe change to full escape later
  const re = new RegExp(keys.join('|').replace(/\$/g, '\\$'), 'g')
  return text.replace(re, (match) => tokens[match])
}

const createURLParams = (location: Location, params: URLSearchParams) => {
  return (
    location.pathname +
    (params + '' ? '?' + params : '') +
    (location.hash && location.hash !== '#' ? location.hash : '')
  )
}

export const setURLParams = (location: Location, paramName = '', value = 0) => {
  const params = new URLSearchParams(location.search)
  params.set(paramName, `${value}`)
  history.replaceState(null, '', createURLParams(location, params))
}

export const deleteURLParams = (location: Location) => {
  const params = new URLSearchParams(location.search)

  for (let pair of params.entries()) {
    const key = pair[0]
    params.delete(key)
  }
  history.replaceState(null, '', createURLParams(location, params))
}

/**
 * Sort channels by channel number
 *
 * @param {Object[]} channels
 * @returns {Object[]} array of sorted channels
 */

export const sortByChannelNumber = (channels: any[]) => {
  if (!channels) return
  return channels.sort((a, b) => a.channelNumber - b.channelNumber)
}

/**
 * Sort array by sortOrder
 * @param array
 * @returns sorted array
 */
export function sortBySortOrder<A extends { sortOrder: number }>(
  array: A[]
): A[] {
  return array.slice().sort((a, b) => a.sortOrder - b.sortOrder)
}

/**
 * Check if the string does not contains only spaces
 *
 * @param {string} string to check
 * @returns {boolean} value
 */

export const containsNotOnlySpaces = (string) => {
  return Boolean(string.replace(/\s/g, '').length)
}

/**
 * Debounce function
 *
 * @param fn
 * @param delay
 * @returns () => void
 */
export const debounce = (
  fn: (...args: any) => void,
  delay: number
): ((...args: any) => void) => {
  let timeoutId
  return (...args: any) => {
    clearTimeout(timeoutId)
    timeoutId = setTimeout(() => fn(...args), delay)
  }
}

export enum CardTypes {
  baseCard,
  featuredCard,
  subcategoryCard,
  tvChannelCard,
  tvChannelFeaturedCard,
}

/**
 * Check if a string includes two identical substrings
 *
 * @param {string} string to check
 * @param {substr} substr
 * @returns {boolean} value
 */

export const isIncludesIdenticalSubstrings = (
  string: string,
  substr: string
) => {
  const index = string.indexOf(substr)
  return index !== location.pathname.lastIndexOf(substr) && index !== -1
}

/**
 * Detect if page scrolled to bottom
 * Works for iOS and Android mobiles and cases when, for example, 100% of body height is set
 *
 * For very fast scroll on mobiles with dynamic browser address bar need to add setTimeout (or debounce) to scrollHandler or calculate the bar height
 * for Sony Xperia: Math.ceil(window.innerHeight + window.pageYOffset) >= scrollHeight (also need add debounce for iOS with this solution)
 * @returns {boolean} value
 */

export const isPageScrolledToBottom = (): boolean => {
  const scrollHeight = Math.max(
    document.body.scrollHeight,
    document.documentElement.scrollHeight,
    document.body.offsetHeight,
    document.documentElement.offsetHeight,
    document.body.clientHeight,
    document.documentElement.clientHeight
  )
  // add solution above for fast scroll for Sony Xperia
  return Math.floor(window.pageYOffset) + window.innerHeight === scrollHeight
}

/**
 * Recursive category search by ID
 * @param {array} categories
 * @param {number} id
 */
export const findCategoryById = (categories, id: number) => {
  for (let i = 0; i < categories.length; i++) {
    const item = categories[i]
    if (item.id === id) return item

    if (Array.isArray(item.subCategories)) {
      if (item.subCategories.length) {
        const result = findCategoryById(item.subCategories, id)
        if (result) {
          return result
        }
      }
    }
  }
}

/**
 * Remove duplicate objects from array
 *
 * @param {array} array to check
 * @returns {array} array of non-duplicate objects
 */
export const removeDuplicateObjects = (array: any[]): any[] => {
  return array?.filter(
    (item, index, arr) => index === arr.findIndex((t) => t.id === item.id)
  )
}

/**
 * Remove duplicate values (for example numbers) from array
 *
 * @param {array} array to check
 * @returns {array} array of non-duplicate values
 */
export function removeDuplicateValues<A>(array: A[]): A[] | undefined {
  if (!array) return
  return [...new Set(array)]
}

/**
 * Sort array by another array of ids
 *
 * @param arrayToSort
 * @param array
 * @returns array of sorted objects
 */
export function sortArray<A extends { id: number }>(
  arrayToSort: A[],
  array: number[]
): A[] {
  return arrayToSort
    .slice()
    .sort((a, b) => array.indexOf(a.id) - array.indexOf(b.id))
}

/**
 * Function check is object empty
 *
 * @param obj
 * @returns boolean
 */
export function isEmpty<A extends { constructor: ObjectConstructor }>(
  obj: A
): boolean {
  return obj && Object.keys(obj).length === 0 && obj.constructor === Object
}

/**
 * Cross-browser function converts a number to a short notation without rounding (for example 2k+)
 *
 * @param num
 * @returns string
 */
export function convertNumber(num: number): string {
  if (!num) return '0'

  const million = 1000000
  const thousand = 1000
  if (num >= million) {
    return Math.trunc(num / million) + 'M+'
  }
  if (num >= thousand) {
    return Math.trunc(num / thousand) + 'K+'
  }
  return num.toString()
}

/**
 * Function parse json, handle errors and returns deserialized value
 * @param string
 * @returns deserialized value
 */
export const parseJson = (string: string): any | false => {
  try {
    return JSON.parse(string)
  } catch (e) {
    return false
  }
}

/**
 * Function assigns category All (0)
 * @param initialState
 * @param response - categories array
 * @returns categories array with category All
 */
export const assignAllCategories = (
  initialState = 0,
  response: { data: [] }
): any[] => {
  const category = {
    id: initialState,
    name: 'All',
    sortOrder: initialState,
  }

  return [category].concat(response.data)
}

/**
 * Repeat request every n seconds
 * @param callback
 * @param timeout in ms
 */
export const repeatRequest = (callback: () => void, timeout: number) => {
  return setTimeout(() => callback(), timeout)
}

/**
 * Function that capitalizes the first letter in a string
 * @param str
 */
export const capitalizeFirstLetter = (str: string): string => {
  if (!str) return ''

  return str[0].toUpperCase() + str.slice(1)
}

/**
 * Function validates the field and returns an error if necessary
 * @param element
 */

export const validate = (element: HTMLInputElement): string => {
  if (!element) return ''

  const PASSWORD_MAX_LENGTH = 64
  const { name, value, validity } = element

  switch (true) {
    case validity.valueMissing:
      return VALIDATION_ERROR_MESSAGE.FIELD_IS_REQUIRED.replace(
        '{{field}}',
        name
      )

    case validity.patternMismatch:
      if (name === 'email')
        return VALIDATION_ERROR_MESSAGE.EMAIL_PATTERN_MISMATCH
      if (name === 'password')
        return VALIDATION_ERROR_MESSAGE.PASSWORD_PATTERN_MISMATCH
  }

  if (name === 'password' && value.length > PASSWORD_MAX_LENGTH)
    return VALIDATION_ERROR_MESSAGE.PASSWORD_TOO_LONG

  return ''
}
