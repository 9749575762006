import { faChevronDown } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import s from './accordion.module.scss'

export const Accordion = ({
  toggler,
  content,
  title,
  className,
}: {
  toggler: JSX.Element
  content: { [key: string]: string }
  title: string
  className?: string
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const { t } = useTranslation('settings')
  const toggleAccordion = () => setIsOpen((prev) => !prev)

  return (
    <div
      className={`${s.accordion} ${isOpen ? s.open : s.compact} ${className}`}
    >
      <div className={`${s.content} `}>
        <h3 className={s.title}>{title}</h3>
        {Object.entries(content)?.map(([key, value]) => (
          <div key={key}>
            <span>{t(key)}</span>
            <span>{value}</span>
          </div>
        ))}
      </div>
      <div
        className={`${s.toggler} ${isOpen ? 'open' : 'compact'}`}
        onClick={toggleAccordion}
      >
        {toggler}
        <FontAwesomeIcon icon={faChevronDown} className={s.chevron} />
      </div>
    </div>
  )
}
