import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  fetchCatchupPrograms,
  fetchCatchups,
  fetchCatchupUrl,
} from '/services/api/v3/catchups'
import {
  GET_CATCHUPS_LIST_SUCCESS,
  GET_CATCHUP_SUCCESS,
} from '../../../root/actionTypes'
import { Catchup, CatchupFromResponse } from '../interfaces/index'
import { mapCatchupsToDays } from '../../../utils/catchups'
import { actions as catchupActions } from '~/components/catchup/store'
import { formatDateForCatchupDays, formatDateForEPG } from '~/utils/date'

export const getCatchupsList = createAsyncThunk(
  GET_CATCHUPS_LIST_SUCCESS,
  async (_, { rejectWithValue }) => {
    try {
      const { payload } = await fetchCatchups()

      return payload.map((catchup: CatchupFromResponse) => ({
        id: catchup.id,
        sortOrder: catchup.sortOrder,
        channelId: catchup.channel.id,
        channelName: catchup.channel.name,
        logoUrl: catchup.channel.logoUrl,
        locked: catchup.channel.locked,
        blockedByAcl: catchup.channel.blockedByAcl,
      }))
    } catch (error) {
      return rejectWithValue([])
    }
  }
)

export const changeCatchupChannel = createAsyncThunk(
  GET_CATCHUP_SUCCESS,
  async (catchup: Catchup, { rejectWithValue, dispatch }) => {
    try {
      dispatch(catchupActions.setActiveChannel(catchup))
      dispatch(catchupActions.setDays({}))

      if (!catchup.blockedByAcl) {
        const response = await fetchCatchupPrograms(
          catchup.channelId,
          catchup.id
        )
        const programs = mapCatchupsToDays(response.payload)
        const days = Object.keys(programs)
        if (days.length > 0) {
          dispatch(catchupActions.setActiveDay(days[0]))
          dispatch(catchupActions.setPrograms(programs[days[0]]))
        }
        return response.payload
      }

      return []
    } catch (error) {
      return rejectWithValue([])
    }
  }
)

export const getProgramData = createAsyncThunk(
  'catchup/getProgramData',
  async (
    {
      channel,
      programId,
    }: {
      channel: Catchup
      programId: number | string
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const { payload } = await dispatch(changeCatchupChannel(channel))
      const activeProgram = payload.find(
        (item) => item.id === Number(programId)
      )
      if (activeProgram) {
        dispatch(
          catchupActions.setActiveDay(
            formatDateForCatchupDays(activeProgram.startSec * 1000)
          )
        )

        const response = await fetchCatchupUrl(
          channel.channelId,
          channel.id,
          programId
        )
        return { ...activeProgram, ...response.payload }
      }

      return {}
    } catch (error) {
      return rejectWithValue({})
    }
  }
)

const getCatchupData = async (id, channelId, programId) => {
  const catchup = await Promise.all([
    fetchCatchups(channelId),
    fetchCatchupPrograms(channelId, id),
  ]).then(([{ payload }, programs]) => {
    const catchup = payload?.[0]
    const program = programs?.payload?.find(
      (program) => program.id === programId
    )

    if (catchup && program) {
      return {
        id: catchup.id,
        name: program.name,
        startSec: formatDateForEPG(program.startSec * 1000),
        endSec: formatDateForEPG(program.endSec * 1000),
        channelId,
        programId,
        channelNumber: catchup.sortOrder, // analogue to the field channelNumber in the old api /media/tv/catchups
        channelName: catchup.channel.name,
        channelLogoUrl: catchup.channel.logoUrl,
        blockedByAcl: catchup.channel.blockedByAcl,
      }
    }

    return {}
  })

  return catchup
}

export const getRecentlyWatched = createAsyncThunk(
  'catchup/getRecentlyWatched',
  async (
    data: { id: number; channelId: number; programId: number }[],
    { rejectWithValue }
  ) => {
    try {
      const catchups = await Promise.allSettled(
        data.map(({ id, channelId, programId }) =>
          getCatchupData(id, channelId, programId)
        )
      )
      const result = catchups?.map((result) =>
        result.status === 'fulfilled' ? result.value : {}
      )

      // remove expires catchups
      return result.filter((catchup) => catchup.id) || []
    } catch (e) {
      return rejectWithValue([])
    }
  }
)
