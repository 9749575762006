import { faPlus } from '@fortawesome/pro-regular-svg-icons'
import {
  faCheck,
  faPencil,
  faTrash,
  faUserCircle,
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useStore } from 'effector-react'
import React from 'react'
import {
  $activeProfileId,
  $profiles,
  createProfileFx,
  deleteProfileFx,
  updateProfileFx,
  setActiveProfileFx,
} from '~/models/profiles'
import { useTranslation } from 'react-i18next'
import { DialogModal } from '../modals/dialog-modal'
import { EditContentModal } from '../modals/edit-content-modal'
import s from '../../pages/settings.module.scss'

import { useModal } from '../modals/index'

export const Profiles = () => {
  const activeProfileId = useStore($activeProfileId)
  const profiles = useStore($profiles)
  const { setModal } = useModal()
  const { t } = useTranslation('settings')

  const changeActiveProfile = (id: number) => setActiveProfileFx({ id })

  const addProfile = async (name: string) => {
    await createProfileFx({ name })
  }

  const handleAddClick = () => {
    setModal(
      <EditContentModal
        title={t('Add profile')}
        message={t('Enter Your Profile Name')}
        confirmButtonTitle={'Save'}
        onConfirm={(val: string) => addProfile(val)}
      />
    )
  }

  const editeProfile = async ({ id, name }: { id: number; name: string }) =>
    await updateProfileFx({ id: id, name: name })

  const handleEditClick = (profile) => {
    setModal(
      <EditContentModal
        name={profile.name}
        title={t('Edit profile')}
        confirmButtonTitle={'Save'}
        message={t('You can edit Your Profile Name')}
        onConfirm={(value) => editeProfile({ id: profile.id, name: value })}
      />
    )
  }

  const deleteProfile = async (prodileID: number) => {
    await deleteProfileFx({ id: prodileID })
  }

  const handleDeleteClick = (profile) => {
    setModal(
      <DialogModal
        title={t('Delete profile')}
        message={t('Do you really want to delete the Profile {{name}}?', {
          name: profile.name,
        })}
        confirmButtonTitle={'Delete'}
        onConfirm={() => deleteProfile(profile.id)}
      />
    )
  }

  return (
    <div className={s.profiles}>
      <h3 className={s.title}>{t('Profiles')}</h3>
      {profiles.map((profile) => (
        <div key={profile.id}>
          <div
            className={`${s.settingsCard} ${s.cardFlex} ${
              activeProfileId === profile.id ? s.selected : ''
            }`}
            onClick={() => changeActiveProfile(profile.id)}
          >
            <span className={s.cardTitle}>
              <FontAwesomeIcon icon={faUserCircle} />
              <span>{profile.name}</span>
            </span>
            <FontAwesomeIcon icon={faCheck} className={s.selectedIcon} />
          </div>
          <div
            className={`${s.settingsCard} ${s.cardSquare}`}
            onClick={() => handleEditClick(profile)}
          >
            <FontAwesomeIcon icon={faPencil} />
          </div>
          {profile.id !== activeProfileId && (
            <div
              className={`${s.settingsCard} ${s.cardSquare}`}
              onClick={() => handleDeleteClick(profile)}
            >
              <FontAwesomeIcon icon={faTrash} />
            </div>
          )}
        </div>
      ))}
      {profiles.length < 5 ? (
        <div
          key={'new'}
          className={`${s.settingsCard} ${s.ghostCard}`}
          onClick={() => handleAddClick()}
        >
          <span className={s.cardTitle}>
            <FontAwesomeIcon icon={faPlus} />
            <span>{t('New Profile')}</span>
          </span>
        </div>
      ) : null}
    </div>
  )
}
