import { createSlice, original } from '@reduxjs/toolkit'
import * as homeActions from '/components/home/store/actions'
import { getBannerCarousel } from '/components/home/store/actions'

const initialState = {
  banners: [],
}

const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {},
  extraReducers: {
    [getBannerCarousel.fulfilled.toString()]: (state, { payload }) => {
      state.banners = payload
    },
    [getBannerCarousel.rejected.toString()]: (state) => {
      state.banners = []
    },
  },
})

export const actions = {
  ...homeSlice.actions,
  ...homeActions,
}

export const reducer = homeSlice.reducer
