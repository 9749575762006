import { domain, guard, forward, combine } from '.'
import { $account } from './account'
import { config } from '/config'

// create child domain
const { createEvent, createEffect, createStore } = domain('chat-loader')

// *
// * events
// *

export const importChatModule = createEvent()
export const setMessagesQuantity = createEvent<number>()
export const updateMessagesQuantity = createEvent<number>()

// *
// * effects
// *

export const importChatModuleFx = createEffect(
  () => import('../components/chat')
)

// *
// * stores
// *

export const $chatModule = createStore<any>(null).on(
  importChatModuleFx.doneData,
  (_, module) => module
)

export const $messagesQuantity = createStore<number>(0)
  .on(setMessagesQuantity, (_, quantity) => quantity)
  .on(updateMessagesQuantity, (state, quantity) => state + quantity)

// *
// * connections
// *

forward({
  from: importChatModule,
  to: importChatModuleFx,
})

guard({
  source: combine(
    $account,
    $chatModule,
    (account, module) =>
      module == null &&
      account != null &&
      config.APP_PUBNUB_CHATS_ENABLED &&
      account.chatEnabled
  ),
  filter: (cond) => cond,
  target: importChatModule,
})
