import React from 'react'

export const ChevronLeftIcon = () => {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className='icon'
    >
      <path
        d='M16 3.16102L16.8696 3.97684C17.0435 4.19153 17.0435 4.53503 16.8696 4.70678L9 12.4785L16.8696 20.2932C17.0435 20.465 17.0435 20.8085 16.8696 21.0232L16 21.839C15.7826 22.0537 15.4783 22.0537 15.2609 21.839L6.13043 12.865C5.95652 12.6503 5.95652 12.3497 6.13043 12.135L15.2609 3.16102C15.4783 2.94633 15.7826 2.94633 16 3.16102Z'
        fill='currentColor'
      />
    </svg>
  )
}
