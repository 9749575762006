import axios from 'axios'
import { domain } from '.'

export const SESSION_LIMIT_COUNT = 5

// create child domain
const { createEffect, createStore, createEvent } = domain('session')

// *
// * effects
// *

export const refreshSessionFx = createEffect({
  handler: () => axios.post('api/v3/session/refresh'),
})

export const clearSessionCount = createEvent();

export const $sessionCount = createStore(0)
  .on(refreshSessionFx, (state) => ++state)
  .reset(clearSessionCount)
