import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { NoContent } from '../common/no-content'

export const NotFoundPage = () => {
  const { t } = useTranslation()
  return (
    <NoContent
      title={t("Sorry, requested page doesn't exist")}
      icon={<FontAwesomeIcon icon={faExclamationCircle} />}
    />
  )
}
