import React, { useEffect } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { useNavigate } from '@reach/router'
import { useTranslation } from 'react-i18next'
import { actions as catchupActions } from './store'
import { getRecentlyWatchedCatchups } from './store/selectors'
import { CatchupCard } from './interfaces'
import Storage from '/utils/storage'
import { Carousel } from '../common/carousel/Carousel'
import { HorizontalInfoCard } from '../common/cards/horizontal-info-card/HorizontalInfoCard'
import { parseJson } from '/utils/helpers'

const CatchupsCarouselContainer = ({
  recentlyWatched,
  getRecentlyWatched,
}: ConnectedProps<typeof connector>) => {
  const maxLength = 36
  const navigate = useNavigate()
  const { t } = useTranslation('home')

  useEffect(() => {
    const list = Storage.getItem('recentCatchupsData')
    if (list) {
      const parsedList = parseJson(list) || []
      getRecentlyWatched(parsedList)
    }
  }, [])

  const handleClick = (catchup) =>
    navigate(`/catchup/${catchup.channelId}/${catchup.id}/${catchup.programId}`)

  return recentlyWatched?.length ? (
    <Carousel
      carouselTitle={t('Recently watched Catchups')}
      className='featured'
      showAllOption={false}
    >
      {recentlyWatched.slice(0, maxLength).map((catchup: CatchupCard) => (
        <HorizontalInfoCard
          key={`${catchup.id}/${catchup.name}`}
          name={catchup.name}
          channelNumber={catchup.channelNumber}
          channelName={catchup.channelName}
          channelLogoUrl={catchup.channelLogoUrl}
          startSec={catchup.startSec}
          endSec={catchup.endSec}
          handleClick={() => handleClick(catchup)}
        />
      ))}
    </Carousel>
  ) : null
}

const mapStateToProps = (state) => {
  return {
    recentlyWatched: getRecentlyWatchedCatchups(state),
  }
}

const mapDispatchToProps = {
  getRecentlyWatched: catchupActions.getRecentlyWatched,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export const CatchupsCarouselRecentlyWatched = connector(
  CatchupsCarouselContainer
)
