import React, { useEffect, useCallback, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { useNavigate } from '@reach/router'
import { withTranslation } from 'react-i18next'
import { TvChannelCard } from '~/components/common/cards/tv-channel-card'
import { setModal } from '/components/common/modal/model'
import { Carousel } from '~/components/common/carousel/Carousel'
import { TvCategory, TvChannel } from '../interfaces'
import { TvSearchBarContainer } from '../TvSearchBar'
import { TvCarousel } from '../TvCarousel'
import { getTvCategories, getTvContent } from '/components/tv/store/selectors'
import { CardTypes } from '~/utils/helpers'
import { Grid } from '/components/common/grid-cards-layout'
import { NoContent } from '/components/common/no-content'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faVideoSlash } from '@fortawesome/pro-regular-svg-icons'
import { GEOBLOCK_MESSAGE } from '/utils/constants'

const TvHomePageComponent = (
  props: ConnectedProps<typeof connector>
): JSX.Element => {
  const { categories, content, search, carousel, t } = props
  const [searchResult, setSearchResult] = useState([])
  const navigate = useNavigate()

  const [q, setQ] = useState<string>('')

  useEffect(() => {
    return () => {
      setSearchResult([])
    }
  }, [])

  useEffect(() => {
    search
      ? setSearchResult(
          content[0].filter((ch) => {
            const query = +ch.channelNumber + ch.name
            return query.toLowerCase().includes(search.toLowerCase())
          })
        )
      : setSearchResult([])
  }, [search])

  const handleClick = useCallback(
    (categoryId: number, channel?: TvChannel) => () => {
      if (channel?.blockedByAcl) {
        setModal({
          text: t(`common:message.${GEOBLOCK_MESSAGE}`, {
            item: `"${channel.name}"`,
          }),
        })
        return
      }

      let url = `/tv/categories/${categoryId}`
      if (channel) url = `/tv/categories/${categoryId}/${channel.id}`
      return navigate(url)
    },
    [categories, content]
  )

  const handleFeaturedMovieClick = useCallback((ch: TvChannel) => {
    ch.id && navigate(`/tv/categories/0/${ch.id}`)
  }, [])

  return (
    <>
      <div className='page-search-breadcrumbs-wrapper'>
        {search?.length ? (
          <div className='breadcrumbs'>
            <span>{t('Live')}</span>
          </div>
        ) : null}
        <TvSearchBarContainer callback={setQ} />
      </div>
      {search?.length ? (
        searchResult.length ? (
          <Grid
            id={0}
            page={{ number: 0, last: true }}
            getItemsByPage={() => null}
            className={'tv-channel-cards'}
            title={t('Search Result ({{length}})', {
              length: searchResult.length,
            })}
          >
            {searchResult.map((item: any) => {
              return (
                <TvChannelCard
                  locked={item.locked}
                  channelTitle={`${item.channelNumber}. ${item.name}`}
                  key={`${item.id}/${item.name}`}
                  handleClick={handleClick(0, item)}
                  channelLogoUrl={item.logoUrl}
                />
              )
            })}
          </Grid>
        ) : (
          <NoContent
            key={'empty'}
            icon={<FontAwesomeIcon icon={faSearch} />}
            title={t('No search results found')}
          />
        )
      ) : (!carousel?.enabledOnModulePage || !carousel?.content?.length) &&
        !categories.filter(
          (category) =>
            content && content[category.id] && content[category.id]?.length
        ).length ? (
        <NoContent
          icon={<FontAwesomeIcon icon={faVideoSlash} />}
          title={t('No content')}
        />
      ) : (
        <div className='page__content'>
          {carousel?.enabledOnModulePage && (
            <TvCarousel
              id={'tvFeatured'}
              title={t('common:Featured')}
              cardType={CardTypes.tvChannelFeaturedCard}
              handleMovieClick={handleFeaturedMovieClick}
            />
          )}
          {categories
            .filter(
              (category: TvCategory) =>
                content && content[category.id] && content[category.id].length
            )
            .map((category: TvCategory) => (
              <Carousel
                id={`tv_${category.id}`}
                key={`${category.id}/${category.name}`}
                carouselTitle={
                  category.name === 'All' ? t('All') : category.name
                }
                showAllOption={content[category.id].length > 36}
                handleCategoryClick={handleClick(category.id)}
              >
                {content[category.id].slice(0, 36).map((ch) => {
                  return (
                    <TvChannelCard
                      channelTitle={`${ch.channelNumber}. ${ch.name}`}
                      key={`${ch.id}/${ch.name}`}
                      locked={ch.locked}
                      handleClick={handleClick(category.id, ch)}
                      channelLogoUrl={ch.logoUrl}
                    />
                  )
                })}
              </Carousel>
            ))}
        </div>
      )}
    </>
  )
}

function mapStateToProps(state) {
  return {
    categories: getTvCategories(state),
    content: getTvContent(state),
    carousel: state.views.tv.carousels?.find((c) => c.type === 'TV_CHANNEL'),
    search: state.views.tv.search,
  }
}

const connector = connect(mapStateToProps)

export const TvHomePage = withTranslation()(connector(TvHomePageComponent))
