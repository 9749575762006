import { LANGUAGES_LIST } from './languages'
import { Language } from './interfaces/index'

export default class ISO6391 {
  static getLanguages(codes: string[]): Language[] {
    if (!codes) return LANGUAGES_LIST

    return codes
      .map((code) => ISO6391.findLanguage(code))
      .filter((language) => language)
  }

  static getName(code: string): string {
    return ISO6391.findLanguage(code)?.name || ''
  }

  static getAllNames(): string[] {
    return LANGUAGES_LIST.map(({ name }) => name)
  }

  static getNativeName(code: string): string {
    return ISO6391.findLanguage(code)?.nativeName || ''
  }

  static getAllNativeNames(): string[] {
    return LANGUAGES_LIST.map(({ nativeName }) => nativeName)
  }

  static getNameByAppLanguage(code: string, appLanguageCode: string): string {
    return ISO6391.findLanguage(code)?.names[appLanguageCode] || ''
  }

  static findLanguage(code: string): Language {
    return LANGUAGES_LIST.find(({ codes }) => codes.includes(code)) as Language
  }
}
