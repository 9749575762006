import { createSelector } from 'reselect'
import { getView } from '~/root/rootSelectors'

const getTvView = createSelector(getView, (view) => view.tv)

export const getTvContent = createSelector(getTvView, (view) => view.content)

export const getTvCategories = createSelector(
  getTvView,
  (view) => view.categories
)

export const getTvCategory = createSelector(
  [getTvView, (state, props) => props],
  (view, { categoryId }) => {
    return view.categories.find((c) => c.id === Number(categoryId)) || null
  }
)

export const getTvActiveChannel = createSelector(
  getTvView,
  (view) => view.activeChannel
)
export const getTvActiveProgram = createSelector(
  getTvView,
  (view) => view.activeProgram
)

export const getContentById = createSelector(
  [getTvContent, (state, props) => props],
  (content, { categoryId }) => {
    if (!content[categoryId]) return null
    return content[categoryId] || null
  }
)

export const getTvChannelById = createSelector(
  [getTvContent, (state, props) => props],
  (content, { categoryId = 0, channelId }) => {
    if (!content[categoryId]) return null
    return content[categoryId].find((c) => c.id === Number(channelId)) || null
  }
)

export const getRecentlyWatchedTvChannel = createSelector(
  getTvView,
  (view) => view.recentlyWatched
)

export const getAllTvChannels = createSelector(
  getTvContent,
  (content) => content[0]
)
