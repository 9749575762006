import axios from 'axios'
import { isServerUnableProcess, repeatRequest, api } from '.'

/**
 * Find VODs by category id
 * @param {String} categoryId
 * @param {Number} page
 * @param {Number} count
 * @returns {Promise<T>}
 */
export const fetchVODByCategory = (categoryId, page = 0, count = 100) => {
  return axios
    .get(`${api}/vods/?categoryId=${categoryId}&page=${page}&count=${count}`)
    .then((response) => response.data)
    .catch((error) => {
      const { status } = error.response
      if (isServerUnableProcess(status)) {
        return repeatRequest(() => fetchVODByCategory(categoryId, page, count))
      } else {
        throw error
      }
    })
}
