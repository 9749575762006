import React, { MouseEvent, useCallback, useState } from 'react'
import { useEffect } from 'react'
import s from './dialogs.module.scss'

interface IModalContext {
  unSetModal: () => void
  setModal: (elem?: JSX.Element) => void
}

const ModalContext = React.createContext<IModalContext>({
  unSetModal: () => {},
  setModal: (elem) => elem,
})

const Modal = ({ modal, unSetModal }) => {
  const closeIfOutside = (e) => {
    e.stopPropagation()
    if (e.target.id === 'activeModal') {
      unSetModal()
    }
  }

  useEffect(() => {
    document.addEventListener('click', closeIfOutside)
    return () => document.removeEventListener('click', closeIfOutside)
  }, [])

  return (
    <div className={s.overlay} id='activeModal'>
      {modal}
    </div>
  )
}

export const ModalProvider = (props) => {
  const [modal, setModal] = useState()
  const unSetModal = useCallback(() => {
    setModal(undefined)
  }, [setModal])

  return (
    <ModalContext.Provider value={{ unSetModal, setModal }} {...props}>
      {props.children}
      {modal && <Modal modal={modal} unSetModal={unSetModal} />}
    </ModalContext.Provider>
  )
}

export const useModal = () => {
  const context = React.useContext(ModalContext)
  if (context === undefined) {
    throw new Error('useModal must be used within a UserProvider')
  }

  return context
}
