import { postStats } from '../api/v2/stats'

/**
 * Stats Collector Class
 * 🔎 update store through redux mw
 * 🔧 preparing & validation collected data
 * ⏰ post statistics through stats-service via time interval
 */
export default class StatsCollector {
  constructor() {
    this.intervalDelay = 30000
    this.timerId = null

    this.data = {} // ---> collected stats data instance
    this.state = [] // ---> current stats list state
    this.store = {} // ---> redux store
  }

  /**
   * [Subscriber] update redux store instance through redux middleware
   * @param {Object} store - redux store
   */
  update(store) {
    this.store = store
  }

  /**
   * Data Collector
   * @param {String} type - active video view type
   * @return {Object} context
   */
  add(type) {
    const { id, urlPlatformType } = this.get(type)

    this.data = {
      mediaId: id,
      mediaType: type,
      urlPlatformType,
    }

    return this
  }

  /**
   * Get media instance by active view type from redux store
   * @param {String} type
   * @return {Object} active media instance
   */
  get(type) {
    const regex = /activeMovie|activeChannel/
    const view = this.store[type.toLowerCase()]
    const inst = Object.keys(view).find((key) => key.match(regex))

    return view[inst]
  }

  /**
   * Start stats collection
   * @return {Object} collected data inst
   */
  start() {
    this.data.start = new Date().getTime()
    this.data.watching = true
    return this.data
  }

  /**
   * Stop stats collection
   * @return {Object} collected data inst
   */
  stop() {
    this.data.stop = new Date().getTime()
    return this.data
  }

  /**
   * Activate POST res interval
   */
  activate() {
    this.start().watching = true
    this.timerId = !this.timerId
      ? setTimeout(() => this.post(), this.intervalDelay)
      : this.timerId
  }

  /**
   * Deactivate POST res interval
   */
  deactivate() {
    this.stop().watching = false
    this.push()
  }

  /**
   * Push in statement
   */
  push() {
    this.state.push(this.data)
    this.data = {}
  }

  /**
   * POST method with validation & clear statement after response
   */
  post() {
    this.stop()
    this.state.push(this.data)

    this.resetTimeout()

    const stats = StatsCollector.validate(this.state)
    return stats.length
      ? postStats(stats).then(({ status }) =>
          status !== 200 ? this.deactivate() : this.clear().synchronize()
        )
      : null
  }

  /**
   * Simple validation method
   * @param {Array} list of collected data
   */
  static validate(list) {
    return list.filter(
      (item) => item.start && item.stop && item.urlPlatformType
    )
  }

  /**
   * Synchronize @start and @stop event
   * Add new delayed timeout task
   */
  synchronize() {
    this.data.start = this.data.stop
    this.timerId = setTimeout(() => this.post(), this.intervalDelay)
  }

  /**
   * Clear statement
   */
  clear() {
    this.state = []
    return this
  }

  /**
   * Reset timeout for prevent multiple timer instances in @activate method
   */
  resetTimeout() {
    clearTimeout(this.timerId)
    this.timerId = null
  }
}
