import { combineReducers } from 'redux'
import { reducer as home } from '../components/home/store'
import { reducer as vod } from '../components/vod/store'
import { reducer as tv } from '../components/tv/store'
import { reducer as catchup } from '../components/catchup/store'
import { tvShowsReducer as tvShows } from '/components/tvShows/tvShowsReducer'
import radio from '../components/radio/radioReducer'
import epg from '../components/epg/epgReducer'

const views = combineReducers({
  home,
  tv,
  vod,
  tvShows,
  radio,
  catchup,
  epg,
})

export const appReducer = combineReducers({
  views,
})
