import axios from 'axios'
import { stats as api } from '.'
import { isServerUnableProcess, repeatRequest } from '..'

export const postStats = (data) => {
  return axios
    .post(`${api}`, data)
    .then((response) => response)
    .catch((error) => {
      const { status } = error.response
      if (isServerUnableProcess(status)) {
        repeatRequest(() => postStats(data))
      } else {
        return error.response
      }
    })
}
