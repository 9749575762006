import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons'
import { TvShowSeason } from '../../interfaces'

import './styles.scss'
import { useTooltip } from '../tooltip/context'
// import Tooltip from 'react-tooltip-lite'

interface TvShowSeasonProps {
  season: TvShowSeason
  handleClick: (
    e: React.MouseEvent<HTMLDivElement>,
    season: TvShowSeason
  ) => Promise<void>
  isActive?: boolean
}

export const Season = ({
  season,
  handleClick,
  isActive,
}: TvShowSeasonProps): JSX.Element => {
  const { year, displayNumber, name, directors, stars, description } = season
  const { t } = useTranslation('tvshow')
  const { openTooltip, tooltip, closeTooltip } = useTooltip()

  const handleTooltipClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    openTooltip(e, season)
  }
  return (
    <div className={`season ${isActive ? 'active' : ''}`}>
      <div
        className='season-info'
        onClick={!tooltip ? (e) => handleClick(e, season) : closeTooltip}
      >
        <span>{`${displayNumber}. ${name}`}</span>
        <span>{t('Release {{year}}', { year })}</span>
      </div>
      {/* <div className='season-tooltip'> */}
      {directors || stars || description ? (
        <div
          className={'tooltip-toggler tooltip-wrapper'}
          onClick={handleTooltipClick}
        >
          <FontAwesomeIcon icon={faExclamationCircle} />
        </div>
      ) : null}
    </div>
  )
}
