import React, { useEffect } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { actions as tvActions } from '/components/tv/store'
import { useTranslation } from 'react-i18next'
import { EpgSearchBarContainer } from '../EpgSearchBar'
import { Channel } from '~/components/common/channel'
import { Player } from '~/components/common/player/Player'
import { PinLockContainer } from '../../common/player/PinLock'
import { formatDate, getDay } from '~/utils/date'
import { NoContent } from '~/components/common/no-content'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { PlayerPreloader } from '~/components/common/Preloaders'
import { getTvActiveChannel } from '~/components/tv/store/selectors'
import { faSearch, faVideoSlash } from '@fortawesome/pro-regular-svg-icons'
import './styles.scss'
import { getChannelLogoSmall, getChannelLogo } from '~/components/tv/helpers'
import Image from '~/components/common/Image'
import { GEOBLOCK_MESSAGE } from '/utils/constants'

export const EpgHome = ({
  activeChannel,
  channels,
  searchQ,
  selectChannel,
  EPG,
  activeProgram,
}: ConnectedProps<typeof connector>): JSX.Element => {
  const { t } = useTranslation()

  const channelsList =
    searchQ && channels?.length
      ? channels.filter((channel) => {
          const channelSearchSrc = `${
            channel.channelNumber
          } ${channel.name.toLowerCase()}`
          return channelSearchSrc.includes(searchQ.toLowerCase())
        })
      : channels

  useEffect(() => {
    if (channelsList && channelsList.length) {
      selectChannel(channelsList[0])
    }
  }, [searchQ])

  return (
    <div className='epg-page'>
      <div className='page-search-breadcrumbs-wrapper'>
        <EpgSearchBarContainer />
      </div>
      {channelsList &&
      channelsList.length &&
      activeChannel &&
      Object.keys(activeChannel).length ? (
        <div className='epg-page__content'>
          <>
            <div className='channels epg-page__column'>
              <h2 className='list__title'>{t('Channels')}</h2>
              <div className='channels__list'>
                {channelsList.map((ch) => {
                  const isActive = ch.id === activeChannel.id
                  return (
                    <Channel
                      key={ch.id}
                      channel={isActive ? activeChannel : ch}
                      handleClick={() => !isActive && selectChannel(ch)}
                      isActive={isActive}
                    />
                  )
                })}
              </div>
            </div>
            <div className='media epg-page__block'>
              <div className='epg-page__column player'>
                <h2 className='list__title'>{t('epg:What is on now')}</h2>

                <div
                  className={`epg-player ${
                    activeChannel.locked ? 'locked' : ''
                  }`}
                >
                  {activeChannel?.blockedByAcl ? (
                    <div className='player-page__info-box'>
                      <h1 className='player-page__title'>
                        {t('common:Sorry')}!
                      </h1>
                      <p className='player-page__message'>
                        {t(`common:message.${GEOBLOCK_MESSAGE}`, {
                          item: `"${activeChannel.name}"`,
                        })}
                      </p>
                    </div>
                  ) : activeChannel.locked ? (
                    <PinLockContainer
                      autoFocus={!searchQ}
                      title={`${activeChannel.channelNumber}. ${activeChannel.name}`}
                    />
                  ) : activeChannel.channelUrl ? (
                    <Player
                      src={activeChannel.channelUrl}
                      drm={activeChannel.drm}
                      skin='epg'
                      contentId={activeChannel.id}
                      contentTitle={activeChannel.name}
                      contentLive={true}
                    />
                  ) : (
                    <PlayerPreloader />
                  )}
                </div>
                {!activeChannel.locked ? (
                  <div className='channel-info'>
                    <div className='channel__logo'>
                      <Image
                        imageUrl={getChannelLogo(activeChannel.logoUrl)}
                        defaultUrl={getChannelLogoSmall(activeChannel.logoUrl)}
                        secondaryImageUrl={'/img/noLogo.svg'}
                      />
                    </div>
                    <div>
                      <div className='channel__title'>{`${activeChannel.channelNumber}. ${activeChannel.name}`}</div>
                      <div className='channel__short-info'>
                        {activeProgram && activeProgram.name}
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>

              <div className='epg-page__column epg_column'>
                <h2 className='list__title'>{t('epg:What is next')}</h2>
                <div className='epg-list'>
                  {!activeChannel.locked ? (
                    EPG?.length ? (
                      EPG.map((epg, index, epgList) => {
                        const isDayEqual =
                          getDay(epg.start) ===
                          (epgList[index - 1]
                            ? getDay(epgList[index - 1].start)
                            : null)
                        if (isDayEqual) {
                          return (
                            <p
                              key={`${epg.title}${epg.start}`}
                              className='epg-row'
                            >
                              <span>
                                {`${formatDate(epg.start)} —
                                ${formatDate(epg.stop)} — ${epg.title}`}
                              </span>
                            </p>
                          )
                        }
                        return (
                          <>
                            {getDay(epg.start) !== getDay(Date()) ? (
                              <h3 className='epg-day'>{getDay(epg.start)}</h3>
                            ) : null}
                            <p
                              key={`${epg.title}${epg.start}`}
                              className={'epg-row'}
                            >
                              <span>
                                {`${formatDate(epg.start)} —
                                    ${formatDate(epg.stop)} — ${epg.title}`}
                              </span>
                            </p>
                          </>
                        )
                      })
                    ) : (
                      <p className='no-content-info'>
                        {t('common:No Programs are available')}
                      </p>
                    )
                  ) : (
                    <p className='no-content-info'>
                      {t('epg:Enter PIN code to watch EPG')}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </>
        </div>
      ) : (
        <NoContent
          icon={<FontAwesomeIcon icon={searchQ ? faSearch : faVideoSlash} />}
          title={
            searchQ
              ? t('common:No search results found')
              : t('common:No content')
          }
        />
      )}
    </div>
  )
}

function mapStateToProps(state) {
  return {
    activeChannel: getTvActiveChannel(state),
    channels: state.views.epg.channels,
    searchQ: state.views.epg.search,
    EPG: state.views.tv.EPG,
    activeProgram: state.views.tv.activeProgram,
  }
}

const mapDispatchToProps = {
  selectChannel: tvActions.changeChannel,
}

const connector = connect(mapStateToProps, mapDispatchToProps)
export const EpgHomePage = connector(EpgHome)
