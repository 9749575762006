import { createSelector } from 'reselect'
import { getView } from '~/root/rootSelectors'

const getCatchupView = createSelector(getView, (view) => view.catchup)

export const getChannels = createSelector(
  getCatchupView,
  (view) => view.channels
)

export const getActiveChannel = createSelector(
  getCatchupView,
  (view) => view.activeChannel
)

export const getDays = createSelector(getCatchupView, (view) => view.days)

export const getRecentlyWatchedCatchups = createSelector(
  getCatchupView,
  (view) => view.recentlyWatched
)
