import type { LAProps, KeySystems, KeySystemOptions } from './types'
import { widevine, playready, fairplay } from './providers'

// https://github.com/videojs/videojs-contrib-eme
export function keySystems(la: LAProps): KeySystems | undefined {
  if (la == null) return
  const ks: KeySystems = {}

  if (la.WIDEVINE != null) {
    ks['com.widevine.alpha'] = widevine(la.WIDEVINE.provider, {
      url: la.WIDEVINE.licenseUrl,
      audioRobustness: 'SW_SECURE_CRYPTO',
      videoRobustness: 'SW_SECURE_CRYPTO',
    })
  }

  if (la.PLAYREADY != null) {
    ks['com.microsoft.playready'] = playready(la.PLAYREADY.provider, {
      url: la.PLAYREADY.licenseUrl,
    })
  }

  if (la.FAIRPLAY != null) {
    if (la.FAIRPLAY.fpsCertificateUrl == null) {
      console.error('❌ there is no fps certificate url defined!!!')
    } else {
      ks['com.apple.fps.1_0'] = fairplay(la.FAIRPLAY.provider, {
        licenseUri: la.FAIRPLAY.licenseUrl,
        certificateUri: la.FAIRPLAY.fpsCertificateUrl,
      })
    }
  }

  return ks
}

// https://github.com/videojs/videojs-contrib-dash
export function keySystemOptions(la: LAProps): KeySystemOptions[] | undefined {
  if (la == null) return
  const kso: KeySystemOptions[] = []

  if (la.WIDEVINE != null) {
    kso.push({
      name: 'com.widevine.alpha',
      options: widevine(la.WIDEVINE.provider, {
        serverURL: la.WIDEVINE.licenseUrl,
        audioRobustness: 'SW_SECURE_CRYPTO',
        videoRobustness: 'SW_SECURE_CRYPTO',
      }),
    })
  }

  if (la.PLAYREADY != null) {
    kso.push({
      name: 'com.microsoft.playready',
      options: playready(la.PLAYREADY.provider, {
        serverURL: la.PLAYREADY.licenseUrl,
      }),
    })
  }

  return kso
}
