import axios from 'axios'
import { persist } from 'effector-storage/local/fp'
import { domain, guard, createApi, combine } from '.'
import { resetError, setForm } from '/models/registration'
import { ErrorI } from '/components/login/interfaces'

// create child domain
const { createEvent, createStore, createEffect } = domain('login')

// *
// * events
// *

export const doLogin = createEvent<{ email: string; password: string }>()
export const doLogout = createEvent()
export const setAuthenticated = createEvent<boolean>()
export const resetAuthenticated = createEvent()

// *
// * effects
// *

export const loginFx = createEffect<{ email: string; password: string }, any>(
  (credentials) => axios.post('/auth', credentials)
)

export const logoutFx = createEffect(() => axios.post('/logout'))

// add some sort of a gate, to disable login request until view has been changed

const $canDoLogin = createStore(true)
  .on(loginFx.pending.updates, (_, pending) => (pending ? false : undefined))
  .on(loginFx.fail, () => true)

export const canDoLogin = createApi($canDoLogin, {
  yes: () => true,
  no: () => false,
})

// *
// * stores
// *

export const $isAuthenticated = createStore(false)
  .on(loginFx.done, () => true)
  .on(setAuthenticated, (_, flag) => flag)
  .reset(resetAuthenticated, loginFx.fail, logoutFx)
  .thru(persist({ key: 'authenticated' }))

export const $loginError = createStore<ErrorI | null>(null)
  .on(loginFx.failData, (_, { response }: any) => ({
    code: response?.data?.errorCode,
    payload: response?.data?.payload,
    message: response?.data?.message,
  }))
  .reset(loginFx, resetError, setForm)

export const geoBlockWBS = $loginError.map((error) =>
  error?.code === 'WB0051' ? error : null
)

// *
// * connections
// *

guard({
  source: doLogin,
  filter: combine(
    loginFx.pending,
    $canDoLogin,
    (pending, can) => !pending && can
  ),
  target: loginFx,
})

guard({
  source: doLogout,
  filter: logoutFx.pending.map((is) => !is),
  target: logoutFx,
})
