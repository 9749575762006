import React, { useRef, useState } from 'react'
import { reset, resetPass } from '../../models/forgotPassword'
import { ErrorBlock } from '/components/login/components/error_components/ErrorBlock'
import { Logo } from '/components/login/components/forms/FormLogo'
import { Button } from '/components/common/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLockAlt } from '@fortawesome/pro-solid-svg-icons'
import { faEye, faEyeSlash } from '@fortawesome/pro-regular-svg-icons'
import { VALIDATION_ERROR_MESSAGE } from '/utils/constants'
import { validate, capitalizeFirstLetter } from '/utils/helpers'

import s from '../../login.module.scss'

export const ChangePasswordForm = (): JSX.Element => {
  const passRef = useRef<HTMLInputElement>(null)
  const [fieldError, setError] = useState('')
  const confirmedPassRef = useRef<HTMLInputElement>(null)
  const [passVisibility, setPassVisibility] = useState<boolean>(false)
  const [confirmedPassVisibility, setConfirmedPassVisibility] =
    useState<boolean>(false)

  const submit = (e) => {
    reset()
    setError('')
    e.preventDefault()
    const pass = passRef?.current
    const confirmedPass = confirmedPassRef?.current

    if (pass && confirmedPass) {
      const passValue = pass.value
      const confirmedPassValue = confirmedPass.value
      const invalidClass = `${s.invalid}`

      const passError = validate(pass)
      const confirmedPassError = validate(confirmedPass)

      pass.className = passError ? invalidClass : ''
      confirmedPass.className = confirmedPassError ? invalidClass : ''
      if (passError || confirmedPassError)
        return setError(capitalizeFirstLetter(passError || confirmedPassError))

      if (passValue && confirmedPassValue) {
        if (passValue === confirmedPassValue) {
          return resetPass(passValue)
        }
        pass.className = invalidClass
        confirmedPass.className = invalidClass
        return setError(VALIDATION_ERROR_MESSAGE.NOT_EQUAL_PASSWORDS)
      }
    }
  }

  const togglePassVisibility = () => setPassVisibility((state) => !state)
  const toggleConfirmedPassVisibility = () =>
    setConfirmedPassVisibility((state) => !state)

  return (
    <>
      <div className={s.loginBox}>
        <div className={s.loginHeader}>
          <Logo />
          <h1 className={s.loginTitle}>Change Password</h1>
        </div>
        <form
          className={`${s.loginForm} ${s.loginForm__paddingBottom}`}
          onSubmit={submit}
          noValidate
        >
          <div className={s.loginForm__field}>
            <FontAwesomeIcon className={s.loginForm__icon} icon={faLockAlt} />
            <div className={`${s.loginForm__label} ${s.withIcon}`}>
              <input type='password' style={{ display: 'none' }} />
              <input
                required
                ref={passRef}
                id='password'
                name='password'
                autoComplete='new-password'
                type={passVisibility ? 'text' : 'password'}
                pattern='^[A-Za-z0-9@#$_&-*!?.]+$'
                placeholder={' '}
              />
              <FontAwesomeIcon
                className={`${s.loginForm__icon} ${s.showPassword}`}
                icon={passVisibility ? faEye : faEyeSlash}
                onClick={togglePassVisibility}
              />
              <label htmlFor='password'>Password</label>
            </div>
          </div>
          <div className={s.loginForm__field}>
            <FontAwesomeIcon className={s.loginForm__icon} icon={faLockAlt} />
            <div className={`${s.loginForm__label} ${s.withIcon}`}>
              <input type='password' style={{ display: 'none' }} />
              <input
                required
                ref={confirmedPassRef}
                id='confirmedPassword'
                name='password'
                autoComplete='new-password'
                type={confirmedPassVisibility ? 'text' : 'password'}
                pattern='^[A-Za-z0-9@#$_&-*!?.]+$'
                placeholder={' '}
              />
              <FontAwesomeIcon
                className={`${s.loginForm__icon} ${s.showPassword}`}
                icon={confirmedPassVisibility ? faEye : faEyeSlash}
                onClick={toggleConfirmedPassVisibility}
              />
              <label htmlFor='confirmedPassword'>Confirm Password</label>
            </div>
          </div>
          <Button
            type='submit'
            title='Submit'
            className={`${s.button} button-primary button-small button-full-width`}
          />
        </form>
      </div>
      {fieldError ? <ErrorBlock error={{ message: fieldError }} /> : null}
    </>
  )
}
