import React, { useRef, useState } from 'react'
import { setForm, doRegister } from '/models/registration'
import { Logo } from '/components/login/components/forms/FormLogo'
import { Button } from '/components/common/button'
import { ErrorBlock } from '/components/login/components/error_components/ErrorBlock'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLockAlt, faUserCircle } from '@fortawesome/pro-solid-svg-icons'
import { faEye, faEyeSlash } from '@fortawesome/pro-regular-svg-icons'
import { FORM_TYPE } from '/utils/constants'
import { validate, capitalizeFirstLetter } from '/utils/helpers'

import s from '../../login.module.scss'

export const SignUp = (): JSX.Element => {
  const [fieldError, setError] = useState('')
  const [passVisibility, setPassVisibility] = useState<boolean>(false)
  const emailRef = useRef<HTMLInputElement>(null)
  const passRef = useRef<HTMLInputElement>(null)

  const showPassword = () => {
    setPassVisibility((prop) => !prop)
  }

  const submit = (e) => {
    setError('')
    e.preventDefault()
    const email = emailRef?.current
    const password = passRef?.current
    const invalidClass = `${s.invalid}`

    if (email && password) {
      const passError = validate(password)
      const emailError = validate(email)
      password.className = passError ? invalidClass : ''
      email.className = emailError ? invalidClass : ''

      if (emailError || passError)
        return setError(capitalizeFirstLetter(emailError || passError))

      doRegister({ email: email.value, password: password.value })
    }
  }

  return (
    <>
      <div className={`${s.loginBox} ${s.loginBox__staticHeight}`}>
        <div className={s.loginHeader}>
          <Logo />
          <h1 className={s.loginTitle}>Sign Up</h1>
          <h2 className={`${s.message} ${s.messageLarge}`}>
            Please fill in fields to create your account
          </h2>
        </div>
        <form className={s.loginForm} onSubmit={submit} noValidate>
          <div className={s.loginForm__field}>
            <FontAwesomeIcon
              className={s.loginForm__icon}
              icon={faUserCircle}
            />
            <div className={s.loginForm__label}>
              <input type='email' style={{ display: 'none' }} />
              <input
                required
                ref={emailRef}
                id='email'
                name='email'
                type='email'
                pattern='.+@.+\..+$'
                placeholder={' '}
              />
              <label htmlFor='email'>Email</label>
            </div>
          </div>
          <div className={s.loginForm__field}>
            <FontAwesomeIcon className={s.loginForm__icon} icon={faLockAlt} />
            <div className={`${s.loginForm__label} ${s.withIcon}`}>
              <input type='password' style={{ display: 'none' }} />
              <input
                required
                ref={passRef}
                id='password'
                name='password'
                autoComplete='new-password'
                type={passVisibility ? 'text' : 'password'}
                pattern='^[A-Za-z0-9@#$_&-*!?.]+$'
                placeholder={' '}
              />
              <FontAwesomeIcon
                className={`${s.loginForm__icon} ${s.showPassword}`}
                icon={passVisibility ? faEye : faEyeSlash}
                onClick={showPassword}
              />
              <label htmlFor='password'>Password</label>
            </div>
          </div>
          <Button
            type='submit'
            title='Submit'
            className={`${s.button} button-primary button-small button-full-width`}
          />
          <p className={`${s.message} ${s.messageMarginTop}`}>
            Do you have an account?
          </p>
          <Button
            type='button'
            title='Sign In'
            className={`${s.button} button-ghost button-small button-full-width`}
            handleClick={() => setForm(FORM_TYPE.SIGN_IN)}
          />
        </form>
      </div>
      {fieldError ? <ErrorBlock error={{ message: fieldError }} /> : null}
    </>
  )
}
