import React from 'react'
import { AccountInfo } from '../components/account-info'
import { Actions } from '../components/actions'
import { AppLanguage } from '../components/app-language'
import { ModalProvider } from '../components/modals'
import { Profiles } from '../components/profiles'
import s from './settings.module.scss'

export const Settings = () => {
  return (
    <ModalProvider>
      <div className={s.settigsPage}>
        <AccountInfo />
        <Profiles />
        <AppLanguage />
        <Actions />
      </div>
    </ModalProvider>
  )
}
