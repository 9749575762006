import {
  CHANGE_EPG_SEARCH_FIELD,
  GET_ALL_CHANNELS_SUCCESS,
  GET_ALL_CHANNELS_ERROR,
} from '../../root/actionTypes'

export default function (state = {}, action) {
  switch (action.type) {
    case GET_ALL_CHANNELS_SUCCESS:
      return { ...state, channels: action.channels }

    case GET_ALL_CHANNELS_ERROR:
      return { ...state, channels: [] }

    case CHANGE_EPG_SEARCH_FIELD:
      return { ...state, search: action.value }

    default:
      return state
  }
}
