import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from '@reach/router'
import { setModal } from '/components/common/modal/model'
import { actions as vodActions } from '../store'
import { useTranslation } from 'react-i18next'
import { Carousel } from '../../common/carousel/Carousel'
import { getHorizontalVODLogo } from '/components/vod/helpers'
import { HorizontalCard } from '/components/common/cards/horizontal-card/HorizontalCard'
import { _ } from '/config'
import { GEOBLOCK_MESSAGE } from '/utils/constants'

const FavoriteVods = ({
  movies,
  handleMovieClick,
  loadContent,
  id,
  setIsVodFavorite,
}) => {
  const maxLength = 36
  const navigate = useNavigate()
  const { t } = useTranslation('home')

  useEffect(() => {
    loadContent({ page: 0, count: maxLength })
  }, [])

  const handleClick = async (movie) => {
    if (movie?.blockedByAcl) {
      setModal({
        text: t(`common:message.${GEOBLOCK_MESSAGE}`, {
          item: `"${movie.name}"`,
        }),
      })
      return
    }
    handleMovieClick && handleMovieClick(movie)

    await navigate(`/vods/categories/0/${movie.id}`)
  }

  return (
    movies?.length && (
      <Carousel
        key={"'favoriteVods'"}
        carouselTitle={t(_('header-favorite-movies'))}
        showAllOption={false}
        id={id}
      >
        {movies.slice(0, maxLength).map((item) => {
          const imageUrl = item.imageHorizontalUrl
            ? getHorizontalVODLogo(item.imageHorizontalUrl)
            : getHorizontalVODLogo(item.imageUrl)
          return (
            <HorizontalCard
              favorite={item.favorite}
              handleFavoriteClick={() => {
                setIsVodFavorite(item), loadContent({})
              }}
              key={`${item.id}${item.name}`}
              title={item.name}
              imageUrl={imageUrl || ''}
              handleClick={() => handleClick(item)}
            />
          )
        })}
      </Carousel>
    )
  )
}

function mapStateToProps(state) {
  return {
    movies: state.views.vod.favoriteVods,
  }
}

const mapDispatchToProps = {
  loadContent: vodActions.getOnlyFavoriteVods,
  setIsVodFavorite: vodActions.setIsVodFavorite,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export const FavoriteVodsCarousel = connector(FavoriteVods)
