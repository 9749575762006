import { domain, guard } from '.'
import { registerServiceWorkerFx } from './sw'
import { formatDateForAnnouncement } from '../utils/date'
import { replaceTokens } from '../utils/helpers'

// create child domain
const { createEvent, createStore } = domain('announcements')

// *
// * types
// *

export type Announcement = {
  subject: string
  message: string
  buttonType: 'OK' | 'OK_CANCEL'
  type: 'Announcement' | 'Reminder' | 'Disabled' | 'Activation_TOA'
}

export type Tokens = {
  [token: string]: string
}

export type Announcements = {
  announcements: Announcement[]
  tokens: Tokens
}

export type ServiceWorkerEnvelope = {
  messageType: 'got-announcements'
  payload: Announcements
}

// *
// * events
// *

export const addAnnouncements = createEvent<Announcement[]>()
export const removeAnnouncement = createEvent<number>()
export const message = createEvent<ServiceWorkerEnvelope>()

// *
// * stores
// *

export const $announcements = createStore<Announcement[]>([])
  .on(addAnnouncements, (announcements, portions) =>
    announcements.concat(portions)
  )
  .on(removeAnnouncement, (announcements, i) =>
    announcements.filter((_, index) => i !== index)
  )

// *
// * connections
// *

guard({
  source: message,
  filter: (msg) => msg.messageType === 'got-announcements',
  target: addAnnouncements.prepend<ServiceWorkerEnvelope>((msg) => {
    const { announcements, tokens } = msg.payload
    tokens.$expirationDate = formatDateForAnnouncement(tokens.$expirationDate)
    return announcements.map((announcement) => ({
      subject: replaceTokens(announcement.subject, tokens),
      message: replaceTokens(announcement.message, tokens),
      buttonType: announcement.buttonType,
      type: announcement.type,
    }))
  }),
})

// *
// * side effects
// *

registerServiceWorkerFx.watch(() => {
  if ('serviceWorker' in navigator) {
    if ('BroadcastChannel' in window) {
      new BroadcastChannel('announcements').addEventListener(
        'message',
        message.prepend((e) => e.data)
      )
    } else {
      navigator.serviceWorker.addEventListener(
        'message',
        message.prepend((e) => e.data)
      )
    }
  }
})
