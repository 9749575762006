export const ResourceType = {
  INTERNAL: 'INTERNAL',
  EXTERNAL: 'EXTERNAL',
  WITHOUT_LINK: 'WITHOUT_LINK',
}

export const ContentType = {
  TV_CHANNEL: 'TV_CHANNEL',
  TV_SHOW: 'TV_SHOW',
  EPISODE: 'EPISODE',
  VOD: 'VOD',
  TV_CATEGORY: 'TV_CATEGORY',
  TV_SUBCATEGORY: 'TV_SUBCATEGORY',
  VOD_CATEGORY: 'VOD_CATEGORY',
  VOD_SUBCATEGORY: 'VOD_SUBCATEGORY',
  TV_SHOW_CATEGORY: 'TV_SHOW_CATEGORY',
  TV_SHOW_SUBCATEGORY: 'TV_SHOW_SUBCATEGORY',
}
