import { createSlice, original } from '@reduxjs/toolkit'
import * as tvActions from '/components/tv/store/actions'
import {
  changeChannel,
  changeChannelsCategory,
  getCarousels,
  getChannelEPG,
  getChannelsCategories,
  rewindTvChannel,
  getRecentlyWatched,
  updateFeaturedVodIsFavorite,
  updateFeaturedShowIsFavorite,
} from '/components/tv/store/actions'

const initialState: any = {
  search: '',

  activeChannel: {},
  activeProgram: null,
  EPG: null,
  activeCategory: 0,

  categories: [],
  channels: [],
  content: {},
  carousels: [],
  recentlyWatched: [],
}

const tvSlice = createSlice({
  name: 'tv',
  initialState,
  reducers: {
    setActiveCategory: (state, { payload }) => {
      state.activeCategory = payload
    },
    setActiveChannel: (state, { payload }) => {
      state.activeChannel = {
        ...payload,
        fromSec: null,
        continueUrl: null,
      }
    },
    setEPG: (state, { payload }) => {
      state.EPG = payload || []
    },
    setActiveProgram: (state, { payload }) => {
      state.activeProgram = payload
    },
    setSearch: (state, { payload }) => {
      state.search = payload.q
    },
    changeMediaProgressBar: (state, { payload }) => {
      state.activeProgram = payload
    },
    setActiveChannelUrl: (state, { payload }) => {
      state.activeChannel = {
        ...payload,
        channelUrl: null,
      }
    },
    unlockChannel: (state) => {
      state.activeChannel.locked = false
    },
  },
  extraReducers: {
    [getChannelsCategories.fulfilled.toString()]: (state, { payload }) => {
      state.categories = payload
      payload.forEach(({ id }) => (state.content[id] = []))
    },
    [getChannelsCategories.rejected.toString()]: (state) => {
      state.categories = initialState.categories
    },

    [getCarousels.fulfilled.toString()]: (state, { payload }) => {
      state.carousels = payload
    },

    [getCarousels.rejected.toString()]: (state) => {
      state.carousels = initialState.carousels
    },

    [changeChannelsCategory.fulfilled.toString()]: (state, { payload }) => {
      state.content[0] = payload
      payload.forEach((channel) => {
        channel.categoryIds.length
          ? channel.categoryIds.forEach((category) => {
              state.content[category] && state.content[category].push(channel)
            })
          : null
      })
    },

    [changeChannel.fulfilled.toString()]: (state, { payload }) => {
      const { playbackUrl, drm } = payload
      const channel = original(state.activeChannel)
      state.activeChannel = {
        ...channel,
        drm,
        channelUrl: playbackUrl,
      }
    },
    [changeChannel.rejected.toString()]: (state) => {
      const channel = original(state.activeChannel)
      state.activeChannel = { ...channel, drm: null, channelUrl: null }
    },

    [getChannelEPG.fulfilled.toString()]: (state, { payload }) => {
      state.EPG = payload
    },
    [getChannelEPG.rejected.toString()]: (state) => {
      state.EPG = []
    },
    [rewindTvChannel.fulfilled.toString()]: (state, { payload }) => {
      if (payload) {
        // likely empty payload?
        const fromSec = payload?.fromSec

        state.activeChannel.channelUrl = payload?.playUrl
        state.activeChannel.fromSec = fromSec ? fromSec * 1000 : null
        state.activeChannel.continueUrl = payload?.continueUrl
      }
    },
    [rewindTvChannel.rejected.toString()]: (state) => {
      state.activeChannel.channelUrl = null
      state.activeChannel.fromSec = null
      state.activeChannel.continueUrl = null
    },
    [getRecentlyWatched.fulfilled.toString()]: (state, { payload }) => {
      state.recentlyWatched = payload
    },
    [getRecentlyWatched.rejected.toString()]: (state) => {
      state.recentlyWatched = initialState.recentlyWatched
    },
    [updateFeaturedVodIsFavorite.toString()]: (state, { payload }) => {
      const carousel =
        state.carousels[
          state.carousels.findIndex((carousel) => carousel.type === 'VOD')
        ].content
      const vodIndex = carousel.findIndex(
        (movie) => movie.vod.id === payload.id
      )
      const newItem = { ...carousel[vodIndex], vod: payload }
      carousel.splice(vodIndex, 1, newItem)
    },
    [updateFeaturedShowIsFavorite.toString()]: (state, { payload }) => {
      const carouselIndex = state.carousels.findIndex(
        (carousel) => carousel.type === 'TV_SHOW'
      )

      const showIndex = state.carousels[carouselIndex].content.findIndex(
        (movie) => movie.tvShow.id === payload.id
      )
      const newItem = {
        ...state.carousels[carouselIndex].content[showIndex],
        tvShow: payload,
      }
      state.carousels[carouselIndex].content.splice(showIndex, 1, newItem)
    },
  },
})

export const actions = {
  ...tvSlice.actions,
  ...tvActions,
}

export const reducer = tvSlice.reducer
