import React from 'react'
import { popModal } from '/components/common/modal/model'
import { useTranslation } from 'react-i18next'
import { ModalI } from './interfaces'
import { Portal } from '/components/common/Portal'
import { Button } from '/components/common/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-regular-svg-icons'

import s from './index.module.scss'

export const Modal = ({ text }: ModalI): JSX.Element => {
  const { t } = useTranslation()
  const close = () => {
    popModal()
  }

  return (
    <Portal className={s.modalLayout}>
      <div className={s.modal}>
        <h1 className={s.modalTitle}>{t('common:Sorry')}</h1>
        {text ? <p className={s.modalContent}>{text}</p> : null}
        <Button
          type='button'
          title={t('common:button.OK')}
          className={`${s.button} button-primary button-small`}
          handleClick={close}
        />
        <FontAwesomeIcon
          icon={faTimes}
          className={s.closeButton}
          onClick={close}
        />
      </div>
    </Portal>
  )
}
