import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { ErrorI } from '/components/login/interfaces'
import s from '/components/login/login.module.scss'

interface ErrorScreenI {
  error: ErrorI
}

const ERR_CODE_MESSAGE = {
  WG0018: 'You have exceeded quantity of sign up attempts. Retry in 24 hours',
  WG0024: 'Your email service has been compromised',
  WG0034: 'Password is not valid',
  WG0037: 'Verification code has been sent to your email',
  WG0038:
    'Your registration request has expired. Please, try registration again',
  WG0045: 'Verification code is not valid',
  WG0046:
    'Your reset password request has expired. Please, try to reset password again',
  WG0047:
    'You have exceeded the quantity of code input attempts. Please, try to reset password again',
  WG0048: 'The limit of new code requests has been exceeded',
  WG0063: 'Verification code is not valid',
  WG0064:
    'You have exceeded the quantity of attempts. Please, retry in 5 minutes',
  WG0067: 'The limit of new code requests has been exceeded',
  WG0069:
    'Sorry, reset password option is unavailable now. Please, contact your content provider',
  WG0071:
    'You have exceeded quantity of reset password attempts. Retry in 24 hours',
  WG0072:
    'You have exceeded quantity of reset password attempts. Retry in 24 hours',
}

export const ErrorBlock = ({ error }: ErrorScreenI): JSX.Element => {
  const { code = '', message: respMessage = '' } = error

  let message
  if (ERR_CODE_MESSAGE[code]) message = ERR_CODE_MESSAGE[code]
  else if (respMessage) message = respMessage
  else message = 'Sorry, unknown error has occurred'

  return (
    <div className={`${s.messageBlock} ${s.messageBlock__error}`}>
      <FontAwesomeIcon icon={faExclamationTriangle} />
      <span>{message}</span>
    </div>
  )
}
