import React, { useEffect } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { Link, navigate, RouteComponentProps, useLocation } from '@reach/router'
import { useTranslation } from 'react-i18next'
import { Player } from '/components/common/player/Player'
import { PlayerPreloader } from '~/components/common/Preloaders'
import { ContentType } from '/components/home/constants'
import * as tvShowsActionCreators from '/components/tvShows/tvShowsActions'
import { convertUnixTimeToDate } from '/utils/date'
import {
  getTvShowsCategory,
  getTvShowsSubCategory,
  getTvShowsSeason,
} from '/components/tvShows/store/selectors'
import { GEOBLOCK_MESSAGE } from '/utils/constants'

interface Props extends RouteComponentProps, ConnectedProps<typeof connector> {
  tvShowId: string
  seasonId?: string
  episodeId: string
  categoryId?: string
  subCategoryId?: string
}

function TvShowPlayer(props: Props) {
  const {
    tvShowId,
    episode,
    season,
    tvShow,
    category,
    subCategory,
    seasonId,
    episodeId,
    url,
    getTvShowById,
    getSeasons,
    getEpisodesBySeason,
    getEpisodes,
    getEpisodeUrl,
    getEpisodeUrlBySeason,
    setActiveEpisode,
    setActiveSeason,
    updateUrl,
  } = props
  const { t } = useTranslation()
  const pageUrl = useLocation()

  useEffect(() => {
    return () => {
      updateUrl('')
    }
  }, [])

  const getDetails = async (tvShowId) => {
    const show = await getTvShowById(tvShowId).then((response) =>
      response && response.error ? navigate('/not_found') : response.payload
    )
    if (!show) return
    if (show.isWithSeason) {
      getSeasons(show.id)
      const episodesBySeason = await getEpisodesBySeason({
        tvShowId: Number(tvShowId),
        seasonId: Number(seasonId),
      }).then((response) => {
        response?.error ? navigate('/not_found') : response
      })
      const activeEpisode = episodesBySeason?.payload?.find(
        (episode) => episode.id === Number(episodeId)
      )
      getEpisodeUrlBySeason({
        tvShowId: Number(tvShowId),
        seasonId: Number(seasonId),
        episodeId: Number(episodeId),
      }).then((response) => {
        response?.error ? navigate('/not_found') : response
      })
      return activeEpisode && setActiveEpisode(activeEpisode)
    }

    const episodes = await getEpisodes(show.id).then((response) => {
      response?.error ? navigate('/not_found') : response
    })
    const activeEpisode = episodes?.payload?.find(
      (episode) => episode.id === Number(episodeId)
    )
    getEpisodeUrl({
      tvShowId: Number(tvShowId),
      episodeId: Number(episodeId),
    }).then((response) => {
      response?.error ? navigate('/not_found') : response
    })
    return activeEpisode && setActiveEpisode(activeEpisode)
  }

  useEffect(() => {
    tvShowId &&
      episodeId &&
      getDetails(tvShowId).then((response) =>
        response.error ? navigate('/not_found') : response
      )
    if (pageUrl.pathname.includes('//')) navigate('/not_found')
  }, [episodeId])

  const movieDurationFormater = (duration) => {
    const arr = duration?.split(':').map((i) => Number(i)) || []
    return arr[0] ? `${arr[0]}h ${arr[1]}m` : arr[1] ? `${arr[1]}m` : null
  }
  const episodeDuration = movieDurationFormater(episode.length)

  const viewCrumb = '/shows'
  const categoryCrumb = category ? `/categories/${category.id}` : ''
  const subCategoryCrumb = subCategory ? `/subs/${subCategory.id}` : ''
  const tvShowCrumb = tvShow ? `/${tvShow.id}` : ''

  return episode ? (
    <div className='player-page'>
      <div className='breadcrumbs'>
        <Link to={viewCrumb}>{t('TV Series')}</Link>
        {category && (
          <Link to={`${viewCrumb}/categories/${category.id}`}>
            {category.name === 'All' ? t('All') : category.name}
          </Link>
        )}
        {subCategory && (
          <Link to={`${viewCrumb}${categoryCrumb}/subs/${subCategory.id}`}>
            {subCategory.name}
          </Link>
        )}
        {tvShow && (
          <Link
            to={`${viewCrumb}${categoryCrumb}${subCategoryCrumb}/${tvShow.id}`}
          >
            {tvShow.name}
          </Link>
        )}
        {season && (
          <Link
            to={`${viewCrumb}${categoryCrumb}${subCategoryCrumb}${tvShowCrumb}`}
            onClick={() => setActiveSeason(season || {})}
          >
            {`${season.displayNumber}. ${season.name}`}
          </Link>
        )}
        <span>{`${episode.displayNumber}. ${episode.name}`}</span>
      </div>

      <div className='player-page__content'>
        <div className='player-page__player'>
          {tvShow?.blockedByAcl ? (
            <div className='player-page__info-box'>
              <h1 className='player-page__title'>{t('common:Sorry')}!</h1>
              <p className='player-page__message'>
                {t(`common:message.${GEOBLOCK_MESSAGE}`, {
                  item: `"${tvShow.name}"`,
                })}
              </p>
            </div>
          ) : url ? (
            <Player
              src={url}
              skin='vod'
              contentType={ContentType.TV_SHOW}
              stoppedTime={episode.stoppedTime}
              contentId={+episodeId}
              tvShowId={+tvShowId}
              seasonId={
                tvShow?.isWithSeason ? Number(seasonId) : 0
              } /* TODO: rewrite after seasonId will be fixed */
              contentTitle={episode.name}
              contentLive={false}
            />
          ) : (
            <PlayerPreloader />
          )}
        </div>
        <div className='player-page__info info'>
          <div className='info__title'>{episode.name}</div>
          <p className='short-info'>
            {episode.ageRating ? <span>{episode.ageRating}</span> : null}
            {episode.resolution ? (
              <span>
                {episode.resolution.substring(
                  episode.resolution.length,
                  episode.resolution.length - 2
                )}
              </span>
            ) : null}
            {episode.releaseTime ? (
              <span>{convertUnixTimeToDate(episode.releaseTime)}</span>
            ) : null}
            {episodeDuration ? <span>{episodeDuration}</span> : null}
          </p>

          {episode.directors && (
            <>
              <h3 className='info__subtitle'>{t('tvshow:Directors')}</h3>
              <p className='info__paragraph'>{episode.directors}</p>
            </>
          )}
          {episode.stars && (
            <>
              <h3 className='info__subtitle'>{t('tvshow:Stars')}</h3>
              <p className='info__paragraph'>{episode.stars}</p>
            </>
          )}
          {episode.description && (
            <>
              <h3 className='info__subtitle'>{t('tvshow:Description')}</h3>
              <p className='info__paragraph'>{episode.description}</p>
            </>
          )}
        </div>
      </div>
    </div>
  ) : null
}

function mapStateToProps(state, props) {
  return {
    episode: state.views.tvShows.activeEpisode,
    season: getTvShowsSeason(state, props),
    tvShow: state.views.tvShows.activeTvShow,
    category: getTvShowsCategory(state, props),
    subCategory: getTvShowsSubCategory(state, props),
    url: state.views.tvShows.url,
  }
}

const mapDispatchToProps = {
  getTvShowById: tvShowsActionCreators.getTvShowByIdRequest,
  getSeasons: tvShowsActionCreators.getSeasonsRequest,
  getEpisodesBySeason: tvShowsActionCreators.getEpisodesBySeasonRequest,
  getEpisodes: tvShowsActionCreators.getEpisodesRequest,
  setActiveEpisode: tvShowsActionCreators.setActiveEpisode,
  setActiveSeason: tvShowsActionCreators.setActiveSeason,
  getEpisodeUrl: tvShowsActionCreators.getEpisodeUrlRequest,
  getEpisodeUrlBySeason: tvShowsActionCreators.getEpisodeUrlBySeasonRequest,
  getEpisodeUrlSuccess: tvShowsActionCreators.getEpisodeUrlSuccess,
  updateUrl: tvShowsActionCreators.updateUrl,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export const TvShowPlayerPage = connector(TvShowPlayer)
