import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from '@reach/router'
import { useTranslation } from 'react-i18next'
import { setModal } from '/components/common/modal/model'
import Storage from '/utils/storage'
import { actions as tvActions } from './store'
import { getRecentlyWatchedTvChannel } from './store/selectors'
import { Carousel } from '../common/carousel/Carousel'
import { parseJson } from '/utils/helpers'
import { TvChannelCard } from '../common/cards/tv-channel-card'
import { GEOBLOCK_MESSAGE } from '/utils/constants'

const TvCarouselContainer = ({ getRecentlyWatched, recentlyWatched }) => {
  const maxLength = 36
  const navigate = useNavigate()
  const { t } = useTranslation('home')

  const handleClick = (channel) => {
    if (channel?.blockedByAcl) {
      setModal({
        text: t(`common:message.${GEOBLOCK_MESSAGE}`, {
          item: `"${channel.name}"`,
        }),
      })
      return
    }

    navigate(`/tv/categories/0/${channel.id}`)
  }

  useEffect(() => {
    const list = Storage.getItem('recentTVChannelsIds')
    if (list) {
      const parsedList = parseJson(list)
      parsedList && parsedList.length && getRecentlyWatched(parsedList)
    }
  }, [])

  return recentlyWatched && recentlyWatched.length ? (
    <Carousel
      carouselTitle={t('Recently watched Live Channels')}
      // TODO: rename class here and in another places
      className='featured'
      showAllOption={false}
    >
      {recentlyWatched.slice(0, maxLength).map((channel) => (
        <TvChannelCard
          key={`${channel.id}/${channel.name}`}
          channelTitle={`${channel.channelNumber}. ${channel.name}`}
          channelLogoUrl={channel.logoUrl}
          handleClick={() => handleClick(channel)}
          locked={channel.locked}
        />
      ))}
    </Carousel>
  ) : null
}

const mapStateToProps = (state) => {
  return {
    recentlyWatched: getRecentlyWatchedTvChannel(state),
  }
}

const mapDispatchToProps = {
  getRecentlyWatched: tvActions.getRecentlyWatched,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export const TvCarouselRecentlyWatched = connector(TvCarouselContainer)
