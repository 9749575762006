import { Route } from '~/root/routes'
import { HomePageContainer } from '../pages/home'

export const path = 'home'

export const homeRoutes = (isEnabled: boolean): Route[] => [
  {
    path: `/${path}`,
    name: 'Home',
    component: HomePageContainer,
    enable: isEnabled,
    main: true,
  },
]
