import { domain, forward } from '.'

// create child domain
const { createEvent, createEffect, createStore } = domain('preloader')

// *
// * events
// *

export const showPreloader = createEvent()
export const hidePreloader = createEvent()
export const hidePreloaderImmediately = createEvent()

// *
// * effects
// *

const hidePreloaderFx = createEffect(
  () => new Promise((resolve) => setTimeout(resolve, 1550))
)

// *
// * stores
// *

export const $preloadersCounter = createStore(0)
  .on(showPreloader, (count) => count + 1)
  .on(hidePreloaderFx.done, (count) => count - 1)
  .on(hidePreloaderImmediately, (count) => count - 1)

export const $shouldShowPreloader = $preloadersCounter.map((count) => count > 0)

// *
// * connections
// *

forward({ from: hidePreloader, to: hidePreloaderFx })
