import { createAsyncThunk } from '@reduxjs/toolkit'
import { fetchBannerCarousel } from '/services/api/v3/carousels'
import { hidePreloader, showPreloader } from '/models/preloader'
// export const decrementBy = createAction<number>('decrementBy')

export const getBannerCarousel = createAsyncThunk(
  'home/getBannerCarouselRequest',
  async (_, { rejectWithValue }) => {
    try {
      showPreloader()
      const result = await fetchBannerCarousel()
      hidePreloader()
      return result.payload
    } catch (e) {
      hidePreloader()
      rejectWithValue(e)
    }
  }
)
