import { domain, forward } from '.'

// create child domain
const { createEvent, createEffect, createStore } = domain('sw')

// *
// * events
// *

export const registerServiceWorker = createEvent()
export const message = createEvent<any>()

// *
// * effects
// *

export const registerServiceWorkerFx = createEffect(() => {
  if ('serviceWorker' in navigator) {
    return navigator.serviceWorker.register('/sw.js')
  }
  throw new Error('service workers are not available')
})

// *
// * stores
// *

export const $serviceWorkerRegistration =
  createStore<ServiceWorkerRegistration | null>(null).on(
    registerServiceWorkerFx.doneData,
    (_, reg) => reg
  )

// *
// * connections
// *

forward({
  from: registerServiceWorker,
  to: registerServiceWorkerFx,
})

// *
// * side effects
// *

registerServiceWorkerFx.watch(() => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.addEventListener(
      'message',
      message.prepend((e) => e.data)
    )
  }
})

registerServiceWorkerFx.doneData.watch((reg) => {
  console.log('sw registration succeeded, scope is', reg.scope)
  if (navigator.serviceWorker.controller === null) {
    console.warn(
      `🚨 you have probably shift-reload page, page is uncontrolled now`
    )
  }
})

registerServiceWorkerFx.failData.watch((error) => {
  console.error(`🚨 failed to register service worker:`, error)
})
