import React, { useRef, useState } from 'react'
import { setForm } from '/models/registration'
import { initiateResetPass, reset } from '../../models/forgotPassword'
import { ErrorBlock } from '/components/login/components/error_components/ErrorBlock'
import { Logo } from '/components/login/components/forms/FormLogo'
import { Button } from '/components/common/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserCircle } from '@fortawesome/pro-solid-svg-icons'
import { FORM_TYPE } from '/utils/constants'
import { capitalizeFirstLetter, validate } from '/utils/helpers'

import s from '../../login.module.scss'

export const ResetPasswordForm = (): JSX.Element => {
  const emailRef = useRef<HTMLInputElement>(null)
  const [fieldError, setError] = useState('')

  const handleInput = () => reset()

  const handleClick = () => {
    reset()
    setForm(FORM_TYPE.SIGN_IN)
  }

  const submit = (e) => {
    setError('')
    e.preventDefault()
    const email = emailRef?.current

    if (email) {
      const { value, validity } = email

      if (validity.valid) {
        return initiateResetPass(value)
      }

      email.className = `${s.invalid}`
      const error = validate(email)
      setError(capitalizeFirstLetter(error))
    }
  }

  return (
    <>
      <div className={s.loginBox}>
        <div className={s.loginHeader}>
          <Logo />
          <h1 className={s.loginTitle}>Reset Password</h1>
          <h2 className={`${s.message} ${s.messageLarge}`}>
            Enter your email address to reset your password.
          </h2>
        </div>
        <form
          className={`${s.loginForm} ${s.loginForm__paddingBottom}`}
          onSubmit={submit}
          noValidate
        >
          <div className={s.loginForm__field}>
            <FontAwesomeIcon
              className={s.loginForm__icon}
              icon={faUserCircle}
            />
            <div className={s.loginForm__label}>
              <input
                required
                ref={emailRef}
                id='email'
                name='email'
                type='email'
                pattern='.+@.+\..+$'
                placeholder={' '}
                onInput={handleInput}
              />
              <label htmlFor='email'>Email</label>
            </div>
          </div>
          <Button
            type='submit'
            title='Submit'
            className={`${s.button} button-primary button-small button-full-width`}
          />
          <div className={s.forgotPassword}>
            <span className={`${s.message}`}>Just remembered?</span>{' '}
            <a className={s.link} onClick={handleClick}>
              Click here
            </a>
          </div>
        </form>
      </div>
      {fieldError ? <ErrorBlock error={{ message: fieldError }} /> : null}
    </>
  )
}
