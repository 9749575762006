import React from 'react'

/**
 * Modal error component.
 */
export default class ErrorModal extends React.PureComponent {
  shouldComponentUpdate(nextProps, nextState) {
    const currentCode = this.props.error.code
    const nextCode = nextProps.error.code

    return currentCode !== nextCode
  }

  static onOpenPopup() {
    document.querySelector('#error-modal').style.display = 'block'
  }

  static hide() {
    document.querySelector('#error-modal').style.display = 'none'
  }

  static getContentByCode(code) {
    let content

    switch (code) {
      case 503:
        content = {
          title: 'System Upgrade',
          body: (
            <div>
              <p>
                We are performing an upgrade in our system to improve our
                services.{' '}
              </p>
              <p>Please be patient we will be back shortly.</p>
            </div>
          ),
        }
    }

    return content
  }

  render() {
    const { code } = this.props.error
    const content = ErrorModal.getContentByCode(code)

    return (
      <div
        id='error-modal'
        className='modal-custom'
        style={content ? { display: 'block' } : { display: 'none' }}
      >
        <div className='modal-content'>
          <div className='modal-header'>
            <h2>{content ? content.title : ''}</h2>
          </div>
          <div className='modal-body'>{content ? content.body : ''}</div>
        </div>
      </div>
    )
  }
}
