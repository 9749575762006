import { faTimes } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useModal } from '..'
import s from '../dialogs.module.scss'

export const DialogModal = ({
  title,
  message,
  confirmButtonTitle = 'OK',
  dismissButtonTitle = 'Cancel',
  onConfirm,
}: {
  title?: string
  message?: string | JSX.Element
  confirmButtonTitle?: string
  dismissButtonTitle?: string
  onConfirm: () => Promise<any>
}) => {
  const { unSetModal } = useModal()
  const onDismiss = () => unSetModal()
  const confirm = () => onConfirm().then(() => unSetModal())
  const { t } = useTranslation()

  return (
    <div className={s.dialogWrapper}>
      <FontAwesomeIcon
        icon={faTimes}
        onClick={unSetModal}
        className={s.closeButton}
      />

      <div className={s.dialog}>
        <h3 className={s.title}>{title}</h3>
        <div className={s.content}>{message}</div>
        <div className={s.actions}>
          <button className={'button-small button-ghost'} onClick={onDismiss}>
            {t(`common:button.${dismissButtonTitle}`)}
          </button>
          <button className={'button-small button-accent'} onClick={confirm}>
            {t(`common:button.${confirmButtonTitle}`)}
          </button>
        </div>
      </div>
    </div>
  )
}
