import React, { useRef, useState } from 'react'
import { useStore } from 'effector-react'
import { setForm } from '/models/registration'
import {
  verifyPass,
  resendCode,
  $email,
  $seconds,
  $forgotPassError,
  reset,
} from '../../models/forgotPassword'
import { Logo } from '/components/login/components/forms/FormLogo'
import { Button } from '/components/common/button'
import { Counter } from '../counter'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLockAlt } from '@fortawesome/pro-solid-svg-icons'
import { FORM_TYPE } from '/utils/constants'

import s from '../../login.module.scss'

export const VerifyCodeForm = (): JSX.Element => {
  const codeRef = useRef<HTMLInputElement>(null)
  const [code, setCode] = useState('')
  const email = useStore($email)
  const seconds = useStore($seconds)
  const errorCode = useStore($forgotPassError)?.code

  const submit = (e) => {
    e.preventDefault()
    const code = codeRef?.current?.value

    if (code) {
      verifyPass(code)
    }
  }

  const getNewCode = () => resendCode()

  const handleInput = (e) => {
    if (!(errorCode === 'WG0044' || errorCode === 'WG0047')) reset()
    setCode(e.target?.value)
  }

  const handleClick = () => {
    reset()
    setForm(FORM_TYPE.RESET_PASSWORD)
  }

  const overallErrors =
    errorCode === 'WG0044' || errorCode === 'WG0046' || errorCode === 'WG0047'

  return (
    <div className={s.loginBox}>
      <div className={s.loginHeader}>
        <Logo />
        <h1 className={s.loginTitle}>Verify account</h1>
        <h2 className={`${s.message} ${s.messageLarge}`}>
          {`We have sent a code to ${email}. Please enter the code below`}
        </h2>
      </div>
      <form
        className={`${s.loginForm} ${s.loginForm__paddingBottom}`}
        onSubmit={submit}
        noValidate
      >
        <div className={s.loginForm__field}>
          <FontAwesomeIcon className={s.loginForm__icon} icon={faLockAlt} />
          <div className={s.loginForm__label}>
            <input
              required
              ref={codeRef}
              id='code'
              type='text'
              placeholder={' '}
              onInput={handleInput}
            />
            <label htmlFor='code'>Code</label>
          </div>
        </div>
        <Button
          type='submit'
          title='Submit'
          isDisabled={!code || overallErrors || errorCode === 'WG0045'}
          className={`${s.button} button-primary button-small button-full-width`}
        />
        <p className={`${s.message} ${s.messageMarginBot}`}>
          Note! If you can not find the email with code reload your mail or
          check the spam
        </p>
        <Button
          type='button'
          title='New Code'
          isDisabled={
            overallErrors || errorCode === 'WG0048' || Boolean(seconds)
          }
          className={`${s.button} button-ghost button-small button-full-width`}
          handleClick={getNewCode}
        />
        {overallErrors ? (
          <div className={s.forgotPassword}>
            <span className={`${s.message}`}>Please, try again to</span>{' '}
            <a className={s.link} onClick={handleClick}>
              Reset password
            </a>
          </div>
        ) : seconds ? (
          <Counter time={seconds} />
        ) : null}
      </form>
    </div>
  )
}
