import React from 'react'
import { useTranslation } from 'react-i18next'
import { withPinLock, HOCProps } from '/components/common/player/PinLockHOC'
import { Portal } from '../../common/Portal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-regular-svg-icons'

interface PinLockModalProps extends HOCProps {
  pinWrapperRef: () => void
  pin: string
  securedPin: string
  title?: string
  error: boolean
  onInputHandler: () => void
  onMouseDown: () => boolean
  onPressButtonHandler: () => boolean
  closeModal: () => void
}

export const PinLockModal = ({
  pinWrapperRef,
  pin,
  securedPin,
  title,
  error,
  onInputHandler,
  onMouseDown,
  onPressButtonHandler,
  closeModal,
}: PinLockModalProps): JSX.Element => {
  const { t } = useTranslation('tv')

  return (
    <Portal className='full-screen-pinlock-modal'>
      <div className='pinlock-modal-content'>
        <h5 className='pinlock-modal-title'>{t('Locked channel')}</h5>
        {title && <h6 className='pinlock-modal-subtitle'>{title}</h6>}
        <div
          ref={pinWrapperRef}
          className='pin-inputs-wrapper'
          onKeyDown={onPressButtonHandler}
        >
          {[0, 1, 2, 3].map((item, index) => {
            return (
              <div key={index} className={`pin-input`}>
                <input
                  type='text'
                  inputMode='numeric'
                  pattern='[0-9]*'
                  maxLength={1}
                  value={securedPin[index] || pin[index] || ''}
                  onInput={onInputHandler}
                  onMouseDown={onMouseDown}
                />
                <span className='pin-input-underline' />
              </div>
            )
          })}
        </div>
        <p className={`pin-message ${error ? 'error' : ''}`}>
          {error
            ? t('Invalid PIN. Please try again')
            : t('Please enter PIN code to watch the content')}
        </p>

        <button className='pinlock-modal_close' onClick={closeModal}>
          <FontAwesomeIcon icon={faTimes} className='modal-close__icon-close' />
        </button>
      </div>
    </Portal>
  )
}

export const PinLockModalContainer = withPinLock(PinLockModal, 'catchup')
