export function base64ToArrayBuffer(str) {
  const bstr = atob(str)
  const len = bstr.length
  const bytes = new Uint8Array(len)
  for (let i = 0; i < len; i++) {
    bytes[i] = bstr.charCodeAt(i)
  }
  return bytes.buffer
}

export function arrayBufferToBase64(buffer) {
  let binary = ''
  const bytes = new Uint8Array(buffer)
  for (let i = 0, len = bytes.byteLength; i < len; i++) {
    binary += String.fromCharCode(bytes[i])
  }
  return btoa(binary)
}

export function uint8ArrayToString(array) {
  return String.fromCharCode.apply(null, new Uint16Array(array.buffer))
}
