import React from 'react'
import Image from '../../Image'
import { useTranslation } from 'react-i18next'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart } from '@fortawesome/pro-regular-svg-icons'
import { faHeart as faHeartSolid } from '@fortawesome/pro-solid-svg-icons'
import { useStore } from 'effector-react'
import { $backendVersion } from '/models/account'

import '../styles.scss'

interface FeaturedCardProps {
  imageUrl: string | null
  title: string
  ageRatings: string
  releaseDate: number
  handleClick: () => void
  handleFavoriteClick?: () => void
  favorite?: boolean
}
export const FeaturedCard = (props: FeaturedCardProps): JSX.Element => {
  const {
    imageUrl,
    title,
    ageRatings,
    releaseDate,
    handleClick,
    favorite,
    handleFavoriteClick,
  } = props
  const { t } = useTranslation('vod')
  const backendVersion = useStore($backendVersion)
  return (
    <div onClick={handleClick} className='card featured-card'>
      <Image imageUrl={imageUrl} defaultUrl={'/img/noContentIcon.svg'} />
      <div className='featured-card__content'>
        <div className='featured-card__title'>{title}</div>
        <div className='featured-card__info'>
          {releaseDate ? (
            <span>{t('Release Date {{year}}', { year: releaseDate })}</span>
          ) : null}
          {ageRatings ? (
            <span>{t('Age Rating {{ageRatings}}', { ageRatings })}</span>
          ) : null}
        </div>
        {backendVersion[0] >= '1.42' && handleFavoriteClick && (
          <div
            className={`add_to_favorite ${favorite ? 'liked' : ''}`}
            onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
              event.stopPropagation()
              handleFavoriteClick()
            }}
          >
            <FontAwesomeIcon icon={favorite ? faHeartSolid : faHeart} />
          </div>
        )}
      </div>
    </div>
  )
}
