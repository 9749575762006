import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from '@reach/router'
import { actions as vodActions } from '../store'
import { setModal } from '/components/common/modal/model'
import { useTranslation } from 'react-i18next'
import { Carousel } from '../../common/carousel/Carousel'
import { getHorizontalVODLogo } from '/components/vod/helpers'
import { HorizontalCard } from '/components/common/cards/horizontal-card/HorizontalCard'
import { getVodContentMovies } from '/components/vod/store/selectors'
import { _ } from '/config'
import { GEOBLOCK_MESSAGE } from '/utils/constants'

const VodsCarouselContainer = ({
  movies,
  handleMovieClick = null,
  loadContent,
  id,
  setIsVodFavorite,
}) => {
  const maxLength = 36
  const navigate = useNavigate()
  const { t } = useTranslation('home')

  useEffect(() => {
    loadContent && loadContent({ categoryId: 0, count: 36 })
  }, [])

  const handleClick = async (movie) => {
    if (movie?.blockedByAcl) {
      setModal({
        text: t(`common:message.${GEOBLOCK_MESSAGE}`, {
          item: `"${movie.name}"`,
        }),
      })
      return
    }

    handleMovieClick && handleMovieClick(movie)
    await navigate(`/vods/categories/0/${movie.id}`)
  }

  return movies.length ? (
    <Carousel
      categoryId={'lastAdded'}
      key={"'lastAdded'"}
      carouselTitle={t(_('header-last-added-movies'))}
      className='featured'
      showAllOption={false}
      id={id}
    >
      {movies.slice(0, maxLength).map((item) => {
        const imageUrl = item.imageHorizontalUrl
          ? getHorizontalVODLogo(item.imageHorizontalUrl)
          : getHorizontalVODLogo(item.imageUrl)
        return (
          <HorizontalCard
            favorite={item.favorite}
            handleFavoriteClick={() => setIsVodFavorite(item)}
            key={`${item.id}${item.name}`}
            title={item.name}
            imageUrl={imageUrl || ''}
            handleClick={() => handleClick(item)}
          />
        )
      })}
    </Carousel>
  ) : null
}

function mapStateToProps(state) {
  return {
    movies: getVodContentMovies(state, { categoryId: 0 }),
  }
}

const mapDispatchToProps = {
  loadContent: vodActions.getVodsByCategory,
  setIsVodFavorite: vodActions.setIsVodFavorite,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export const VodsCarouselLastAdded = connector(VodsCarouselContainer)
