import React, { memo } from 'react'
import { CatchupProgram } from '../../interfaces/index'
import { formatDateForEPG } from '~/utils/date'

import './styles.scss'

interface ProgramProps {
  program: CatchupProgram
  handleProgram: (program) => void
}

const ProgramComponent = ({
  program,
  handleProgram,
}: ProgramProps): JSX.Element => {
  const handleClick = () => handleProgram(program)

  return (
    <div className={`program `} onClick={handleClick}>
      <span className='program__time'>{`${formatDateForEPG(
        program.startSec * 1000
      )} - ${formatDateForEPG(program.endSec * 1000)}`}</span>
      <span className='program__title'>{program.name}</span>
    </div>
  )
}

export const Program = memo(ProgramComponent)
