export const LANGUAGES_LIST = [
  {
    codes: ['en', 'eng'],
    name: 'English',
    nativeName: 'English',
    names: {
      en: 'English',
      es: 'Inglés',
      fr: 'Anglaise',
      pt: 'Inglês',
    },
  },
  {
    codes: ['fr', 'fre', 'fra'],
    name: 'French',
    nativeName: 'Français',
    names: {
      en: 'French',
      es: 'Francés',
      fr: 'Français',
      pt: 'Francesa',
    },
  },
  {
    codes: ['pt', 'por'],
    name: 'Portuguese',
    nativeName: 'Português',
    names: {
      en: 'Portuguese',
      es: 'Portugués',
      fr: 'Portugais',
      pt: 'Português',
    },
  },
  {
    codes: ['es', 'spa'],
    name: 'Spanish',
    nativeName: 'Español',
    names: {
      en: 'Spanish',
      es: 'Español',
      fr: 'Espagnole',
      pt: 'Espanhola',
    },
  },
]
