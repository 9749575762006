export const tv = '/api/v3/channels/tv'
export const tvShows = '/api/v3/tvshows'
export const carousels = '/api/v3/carousels'
export const chat = '/api/v3/chat'
export const vods = '/api/v3/vods'

export const isOK = (response) =>
  response && response.data && response.data.status === 'OK'
export const isERROR = (response) =>
  !response || !response.data || response.data.status === 'ERROR'
export const ensureOK = (response) =>
  isOK(response) ? response : Promise.reject(response)
export const dispatchAction = (dispatch) => (action) => (data) =>
  dispatch(action(data))
