import React, { useRef, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useStore } from 'effector-react'
import { $pin } from '/models/account'
import { actions as tvActions } from '~/components/tv/store'
import { actions as catchupActions } from '~/components/catchup/store'

export interface HOCProps {
  unlockTvChannel: () => void
  unlockCatchupChannel: () => void
  autoFocus: boolean
}

export const withPinLock = <P extends HOCProps>(
  WrappedComponent: React.ComponentType<P>,
  mediaType: string
): React.FC<P & HOCProps> => {
  const PinLockHOC = ({
    unlockTvChannel,
    unlockCatchupChannel,
    ...props
  }: HOCProps) => {
    const pinWrapperRef = useRef<HTMLElement | null>(null)

    const subscriberPin = useStore($pin)
    const [pin, setPin] = useState<string>('')
    const [securedPin, setSecuredPin] = useState<string>('')

    const [error, setError] = useState<boolean | null>(null)

    const VALIDATION_DELAY = 200
    const PIN_CODE_MASKING_DELAY = 500
    const ERROR_SETTING_DELAY = 700

    const addValue = (value) => {
      setPin((pin) => pin + value)
      setTimeout(() => {
        setSecuredPin((securedPin) => securedPin + '*')
      }, PIN_CODE_MASKING_DELAY)
    }

    const deleteValue = () => {
      setPin((pin) => pin.slice(0, -1))
      setSecuredPin((securedPin) => securedPin.slice(0, -1))
    }

    const reset = () => {
      setPin('')
      setSecuredPin('')
    }

    const validate = () => {
      const isPinValide = pin === subscriberPin
      if (isPinValide) {
        mediaType === 'catchup' ? unlockCatchupChannel() : unlockTvChannel()
        reset()
      } else {
        setTimeout(() => {
          setError(true)
          reset()
        }, ERROR_SETTING_DELAY)
      }
    }

    const onPressButtonHandler = (e) => {
      e.key === 'Backspace' && deleteValue()
    }

    const onInputHandler = ({ target }) => {
      const { value } = target
      if (Number.isInteger(Number(value))) {
        addValue(value)
      }
    }

    const onMouseDown = (e) => {
      e.preventDefault()
      const inputs = e.target.parentElement?.parentElement?.children
      inputs?.[pin.length]?.firstElementChild?.focus()
    }

    useEffect(() => {
      const inputs = pinWrapperRef?.current?.children

      if (!pin.length && (props.autoFocus || error !== null)) {
        return inputs?.[0]?.firstElementChild?.focus()
      }

      if (pin.length) {
        setError(false)
        inputs?.[pin.length]?.firstElementChild?.focus()
      }

      const isLengthIdentical = pin.length === subscriberPin.length
      setTimeout(() => {
        if (isLengthIdentical) validate()
      }, VALIDATION_DELAY)
    }, [pinWrapperRef, pin])

    return (
      <WrappedComponent
        pinWrapperRef={pinWrapperRef}
        pin={pin}
        securedPin={securedPin}
        error={error}
        onInputHandler={onInputHandler}
        onMouseDown={onMouseDown}
        onPressButtonHandler={onPressButtonHandler}
        {...(props as P)}
      />
    )
  }

  const mapDispatchToProps = {
    unlockTvChannel: tvActions.unlockChannel,
    unlockCatchupChannel: catchupActions.unlockChannel,
  }

  const connector = connect(undefined, mapDispatchToProps)

  // for better debugging
  // function getDisplayName(WrappedComponent) {
  //   return WrappedComponent.displayName || WrappedComponent.name || 'Component';
  // }
  // PinLockHOC.displayName = `PinLockHOC(${getDisplayName(WrappedComponent)})`;

  return connector(PinLockHOC)
}
