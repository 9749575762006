import React, {
  useContext,
  useEffect,
  useRef,
  useCallback,
  useState,
  useLayoutEffect,
} from 'react'

import { GridContext, GridContextType } from './grid-context'
import { IntersectionObserverTarget } from '../IntersectionObserverTarget'

import './styles.scss'
interface GridProps {
  id: number
  contextSelector?: string
  page: { number: number; last: boolean }
  children: JSX.Element[]
  getItemsByPage: (id, nextPage) => void
  title?: string
  className?: string
}

export const Grid = (props: GridProps): JSX.Element => {
  const {
    id,
    children,
    page,
    getItemsByPage,
    title,
    className,
    contextSelector,
  } = props

  const { getGridActivePosition, addGrid, updateGrid } = useContext(
    GridContext
  ) as GridContextType

  const gridRef = useRef<HTMLDivElement>(null)

  let currentGrid, cardsInRow, rowHeight, position

  const [gridOffset, setGridOffset] = useState(0)
  const [clientHeight, setClientHeight] = useState(0)
  const [activeCard, setActiveCard] = useState(0)

  useEffect(() => {
    if (contextSelector) {
      currentGrid = gridRef.current
      activeCard === undefined && addGrid(contextSelector)
      setClientHeight(window.innerHeight)

      setGridOffset(currentGrid.getBoundingClientRect()?.y)
      cardsInRow = Math.floor(
        currentGrid.offsetWidth / currentGrid.children[0]?.offsetWidth
      )
      rowHeight =
        currentGrid.children[cardsInRow]?.getBoundingClientRect().height + 10
    }
  })

  const handleScroll = () => {
    if (contextSelector) {
      position =
        Math.floor((clientHeight - gridOffset) / rowHeight) * cardsInRow
      updateGrid({
        id: contextSelector,
        position:
          position <= children.length - 1 ? position : children.length - 1,
      })
    }
  }

  useLayoutEffect(() => {
    document
      .querySelector('.page__inner')!
      .addEventListener('scroll', handleScroll)
    return () =>
      document
        .querySelector('.page__inner')!
        .removeEventListener('scroll', handleScroll)
  }, [handleScroll])

  useLayoutEffect(() => {
    if (contextSelector) setActiveCard(getGridActivePosition(contextSelector))
  }, [])

  useEffect(() => {
    if (contextSelector)
      currentGrid.children[activeCard]?.scrollIntoView({ block: 'end' })
  }, [activeCard])

  const load = useCallback(() => {
    if (page && !page.last) {
      getItemsByPage(id, page.number + 1)
    }
  }, [page])

  return (
    <>
      <div className='grid-layout__title'>{title}</div>
      <div className={`grid ${className}`} ref={gridRef}>
        {children}

        <IntersectionObserverTarget load={load} page={page?.number || 0} />
      </div>
    </>
  )
}
