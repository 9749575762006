import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle, faSearch } from '@fortawesome/pro-regular-svg-icons'
import './search-field/styles.scss'

interface Props {
  placeholder?: string
  handleSearch: (query: string) => void
}

export const SearchField = ({
  placeholder,
  handleSearch,
}: Props): JSX.Element => {
  const [q, setQ] = useState<string>('')

  useEffect(() => {
    return () => {
      setQ('')
      handleSearch('')
    }
  }, [])

  const handleChange = (e) => setQ(e.target.value)

  const handleKeyPress = (e) => e.key === 'Enter' && handleSearch(q)

  const clearSearchField = () => {
    setQ('')
    handleSearch('')
  }

  return (
    <div className='search-bar'>
      <FontAwesomeIcon icon={faSearch} className='icon' />
      <input
        id='search'
        type='text'
        className='search-bar__input input'
        placeholder={placeholder || ''}
        value={q}
        onChange={handleChange}
        onKeyPress={handleKeyPress}
      />
      {q && (
        <FontAwesomeIcon
          icon={faTimesCircle}
          className='icon'
          onClick={clearSearchField}
        />
      )}
    </div>
  )
}

export default SearchField
