import VideoJSExport, { VideoJsPlayer } from 'video.js'
import { config } from '/config'

export type VideoJS = typeof VideoJSExport
export type { VideoJsPlayer }

interface Source {
  id?: number
  tvShowId?: number
  seasonId?: number
  type?: string
  title?: string
  live?: boolean
  drm?: any
}

export interface AddonModule {
  init(videojs: VideoJS): void
  create(player: VideoJsPlayer, account: any): void
  play(source: Source): void
  end(): void
  destroy(): void
}

const addonsAsync: Promise<AddonModule>[] = []
const addons: AddonModule[] = []

function importAddon(m: Promise<AddonModule>) {
  addonsAsync.push(m)
  m.then((addon) => {
    const index = addonsAsync.indexOf(m)
    if (index > -1) {
      addonsAsync.splice(index, 1)
    }
    addons.push(addon)
  })
}

if (config.APP_NPAW_ENABLED) {
  importAddon(import('./npaw') as any)
}
importAddon(import('./continueWatchingAddon') as any)

/*
 * Hooks
 */

const method =
  <N extends keyof AddonModule>(name: N) =>
  (...args: Parameters<AddonModule[N]>): void => {
    for (const addon of addons) {
      addon[name]?.(...args)
    }
    for (const addon of addonsAsync) {
      addon.then((addon) => {
        addon[name]?.(...args)
      })
    }
  }

export const init = method('init')
export const create = method('create')
export const play = method('play')
export const end = method('end')
export const destroy = method('destroy')
