import cn from 'classnames'
import React from 'react'
import { useStore } from 'effector-react'
import { $shouldShowPreloader } from '/models/preloader'
import './Preloaders.scss'

/**
 * Global preloader blocking spinner
 */
export const GlobalPreloader = () => (
  <div
    className={cn('global-preloader', {
      'global-preloader-hidden': !useStore($shouldShowPreloader),
    })}
  >
    <Preloader />
  </div>
)

export const Preloader = () => (
  <div className='preloader-wrapper active'>
    <div className='spinner-layer spinner-blue-only'>
      <div className='circle-clipper left'>
        <div className='circle' />
      </div>
      <div className='gap-patch'>
        <div className='circle' />
      </div>
      <div className='circle-clipper right'>
        <div className='circle' />
      </div>
    </div>
  </div>
)

/**
 * Preloader functional component
 */
export const ListPreloader = () => (
  <div className='preloader-list'>
    <div className='preloader-list-dot' />
    <div className='preloader-list-dot' />
    <div className='preloader-list-dot' />
  </div>
)

export const PlayerPreloader = () => <div className='player-preloader'></div>
