import React, { memo, useMemo } from 'react'
import { getRandomLockedChannelPoster, getRandomPoster } from '~/utils/helpers'
import Image from '../../Image'

import '../styles.scss'

interface CategoryCardProps {
  categoryTitle: string | JSX.Element
  handleClick: () => void
  className?: string
  categoryId: number
}

export const CategoryCardComponent = (
  props: CategoryCardProps
): JSX.Element => {
  const { categoryTitle, categoryId, handleClick, className } = props
  const image = useMemo(() => getRandomPoster(), [categoryId])
  return (
    <div className={`card category-card`} onClick={handleClick}>
      <Image imageUrl={image} defaultUrl={'/img/noLogo.svg'} />
      <div className='category-card__title'>{categoryTitle}</div>
    </div>
  )
}

export const CategoryCard = memo(CategoryCardComponent)
