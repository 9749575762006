import { formatDateToWeekDay, formatDateToWeekDayShort } from '~/utils/date'
import React from 'react'

import './styles.scss'
interface DayProps {
  day: string
  isActive: boolean
  handleClick: (day) => void
}

export const Day = ({ day, isActive, handleClick }: DayProps): JSX.Element => {
  const weekDay = formatDateToWeekDay(day)

  return (
    <div className={`day ${isActive ? 'active' : ''}`} onClick={handleClick}>
      <span className='number'>{weekDay.split(' ')[0]}</span>
      <span className='title-long'>{weekDay.split(' ')[1]}</span>
      <span className='title-short'>
        {formatDateToWeekDayShort(day).toUpperCase()}
      </span>
    </div>
  )
}
