import StatsCollector from './collector'

export const stats = new StatsCollector()

/**
 * Redux middleware
 */
export default () => (store) => (next) => (action) => {
  const result = next(action)
  const views = store.getState().views

  stats.update(views)

  return result
}
