import React, { useEffect } from 'react'
import { useStore } from 'effector-react'
import { getToa, acceptToaFx, declineToaFx, $toa } from '/models/toa'
import { useTranslation } from 'react-i18next'

export const Toa = () => {
  const toa = useStore($toa)
  const { t } = useTranslation()

  useEffect(() => {
    getToa()
  }, [])

  return (
    <div
      id='terms-of-agreement'
      className='terms-of-agreement terms-of-agreement-active'
    >
      <div className='terms-of-agreement-info'>
        <h1 className='terms-of-title'>{toa?.title}</h1>
        <div className='terms-of-agreement-text'>
          {toa?.message?.split(/<br \/>|\n/g).map((item, i) => (
            <div key={i}>{item}</div>
          ))}
        </div>
      </div>
      <div
        className='terms-of-agreement-footer'
        id='terms-of-agreement-close-popup'
      >
        {toa?.type === 'OK' && (
          <button
            type='button'
            className='btn text-of-agreement-agree'
            onClick={(event) => {
              event.preventDefault()
              acceptToaFx()
            }}
          >
            {t('common:button.Ok to Close')}
          </button>
        )}
        {toa?.type === 'OK_CANCEL' && (
          <button
            type='button'
            className='btn text-of-agreement-agree'
            onClick={(event) => {
              event.preventDefault()
              acceptToaFx()
            }}
          >
            {t('common:button.Accept')}
          </button>
        )}
        {toa?.type === 'OK_CANCEL' && (
          <button
            type='button'
            className='btn text-of-agreement-decline'
            onClick={(event) => {
              event.preventDefault()
              declineToaFx()
            }}
          >
            {t('common:button.Decline')}
          </button>
        )}
      </div>
    </div>
  )
}
