import { createSelector } from 'reselect'
import { getView } from '~/root/rootSelectors'
import type {
  TvShowCategory,
  TvShowSeason,
} from '/components/tvShows/interfaces'

const getTvShowsView = createSelector(getView, (view) => view.tvShows)

const getTvShowsCategories = createSelector(
  getTvShowsView,
  (view) => view.categories
)

const getTvShowsSeasons = createSelector(getTvShowsView, (view) => view.seasons)

const getTvShoesContent = createSelector(
  getTvShowsView,
  (tvShows) => tvShows.content
)

export const getTvShowsContentById = createSelector(
  [getTvShoesContent, (state, props) => props],
  (content, props) => {
    const id = props.subCategoryId || props.categoryId || 0
    if (!content[id]) return null
    return content[id] || null
  }
)

export const getTvShowsCategory = createSelector(
  [getTvShowsCategories, (state, props) => props.categoryId],
  (categories, categoryId) => {
    return categories.find((c: TvShowCategory) => c.id === +categoryId)
  }
)

export const getTvShowsSubCategory = createSelector(
  [getTvShowsCategory, (state, props) => props.subCategoryId],
  (category, subCategoryId) => {
    if (category && category.subCategories) {
      return category.subCategories.find(
        (c: TvShowCategory) => c.id === +subCategoryId
      )
    }
  }
)

export const getTvShowsSeason = createSelector(
  [getTvShowsSeasons, (state, props) => props.seasonId],
  (seasons, seasonId) => {
    return seasons?.find((c: TvShowSeason) => c.id === +seasonId)
  }
)
