import React from 'react'

import s from '/components/login/login.module.scss'

interface CounterProps {
  time: number
}

export const Counter = ({ time }: CounterProps): JSX.Element => (
  <p className={`${s.message} ${s.counter}`}>Request new code in {time} sec</p>
)
