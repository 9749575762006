import axios from 'axios'
import { persist } from 'effector-storage/local/fp'
import { domain, forward, TAKE_FIRST } from '.'
// ! This model SHOULD NOT import any other models, to avoid circular dependencies

// create child domain
const { createEvent, createStore, createReEffect } = domain('account')

// *
// * events
// *

export const setAccount = createEvent<any>()
export const setAccountWithoutProfile = createEvent<any>()
export const resetAccount = createEvent()

// *
// * effects
// *

export const getAccountFx = createReEffect({
  strategy: TAKE_FIRST,
  handler: () => axios.get('/api/account'),
})

// *
// * stores
// *

export const $account = createStore<any>(null)
  .on(setAccount, (_, account) => account)
  .on(setAccountWithoutProfile, (_, { profileId, ...account }) => account)
  .reset(resetAccount)

// *
// * connections
// *

// set account on successful get account
forward({
  from: getAccountFx.doneData.map((payload) => payload.data),
  to: setAccountWithoutProfile,
})

// reset account on failed get account
forward({
  from: getAccountFx.fail,
  to: resetAccount,
})

// *
// * derived stores
// *

// get pin code from account
export const $pin = $account.map((account) => account?.subscriberPin ?? null)
export const $backendVersion = $account.map(
  (account) => account?.version?.match(/^(\d+)\.(\d+)/) ?? []
)

// get profile id from account
export const $profileId = createStore<number | null>(null)
  .on($account, (id, account) => account?.profileId ?? id ?? null)
  .thru(persist({ key: 'profile' }))

// get location from account
export const $location = $account.map((account) => {
  if (account === null || !account.location) return null

  const loc = account.location.replace(/^['"]?|['"]?$/, '').split(',')
  const city = loc[0].trim()
  const country = loc[1].trim()

  let latitude = account.location.match(/latitude=(-?[\d.]+)/)
  let longitude = account.location.match(/longitude=(-?[\d.]+)/)
  latitude = latitude && latitude.length === 2 ? latitude[1] : null
  longitude = longitude && longitude.length === 2 ? longitude[1] : null

  return { city, country, latitude, longitude }
})
