import type { VideoJsPlayer } from 'video.js'
import type { LAProps, SourceWithDRM } from './drm/types'
import cn from 'classnames'
import React, { useEffect, useRef, memo, Children } from 'react'
import * as addons from './addons'
import { useTranslation } from 'react-i18next'

// videojs + plugins + custom controls
import videojs from 'video.js'
import 'videojs-contrib-dash'
import 'videojs-contrib-quality-levels'
import 'videojs-http-source-selector'
import 'videojs-contrib-eme'
import 'videojs-errors'
import './controls'
import esMain from 'video.js/dist/lang/es.json'
import frMain from 'video.js/dist/lang/fr.json'
import ptMain from 'video.js/dist/lang/pt-PT.json'
import esErrors from 'videojs-errors/dist/lang/es.json'
import frErrors from 'videojs-errors/dist/lang/fr.json'
import ptErrors from 'videojs-errors/dist/lang/pt.json'
import esPlayer from '/services/locales/es/player.json'
import frPlayer from '/services/locales/fr/player.json'
import ptPlayer from '/services/locales/pt/player.json'

import { keySystems, keySystemOptions } from './drm'
import { useStore } from 'effector-react'
import { $account } from '/models/account'
import { $volume, $pip, setVolume, enterPip, leavePip } from '/models/player'
import { $appLanguage } from '/models/settings'

import './Player.scss'

// add dumb declarations for VideoJS plugins in use
declare module 'video.js' {
  export interface VideoJsPlayer {
    httpSourceSelector: () => void
    eme: () => void
    errors: () => void
    requestPictureInPicture: () => Promise<void>
  }
}

//
;(window as any).videojs = videojs // add global variable just for convenience
videojs.log.level('all')

// init addons
addons.init(videojs)

type Props = {
  src: string
  drm?: { la: LAProps }
  skin: string
  hasLiveRewind?: boolean
  rewindBack?: () => void
  rewindForward?: () => void
  stoppedTime?: number
  contentId?: number
  contentType?: string
  contentTitle?: string
  contentLive?: boolean
  tvShowId?: number
  seasonId?: number
  children?: JSX.Element
}

export const Player = memo(
  ({
    skin,
    src,
    drm,
    hasLiveRewind,
    rewindBack,
    rewindForward,
    stoppedTime,
    contentId,
    contentType,
    contentTitle,
    contentLive,
    tvShowId,
    seasonId,
    children,
  }: Props): JSX.Element => {
    const account = useStore($account)
    const { level, mute } = useStore($volume)
    const isPip = useStore($pip)
    const appLanguage = useStore($appLanguage)
    const video = useRef<HTMLVideoElement>(null)
    const player = useRef<VideoJsPlayer | null>(null)
    const { t } = useTranslation()

    // if video was in PiP, request it again
    // only works in chrome :(
    const reenterPip = () => {
      if (isPip && player.current) {
        if (videojs.browser.IS_CHROME) {
          player.current.requestPictureInPicture()
        } else {
          leavePip()
        }
      }
    }

    // create VideoJS instance on component mount and dispose on unmount
    useEffect(() => {
      if (!video.current || !document.body.contains(video.current)) return

      videojs.log('🎬 create player')

      // add global variable just for convenience
      // https://docs.videojs.com/index.html
      player.current = (window as any).player = videojs(video.current, {
        controls: true,
        preload: 'auto',
        autoplay: 'any',
        aspectRatio: '16:9', // puts the player in fluid mode
        html5: {
          nativeCaptions: false, // videojs.browser.IS_SAFARI
          dash: {
            // https://github.com/videojs/videojs-contrib-dash#passing-options-to-dashjs
          },
          vhs: {
            // https://github.com/videojs/http-streaming
            handleManifestRedirects: true,
            // enableLowInitialPlaylist: true,
            overrideNative: !videojs.browser.IS_SAFARI,
          },
        },
        language: appLanguage,
        languages: {
          es: { ...esMain, ...esErrors, ...esPlayer },
          fr: { ...frMain, ...frErrors, ...frPlayer },
          pt: { ...ptMain, ...ptErrors, ...ptPlayer },
        },
      })

      // pass player instance to addons
      addons.create(player.current, account)

      // add live rewind buttons to control bar, if needed
      if (hasLiveRewind) {
        const controlBar = player.current.getChild('controlBar')
        if (controlBar) {
          controlBar.addChild('Rewind', { clickHandler: rewindBack }, 0)
          controlBar.addChild('Forward', { clickHandler: rewindForward }, 2)
        }
      }

      // save volume level and mute state in local storage
      player.current.on('volumechange', function (this: VideoJsPlayer) {
        setVolume({
          level: this.volume(),
          mute: this.muted(),
        })
      })

      player.current.on('enterpictureinpicture', enterPip)
      player.current.on('leavepictureinpicture', leavePip)
      player.current.on('loadedmetadata', reenterPip)
      player.current.on('ended', addons.end)

      // initialize videojs-http-source-selector plugin
      // https://github.com/jfujita/videojs-http-source-selector
      player.current.httpSourceSelector()

      // initialize videojs-contrib-eme plugin
      // https://github.com/videojs/videojs-contrib-eme
      player.current.eme()

      // display VideoJS errors
      // https://github.com/brightcove/videojs-errors
      player.current.errors()

      // on destroy
      return () => {
        if (player.current) {
          // notify addons on destroying player instance
          addons.destroy()

          videojs.log('🎬 dispose player')
          player.current.dispose()
          player.current = null
        }
      }
    }, [hasLiveRewind || false, account])

    // external volume change
    useEffect(() => {
      if (player.current) {
        player.current.volume(level)
        player.current.muted(mute)
      }
    }, [level, mute])

    // external stream link/drm change
    useEffect(() => {
      if (player.current) {
        videojs.log('💥 play url', src, drm)
        const source: SourceWithDRM = { src }

        // notify addons about playback start
        addons.play({
          id: contentId,
          type: contentType,
          title: contentTitle,
          live: contentLive,
          tvShowId,
          seasonId,
        })

        // DASH
        if (src.includes('.mpd')) {
          source.type = 'application/dash+xml'
          if (drm) {
            source.keySystemOptions = keySystemOptions(drm.la)
          }
        }

        // HLS
        else {
          source.type = 'application/x-mpegURL'
          if (drm) {
            source.keySystems = keySystems(drm.la)
          }
        }

        console.log('✅', source)
        player.current.src(source)
      }
    }, [src, drm])

    useEffect(() => {
      if (player.current) {
        player.current.currentTime(stoppedTime || 0)
      }
    }, [stoppedTime])

    return (
      <div className={cn('videojs-container', skin)}>
        <div data-vjs-player>
          <video
            className='video video-js vjs-big-play-centered'
            ref={video}
            playsInline
          >
            {t('common:message.your browser does not support the video tag')}
          </video>
          {children}
        </div>
      </div>
    )
  }
)
