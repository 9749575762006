import React, { useCallback, useState, useEffect } from 'react'
import { convertUnixTimeToDate } from '~/utils/date'
import { Tooltip } from '.'
import { TvShowEpisode, TvShowSeason } from '../../interfaces'

const TooltipContext = React.createContext({
  openTooltip: (e, {}) => {},
  tooltip: false,
  closeTooltip: () => {},
  content: {
    displayNumber: null,
    name: null,
    stars: null,
    directors: null,
    description: null,
    year: null,
  },
  setContent: ({}) => {},
})

export const TooltipProvider = (props) => {
  const [tooltip, setTooltip] = useState(false)
  const [coords, setCoords] = useState({
    x: 0,
    y: 0,
  })

  const [content, setContent] = useState<
    Record<string, number | string | null> | undefined
  >()

  const closeTooltip = useCallback(() => {
    setTooltip(false)
  }, [setTooltip])

  const openTooltip = useCallback(
    (
      e: React.MouseEvent<HTMLDivElement, MouseEvent>,
      content: TvShowSeason | TvShowEpisode
    ) => {
      const { clientX, clientY } = e

      const newContent = Object.entries(content).reduce(
        (acc, [key, value]) => ({
          ...acc,
          [key === 'releaseTime' ? 'year' : key]:
            key === 'releaseTime' ? convertUnixTimeToDate(value) : value,
        }),
        {}
      )
      setContent(newContent)
      setCoords({ x: clientX, y: clientY })
      setTooltip(true)
    },
    [setTooltip, content]
  )

  return (
    <TooltipContext.Provider
      value={{ tooltip, closeTooltip, openTooltip }}
      {...props}
    >
      {props.children}
      {tooltip && (
        <Tooltip
          content={content}
          closeTooltip={closeTooltip}
          coords={coords}
        />
      )}
    </TooltipContext.Provider>
  )
}

export const useTooltip = () => {
  const context = React.useContext(TooltipContext)
  if (context === undefined) {
    throw new Error('useModal must be used within a UserProvider')
  }

  return context
}
