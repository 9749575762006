import React, { useState } from 'react'
// import { ClearFieldIcon } from '../icons/clear-field-icon'
// import { SearchIcon } from '../icons/search-icon'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faTimesCircle, faSearch } from '@fortawesome/pro-regular-svg-icons'

import './styles.scss'

interface SearchFieldProps {
  placeholder: string
  handleSearch: (searchQuery: { q: string }) => void
}

export const SearchField = (props: SearchFieldProps): JSX.Element => {
  const { placeholder, handleSearch } = props

  const [search, setSearch] = useState<string>('')

  const handleSubmit = (event) => {
    if (event.key === 'Enter') {
      handleSearch({ q: search })
    }
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
  }

  const clearField = () => {
    handleSearch({ q: '' })
    setSearch('')
  }

  return (
    <div className='search-bar'>
      <FontAwesomeIcon icon={faSearch} className='icon' />
      <input
        id='search'
        type='text'
        className='search-bar__input  input'
        name='searchQuery'
        placeholder={placeholder}
        value={search}
        onChange={handleChange}
        onKeyPress={handleSubmit}
      />
      {search && (
        <FontAwesomeIcon
          icon={faTimesCircle}
          className='icon'
          onClick={clearField}
        />
      )}
    </div>
  )
}

export default SearchField
