import React from 'react'
import { setForm } from '/models/registration'
import { ErrorI } from '../../interfaces'
import { Portal } from '/components/common/Portal'
import { Logo } from '/components/login/components/forms/FormLogo'
import { Button } from '/components/common/button'
import { FORM_TYPE } from '/utils/constants'

import s from './errorScreen.module.scss'

interface ErrorScreenI {
  error: {
    geoBlockWBS?: ErrorI | null
    geoBlock?: ErrorI | null
    registrationFailed?: ErrorI | null
  }
}

export const ErrorScreen = ({
  error: { geoBlockWBS, geoBlock, registrationFailed },
}: ErrorScreenI): JSX.Element => (
  <Portal className={`${s.errorScreen}`}>
    <div className={s.errorBox}>
      <Logo />
      <h1 className={s.title}>Sorry!</h1>

      {(geoBlockWBS || geoBlock) && (
        <p className={`${s.message}`}>
          According to Geoblock Policy the service is unavailable in your region
        </p>
      )}

      {registrationFailed && (
        <>
          <p className={`${s.message}`}>
            Something went wrong. Please, try to sign up later
          </p>
          <Button
            type='button'
            title='Sign Up'
            className={`${s.button} button-primary button-small`}
            handleClick={() => setForm(FORM_TYPE.SIGN_UP)}
          />
        </>
      )}
    </div>
  </Portal>
)
