import videojs from 'video.js'

import './Forward.scss'

const Button = videojs.getComponent('Button')
const Component = videojs.getComponent('Component')

class Forward extends Button {
  controlText_ = 'Forward'

  buildCSSClass() {
    return `vjs-forward-control ${super.buildCSSClass()}`
  }
}

Forward.prototype.controlText_ = 'Forward'
Component.registerComponent('Forward', Forward)
