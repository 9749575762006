import {
  getPercentageFromDate,
  formatDateForEPG,
  formatDateToMiliseconds,
  getDay,
} from './date'

/**
 * @param {array} list - array with EPG programs from server
 * @returns {array} new array sorted from list via
 */
export function sortEPGListByStartDate(list) {
  return list.sort(
    (a, b) =>
      formatDateToMiliseconds(a.start) - formatDateToMiliseconds(b.start)
  )
}

/**
 * @param {object} hit - EPG program
 * @returns {object} || null; format object for ProgressBar render
 */
function getCurrentChannelEPG(hit) {
  const progress = getPercentageFromDate(hit.start, hit.stop)
  return progress < 100
    ? {
        name: hit.title,
        progress,
        timeInterval: {
          start: hit.start,
          stop: hit.stop,
        },
      }
    : null
}

/**
 * @param {array} epg - programs
 * @returns {object}; format object for pure React render
 */
function mapProgramsToRenderFormat(epg) {
  return epg.map((hit) => {
    return {
      name: hit.title,
      description: hit.description,
      day: getDay(hit.start),
      start: formatDateForEPG(hit.start),
      stop: formatDateForEPG(hit.stop),
      raw: {
        start: Math.round(Date.parse(hit.start) / 1000),
        stop: Math.round(Date.parse(hit.stop) / 1000),
      },
    }
  })
}

/**
 * Return {Object} with next fields:
 *            {currentProgram} {Object} - includes "name" and time "progress" the Program
 *            {programs} {Array} - includes next programs (all EPG) for current Channel
 * @param {object} state - state of store: object
 * @returns {object} - object.currentProgram & object.programs
 */
export function getEPGInfoHelper(state) {
  const epg = state.slice()

  if (epg.length > 0) {
    let currentProgram = getCurrentChannelEPG(epg.shift())

    while (!currentProgram) {
      currentProgram = getCurrentChannelEPG(epg.shift())
    }

    return {
      currentProgram,
      programs: mapProgramsToRenderFormat(epg),
    }
  }

  return {
    currentProgram: null,
    programs: [],
  }
}

/**
 * Return {Array} with Srings channels id for next EPG request:
 * @param {object} channels - array of objects; response channel list from server
 * @returns {Array} - array of channels id
 */
export function mapEPGIdToArray(channels) {
  return channels.map((channel) => channel.epgId)
}
