import {
  widevine as commonWidevine,
  playready as commonPlayready,
  fairplay as commonFairplay,
} from './common'

const warn =
  (modifier) =>
  <T>(options: T): T => {
    console.warn('DRM provider is not supported, it might not work')
    return modifier(options)
  }

// use common settings for unsupported provider
// + print warning
export const widevine = warn(commonWidevine)
export const playready = warn(commonPlayready)
export const fairplay = warn(commonFairplay)
