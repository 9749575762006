import axios from 'axios'
import { persist } from 'effector-storage/local/fp'
import { domain, combine, forward, TAKE_FIRST, TAKE_LAST } from '.'
// import { $profileId } from './account'

// create child domain
const { createEvent, createStore, createEffect, createReEffect } =
  domain('profiles')

// *
// * events
// *

export const getProfiles = createEvent()
export const getActiveProfile = createEvent()

export const addProfile = createEvent<{ id: number; name: string }>()
export const updateProfile = createEvent<{ id: number; name: string }>()
export const deleteProfile = createEvent<{ id: number }>()

export const chooseProfile = createEvent<{ id: number }>()

export const setEditedProfile = createEvent<{ id: number }>()
export const clearEditedProfile = createEvent()
export const setAction = createEvent<string>()

// *
// * effects
// *

export const getProfilesFx = createReEffect({
  strategy: TAKE_LAST,
  handler: (_, onCancel) => {
    const source = axios.CancelToken.source()
    onCancel(() => source.cancel())
    return axios.get('/api/v3/profiles', {
      cancelToken: source.token,
    })
  },
})

export const createProfileFx = createReEffect<{ name?: string }, any>({
  strategy: TAKE_FIRST,
  handler: ({ name = 'Default' } = {}) =>
    axios.post('/api/v3/profiles', { name }),
})

export const getProfileFx = createReEffect<{ id: number }, any>({
  strategy: TAKE_LAST,
  handler: ({ id }, onCancel) => {
    const source = axios.CancelToken.source()
    onCancel(() => source.cancel())
    return axios.get(`/api/v3/profiles/${id}`, {
      cancelToken: source.token,
    })
  },
})

export const updateProfileFx = createReEffect<
  { id: number; name: string },
  any
>({
  strategy: TAKE_FIRST,
  handler: ({ id, name }) => axios.put(`/api/v3/profiles/${id}`, { name }),
})

export const deleteProfileFx = createReEffect<{ id: number }, any>({
  strategy: TAKE_FIRST,
  handler: ({ id }) => axios.delete(`/api/v3/profiles/${id}`),
})

export const getActiveProfileFx = createReEffect({
  strategy: TAKE_LAST,
  handler: (_, onCancel) => {
    const source = axios.CancelToken.source()
    onCancel(() => source.cancel())
    return axios.get('/api/v3/profiles/active', {
      cancelToken: source.token,
    })
  },
})

export const setActiveProfileFx = createReEffect({
  strategy: TAKE_FIRST,
  handler: ({ id }) => axios.patch(`/api/v3/profiles/${id}/active`),
})

export const createDefaultProfileFx = createEffect({
  handler: () =>
    createProfileFx()
      .then(({ data }) => chooseProfile({ id: data.payload.id }))
      .catch(console.error),
})

// *
// * stores
// *

export const $profiles = createStore<{ id: number; name: string }[]>([])
  .on(getProfilesFx.doneData, (_, { data }) => data.payload)
  .on(addProfile, (profiles, profile) => profiles.concat(profile))
  .on(updateProfile, (profiles, profile) => {
    const existing = profiles.find((x) => x.id === profile.id)
    return existing === undefined
      ? profiles.concat(profile) // if there were no such item - add it
      : existing.name !== profile.name
      ? profiles.map((x) => (x.id === profile.id ? profile : x)) // on new name - update it
      : undefined // otherwise do nothing
  })
  .on(deleteProfile, (profiles, { id }) => profiles.filter((x) => x.id !== id))

export const $activeProfileId = createStore<number | null>(null)
  .on(getActiveProfileFx.doneData, (_, { data }) => data.payload.id)
  .on(setActiveProfileFx.done, (_, { params }) => params.id)
  .thru(persist({ key: 'profile' }))

export const $activeProfile = combine(
  $profiles,
  $activeProfileId,
  (profiles, id) => profiles.find((x) => x.id === id)
)

export const $editedProfileId = createStore<number | null>(null)
  .on(setEditedProfile, (_, { id }) => id)
  .reset(clearEditedProfile)

export const $editedProfile = combine(
  $profiles,
  $editedProfileId,
  (profiles, id) => profiles.find((x) => x.id === id) || { id: null, name: '' }
)

export const $action = createStore('')
  .on(setAction, (_, action) => action)
  .reset(clearEditedProfile)

// *
// * connections
// *

forward({ from: getProfiles, to: getProfilesFx })
forward({ from: getActiveProfile, to: getActiveProfileFx })

forward({
  from: createProfileFx.done.map(({ params, result }) => ({
    id: result.data.payload.id,
    name: params?.name ?? 'Default',
  })),
  to: addProfile,
})

forward({
  from: [
    getProfileFx.doneData.map(({ data }) => data.payload),
    getActiveProfileFx.doneData.map(({ data }) => data.payload),
    updateProfileFx.done.map(({ params }) => params),
  ],
  to: updateProfile,
})

forward({
  from: deleteProfileFx.done.map(({ params }) => params),
  to: deleteProfile,
})
