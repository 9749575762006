import React from 'react'
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import s from '/components/login/login.module.scss'

interface WarningProps {
  warning: string
}

export const WarningBlock = ({ warning }: WarningProps): JSX.Element => (
  <div className={`${s.messageBlock} ${s.messageBlock__warning}`}>
    <FontAwesomeIcon icon={faExclamationTriangle} />
    <span>{warning}</span>
  </div>
)
