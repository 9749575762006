import { categoriesPath, Route, subCategoriesPath } from '~/root/routes'
import { VodHomePage } from '/components/vod/pages/home'
import { VodDetailsPage } from '/components/vod/pages/details'
import { VodCategoryPage } from '/components/vod/pages/category'
import { _ } from '/config'

export const path = 'vods'

export const vodRoutes = (vodEnabled: boolean): Route[] => [
  {
    path: `/${path}`,
    name: _('navbar-header-movies'),
    component: VodHomePage,
    enable: vodEnabled,
    main: true,
  },
  {
    path: `/${path}/:vodId`,
    name: _('navbar-header-movies'),
    component: VodDetailsPage,
    enable: vodEnabled,
  },
  {
    path: `/${path}/${categoriesPath}`,
    name: _('navbar-header-movies'),
    component: VodHomePage,
    enable: vodEnabled,
  },
  {
    path: `/${path}/${categoriesPath}/:categoryId`,
    name: _('navbar-header-movies'),
    component: VodCategoryPage,
    enable: vodEnabled,
  },
  {
    path: `/${path}/${categoriesPath}/:categoryId/:vodId`,
    name: _('navbar-header-movies'),
    component: VodDetailsPage,
    enable: vodEnabled,
  },
  {
    path: `/${path}/${categoriesPath}/:categoryId/${subCategoriesPath}`,
    name: _('navbar-header-movies'),
    component: VodCategoryPage,
    enable: vodEnabled,
  },
  {
    path: `/${path}/${categoriesPath}/:categoryId/${subCategoriesPath}/:subCategoryId`,
    name: _('navbar-header-movies'),
    component: VodCategoryPage,
    enable: vodEnabled,
  },
  {
    path: `/${path}/${categoriesPath}/:categoryId/${subCategoriesPath}/:subCategoryId/:vodId`,
    name: _('navbar-header-movies'),
    component: VodDetailsPage,
    enable: vodEnabled,
  },
]
