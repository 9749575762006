import React from 'react'
import { connect } from 'react-redux'
import { setModal } from '/components/common/modal/model'
import { useTranslation } from 'react-i18next'
import { Carousel } from '../common/carousel/Carousel'
import { FeaturedCard } from '../common/cards/featured-card/FeaturedCard'
import { actions as vodActions } from './store'
import { Movie } from './interfaces'
import { getHorizontalVODLogo, getVODLogo } from './helpers'
import { HorizontalCard } from '../common/cards/horizontal-card/HorizontalCard'
import { CardTypes } from '/utils/helpers'
import { useNavigate } from '@reach/router'
import { GEOBLOCK_MESSAGE } from '/utils/constants'

interface TvCarouselProps {
  id: string
  title: string
  cardType: number
  carousel: { content: any[] }
  handleMovieClick?: (item: Movie) => void
}

const VodsCarousel = ({
  carousel,
  title = '',
  id,
  handleMovieClick = null,
  cardType,
  setIsVodFavorite,
}: TvCarouselProps) => {
  const maxLength = 36
  const navigate = useNavigate()
  const { t } = useTranslation()

  const handleClick = async (item) => {
    if (item?.blockedByAcl) {
      setModal({
        text: t(`common:message.${GEOBLOCK_MESSAGE}`, {
          item: `"${item.name}"`,
        }),
      })
      return
    }

    if (typeof handleMovieClick === 'function' && item) {
      handleMovieClick(item)
    }

    await navigate(`/vods/categories/0/${item?.id}`)
  }

  return carousel?.content?.length ? (
    <Carousel
      id={id}
      carouselTitle={title}
      showAllOption={false}
      className='featured'
    >
      {carousel.content
        .slice(0, maxLength)
        .map((item) =>
          cardType === CardTypes.featuredCard ? (
            <FeaturedCard
              favorite={item.vod.favorite}
              handleFavoriteClick={() => setIsVodFavorite(item.vod)}
              key={`${item.name}${item.id}`}
              handleClick={() => handleClick(item.vod)}
              imageUrl={
                getHorizontalVODLogo(item.vod.imageHorizontalUrl) ||
                getVODLogo(item.vod.imageUrl)
              }
              title={item.vod.name}
              ageRatings={item.vod.ageRatings}
              releaseDate={item.vod.year}
            />
          ) : (
            <HorizontalCard
              favorite={item.vod.favorite}
              handleFavoriteClick={() => setIsVodFavorite(item.vod)}
              key={`${item.name}${item.id}`}
              handleClick={() => handleClick(item.vod)}
              imageUrl={
                getHorizontalVODLogo(item.vod.imageHorizontalUrl) ||
                getVODLogo(item.vod.imageUrl)
              }
              title={item.vod.name}
            />
          )
        )}
    </Carousel>
  ) : null
}

function mapStateToProps(state) {
  return {
    carousel: state.views.tv.carousels?.find((item) => item.type === 'VOD'),
  }
}
const mapDispatchToProps = {
  setIsVodFavorite: vodActions.setIsVodFavorite,
}

export const VodsCarouselContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(VodsCarousel)
