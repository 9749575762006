// Safari prior to version 15 doesn't support top level await
// import './wdyr'

import React from 'react'
import { render } from 'react-dom'

import AppContainer from './root/App'

import { configureAxios } from './services/api'
import { init as initModels } from '/models/app'

// add mimic for development mode
// enable `mimic` only if VITE_MIMIC variable passed (with any value)
if (process.env.NODE_ENV !== 'production' && import.meta.env.VITE_MIMIC) {
  import('mimic')
}

/**
 * Prepare application, check session
 */
function preflight() {
  try {
    // check, if we have access to `localStorage`
    typeof localStorage /* eslint-disable-line no-unused-expressions */
  } catch (e) {
    return Promise.reject(new Error('no localStorage access'))
  }

  configureAxios()
  initModels()

  // TODO: get rid of promise?
  return Promise.resolve()
}

/**
 * Render App
 */
function main() {
  render(<AppContainer />, document.querySelector('#app'))
}

// init and render
preflight().then(main, main)
