import { createSelector } from 'reselect'
import { getView } from '~/root/rootSelectors'

const getHomeView = createSelector(getView, (view) => view.home)

const getBanners = createSelector(getHomeView, (home) => home.banners)

export const getBanner = createSelector([getBanners], (banners) => {
  return banners[0]
})

/*  TV  selectors  */

const getTvView = createSelector(getView, (state) => state.tv)

export const isTVContent = createSelector(getTvView, (state) => {
  return state && state.recentlyWatched && !!state.recentlyWatched.length
})

/*  CATCHUPS  selectors  */

const getCatchupsView = createSelector(getView, (state) => state.catchup)

export const isCatchupsContent = createSelector(getCatchupsView, (state) => {
  return state && state.recentlyWatched && !!state.recentlyWatched.length
})

/*  VODS  selectors */

const getVodView = createSelector(getView, (state) => state.vod)
const getVodContentAll = createSelector(
  getVodView,
  (state) => state && state.content && state.content[0]
)

export const isVodsContent = createSelector([getVodContentAll], (state) => {
  return state && state.movies && !!state.movies.length
})

/*  TVShows  selectors  */

const getTVShowsView = createSelector(getView, (state) => state.tvShows)

export const isTvShowsContent = createSelector(
  getTVShowsView,
  (state) =>
    state &&
    state.content &&
    state.content.tvShows &&
    !!state.content.tvShows.length
)

export const isAnyContent = createSelector(
  [isTVContent, isVodsContent, isTvShowsContent, isCatchupsContent],
  (tvContent, vodsContent, tvShowsContent, catchupsContent) => {
    return tvContent || vodsContent || tvShowsContent || catchupsContent
  }
)
