import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import createStatsCollector from '../services/stats'
import { appReducer } from './rootReducer'

import { actions as vodActions } from '../components/vod/store'
import { actions as tvActions } from '../components/tv/store'
import { actions as homeActions } from '../components/home/store'
import { actions as catchupActions } from '../components/catchup/store'
import { getCategoriesRequest } from '../components/tvShows/tvShowsActions'
import { getAllChannels } from '../components/epg/epgActions'

const initialStateCategoryID = 0

const storeData = {
  views: {
    radio: {
      search: '',
      status: null,
      activeChannel: {},
      activeCategory: 0,

      categories: [],
      channels: [],
    },
    epg: {
      search: '',
      channels: [],
    },
  },
}

const stats = createStatsCollector()

const history = (store) => (next) => (action) => {
  if (store.history) {
    if (store.history.length === 2) {
      store.history.push(store.getState())
      store.history.shift()
    } else {
      store.history.push(store.getState())
    }
  } else {
    initHistory()
  }

  next(action)
}

function initHistory() {
  store.history = []
  store.history.push(store.getState())
}

const middleware = getDefaultMiddleware({
  immutableCheck: false,
  serializableCheck: false,
  thunk: true,
}).concat([history, stats])

export const store = configureStore({
  reducer: appReducer,
  preloadedState: storeData,
  middleware,
  devTools: process.env.NODE_ENV !== 'production',
})

export function setInitialState() {
  store.dispatch(homeActions.getBannerCarousel())
  store.dispatch(tvActions.getChannelsCategories())
  store.dispatch(vodActions.getCategories({ page: 0 }))
  store.dispatch(getCategoriesRequest())
  // store.dispatch(getRadioCategories(initialStateCategoryID))
  store.dispatch(catchupActions.getCatchupsList())
  store.dispatch(getAllChannels())
  store.dispatch(tvActions.changeChannelsCategory(initialStateCategoryID))
  // store.dispatch(changeRadioCategory(initialStateCategoryID))
  store.dispatch(tvActions.getCarousels())
}
