import axios from 'axios'
import { tv as api, ensureOK } from '.'
import { isServerUnableProcess, repeatRequest } from '../'
import { assignAllCategories } from '/utils/helpers'

/**
 * Find channel url
 * @param channelId
 * @return {Promise<T>}
 */
export const fetchChannelUrl = (channelId: number): Promise<any> => {
  return axios
    .get(`${api}/${channelId}/url`)
    .then(ensureOK)
    .then((response) => response.data)
    .catch((error) => {
      const { status } = error.response
      if (isServerUnableProcess(status)) {
        return repeatRequest(() => fetchChannelUrl(channelId))
      } else {
        throw error
      }
    })
}

/**
 * Find channels by categoryId, ids
 * @param categoryId
 * @param ids
 * @returns {Promise<T>}
 */
export const fetchChannelsByCategory = (
  categoryId = 0,
  ids?: number[]
): Promise<any> => {
  let url = `${api}?categoryId=${categoryId}`
  if (ids) url += `&ids=${ids}`

  return axios
    .get(url)
    .then((response) => response.data.payload)
    .catch((error) => {
      const { status } = error.response
      if (isServerUnableProcess(status)) {
        return repeatRequest(() => fetchChannelsByCategory(categoryId, ids))
      } else {
        throw error
      }
    })
}

/**
 * Send url
 * @param url
 * @returns {Promise<T>}
 */
export const pingLiveRewindSupportUrl = (url: string) => {
  return axios.get(url)
}

/**
 * Get all channels categories
 * @returns {Promise<T>}
 */
export const fetchCategories = (): Promise<any> => {
  return axios
    .get(`${api}/categories`)
    .then((response) => assignAllCategories(0, { data: response.data.payload }))
    .catch((error) => {
      const { status } = error.response
      if (isServerUnableProcess(status)) {
        return repeatRequest(() => fetchCategories())
      } else {
        throw error
      }
    })
}
