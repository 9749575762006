import React, { useState } from 'react'

export interface CarouselInfo {
  id: string | number
  position: number
}

export type CarouselContextType = {
  carouselPositions: CarouselInfo[] | []
  updateCarousel: (carousel: CarouselInfo) => void
  getCarouselActivePosition: (id) => number
  addCarousel: (id) => void
}

export const CarouselsContext = React.createContext({})

export const CarouselsProvider = ({ children }) => {
  const [carouselPositions, setCarouselPositions] = useState<CarouselInfo[]>([])

  const addCarousel = (id) => {
    if (carouselPositions.find((c) => c.id === id)) {
      return
    }
    setCarouselPositions([...carouselPositions, { id: id, position: 0 }])
  }
  const updateCarousel = (c) => {
    carouselPositions.find((carousel) => {
      if (carousel.id === c.id) carousel.position = c.position
    })
    setCarouselPositions([...carouselPositions])
  }
  const getCarouselActivePosition = (id: string) => {
    if (carouselPositions.find((c) => c.id === id))
      return carouselPositions.find((c) => c.id === id)?.position
  }

  return (
    <CarouselsContext.Provider
      value={{
        carouselPositions,
        updateCarousel,
        getCarouselActivePosition,
        addCarousel,
      }}
    >
      {children}
    </CarouselsContext.Provider>
  )
}
