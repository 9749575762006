import React from 'react'
import { setForm } from '/models/registration'
import { Button } from '/components/common/button'
import { Logo } from '/components/login/components/forms/FormLogo'
import { FORM_TYPE } from '/utils/constants'

import s from '../../login.module.scss'

export const Confirmation = (): JSX.Element => {
  return (
    <div className={`${s.loginWrap} ${s.confirmation}`}>
      <div className={s.loginBox}>
        <Logo />
        <h1 className={s.loginSubTitle}>Thank you for account creation!</h1>
        <p className={`${s.message} ${s.messageAccent}`}>
          You have verified your account successfully. Please sign in to the
          application using your email and password.
        </p>
        <Button
          type='button'
          title='Sign In'
          className={`${s.button} button-primary button-small`}
          handleClick={() => setForm(FORM_TYPE.SIGN_IN)}
        />
      </div>
    </div>
  )
}
