import React from 'react'
import { useTranslation } from 'react-i18next'
import { withPinLock, HOCProps } from '/components/common/player/PinLockHOC'
import './PinLock.scss'

interface PinLockProps extends HOCProps {
  pinWrapperRef: () => void
  pin: string
  securedPin: string
  title?: string
  error: boolean
  onInputHandler: () => void
  onMouseDown: () => boolean
  onPressButtonHandler: () => boolean
}

const PinLock = ({
  pinWrapperRef,
  pin,
  securedPin,
  title,
  error,
  onInputHandler,
  onMouseDown,
  onPressButtonHandler,
}: PinLockProps): JSX.Element => {
  const { t } = useTranslation('tv')

  return (
    <div id='pin'>
      <div className='pin-content'>
        <h5 className='pin-title'>{t('Locked channel')}</h5>
        <h6 className='pin-subtitle'>{title}</h6>
        <div
          ref={pinWrapperRef}
          className='pin-inputs-wrapper'
          onKeyDown={onPressButtonHandler}
        >
          {[0, 1, 2, 3].map((item, index) => {
            return (
              <div key={index} className={`pin-input`}>
                <input
                  type='text'
                  inputMode='numeric'
                  pattern='[0-9]*'
                  maxLength={1}
                  value={securedPin[index] || pin[index] || ''}
                  onInput={onInputHandler}
                  onMouseDown={onMouseDown}
                />
                <span className='pin-input-underline' />
              </div>
            )
          })}
        </div>
        <p className={`pin-message ${error ? 'error' : ''}`}>
          {error
            ? t('Invalid PIN. Please try again')
            : t('Please enter PIN code to watch the content')}
        </p>
      </div>
    </div>
  )
}

export const PinLockContainer = withPinLock(PinLock, 'tv')
