import React, { useState, useEffect, useRef } from 'react'
import { useLocation } from '@reach/router'
import Slider from 'react-slick'
import { ResourceType } from '/components/home/constants'
import { setURLParams } from '/utils/helpers'

import './slick.css'
import './slick-theme.css'

import './Slider.scss'

const Arrow = ({ className, direction, onClick }) => {
  return (
    <svg
      height='52'
      width='52'
      id={`${direction}Arrow`}
      className={className}
      onClick={onClick}
    >
      <g id='arrowHoverEffect' viewBox='0 0 52 52'>
        <circle r='26' cx='26' cy='26' fill='transparent'>
          <animate
            attributeType='XML'
            attributeName='fill'
            from='rgba(255,255,255,0.35)'
            to='rgba(255,255,255,0.35)'
            repeatCount='1'
            begin='click'
            dur='0.5s'
            restart='always'
          />
        </circle>
      </g>
      <g transform='translate(10 10)'>
        {direction === 'forward' ? (
          <use href='#arrowNext' height='32' width='32' />
        ) : (
          <use href='#arrowPrev' height='32' width='32' />
        )}
      </g>
      <defs>
        <symbol id='arrowNext' viewBox='0 0 24 24'>
          <path
            d='M12.2507 2.16949L11.402 3.02825C11.1898 3.25424 11.1898 3.61582 11.402 3.79661L17.9793 10.8023H3.50921C3.21217 10.8023 3 11.0734 3 11.3446V12.6102C3 12.9266 3.21217 13.1525 3.50921 13.1525H17.9793L11.402 20.2034C11.1898 20.3842 11.1898 20.7458 11.402 20.9718L12.2507 21.8305C12.4204 22.0565 12.7599 22.0565 12.9721 21.8305L21.8409 12.3842C22.053 12.1582 22.053 11.8418 21.8409 11.6158L12.9721 2.16949C12.7599 1.9435 12.4204 1.9435 12.2507 2.16949Z'
            fill='currentColor'
          />
        </symbol>
        <symbol id='arrowPrev' viewBox='0 0 24 24'>
          <path
            d='M12.7276 21.8305L13.5744 20.9718C13.7861 20.7458 13.7861 20.3842 13.5744 20.2034L7.0117 13.1525H21.4919C21.746 13.1525 22 12.9266 22 12.6102V11.3446C22 11.0734 21.746 10.8023 21.4919 10.8023H7.0117L13.5744 3.79661C13.7861 3.61582 13.7861 3.25424 13.5744 3.02825L12.7276 2.16949C12.5582 1.9435 12.2195 1.9435 12.0078 2.16949L3.15877 11.6158C2.94708 11.8418 2.94708 12.1582 3.15877 12.3842L12.0078 21.8305C12.2195 22.0565 12.5582 22.0565 12.7276 21.8305Z'
            fill='currentColor'
          />
        </symbol>
      </defs>
    </svg>
  )
}

export const SliderCarousel = ({
  carousel,
  carousel: { bannerBundles = [] },
  handleClick,
}) => {
  const ref = useRef(null)
  const location = useLocation()
  const paramName = 'banner'
  const [swiping, setSwiping] = useState(false)

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const id = params.get(paramName)
    if (id != null) ref.current.slickGoTo(id)
  }, [])

  const handleMouseDown = (event) => {
    event.preventDefault()
  }

  const handleMouseUp = () => {
    setSwiping(ref.current.innerSlider.state.swiping)
  }

  const handleOnClick = (event, item, index) => {
    if (swiping) {
      return event.preventDefault()
    }
    handleClick(item, index)
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: carousel.autoScrollEnabled,
    autoplaySpeed: carousel.autoScrollIn * 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <Arrow direction='forward' сlassName='slick-arrow' />,
    prevArrow: <Arrow direction='back' сlassName='slick-arrow' />,
    afterChange: (current) => setURLParams(location, paramName, current),
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
          arrows: false,
          variableWidth: false,
        },
      },
    ],
  }

  return (
    <div className='slick-container'>
      <Slider ref={ref} {...settings}>
        {bannerBundles && bannerBundles.length > 0
          ? bannerBundles.map((item, index) => {
              const {
                content,
                resourceType,
                link,
                mobileBannerUrl,
                tabletBannerUrl,
                tvBannerUrl,
              } = item
              const isExternalLink =
                resourceType === ResourceType.EXTERNAL && link

              return isExternalLink ? (
                <a
                  key={index}
                  className='slide-content'
                  href={link}
                  target='_blank'
                  onMouseUpCapture={handleMouseUp}
                  onMouseDownCapture={handleMouseDown}
                >
                  <picture className='slide-content__picture'>
                    <source
                      media='(max-width: 600px)'
                      srcSet={mobileBannerUrl}
                    />
                    <source
                      media='(max-width: 1100px)'
                      srcSet={tabletBannerUrl}
                    />
                    <img
                      className='slide-content__img'
                      src={tvBannerUrl}
                      alt={content?.name || 'Slider Image'}
                    />
                  </picture>
                </a>
              ) : (
                <div
                  key={index}
                  className='slide-content'
                  onClickCapture={(event) => handleOnClick(event, item, index)}
                  onMouseUpCapture={handleMouseUp}
                  onMouseDownCapture={handleMouseDown}
                >
                  <picture className='slide-content__picture'>
                    <source
                      media='(max-width: 600px)'
                      srcSet={mobileBannerUrl}
                    />
                    <source
                      media='(max-width: 1100px)'
                      srcSet={tabletBannerUrl}
                    />
                    <img
                      className='slide-content__img'
                      src={tvBannerUrl}
                      alt={content?.name || 'Slider Image'}
                    />
                  </picture>
                </div>
              )
            })
          : null}
      </Slider>
    </div>
  )
}
