import React from 'react'
import { connect } from 'react-redux'
// import * as vodActionCreators from './vodActions'
import { actions as vodActions } from './store'
import { useTranslation } from 'react-i18next'
import { SearchField } from '../common/search-field'
import { _ } from '/config'

const VodsSearchBar = ({ activeCategory, getVODBySearch, callback }) => {
  const { t } = useTranslation('vod')
  return (
    <SearchField
      placeholder={t(_('search-movie-placeholder'))}
      handleSearch={(search) => {
        getVODBySearch(search)
        if (typeof callback === 'function') callback(search.q)
      }}
      activeCategory={activeCategory}
    />
  )
}

function mapStateToProps(state) {
  return {
    activeCategory: state.views.vod.activeCategory,
  }
}

const mapDispatchToProps = {
  getVODBySearch: vodActions.getVodsBySearch,
}

export const VodsSearchBarContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(VodsSearchBar)
