import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useTooltip } from '../tooltip/context'
import { convertUnixTimeToDate } from '~/utils/date'
import { TvShowEpisode } from '../../interfaces'
import './styles.scss'

interface TvShowEpisodeProps {
  episode: TvShowEpisode
  handleClick: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    episode: TvShowEpisode
  ) => Promise<void> | undefined
  isActive?: boolean
}

export const Episode = ({
  isActive,
  handleClick,
  episode,
}: TvShowEpisodeProps): JSX.Element => {
  const {
    description,
    directors,
    displayNumber,
    stars,
    length,
    name,
    releaseTime,
    resolution,
  } = episode

  const { t } = useTranslation('tvshow')
  const convertedReleaseTime = convertUnixTimeToDate(releaseTime)
  const { openTooltip, tooltip, closeTooltip } = useTooltip()
  const handleTooltipClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => openTooltip(e, episode)

  return (
    <div className={`episode ${isActive ? 'active' : ''}`}>
      <div
        className='episode__info'
        onClick={(e) => (!tooltip ? handleClick(e, episode) : closeTooltip)}
      >
        <span className='episode__title'>{`${displayNumber}. ${name}`}</span>
        {length !== '00:00' ? (
          <span>{t('Length {{length}}', { length })}&nbsp;</span>
        ) : null}
        <span>
          {resolution?.substring(resolution.length, resolution.length - 2)}
          &nbsp;
        </span>
        <span>{t('Release {{year}}', { year: convertedReleaseTime })}</span>
      </div>
      {/* <div className='tooltip-wrapper'> */}
      {(directors || stars || description) && (
        <div
          className='tooltip-toggler tooltip-wrapper'
          onClick={handleTooltipClick}
        >
          <FontAwesomeIcon icon={faExclamationCircle} />
        </div>
      )}
    </div>
  )
}
