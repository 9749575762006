import { replaceSizeHelper } from '/utils/helpers'

/**
 * @param {string} src - url for content resources
 * @returns {function} return decorate function
 */
export function getVODLogo(src?: string) {
  return replaceSizeHelper(src, '137x193')
}

/**
 * @param {string} src - url for content resources
 * @returns {function} return decorate function
 */
export function getHorizontalVODLogo(src?: string) {
  return replaceSizeHelper(src, '800x542')
}
