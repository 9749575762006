import axios from 'axios'
import { domain, combine, TAKE_FIRST } from '.'

// create child domain
const { createStore, createReEffect } = domain('carousels')

// *
// * effects
// *

export const getBannersFx = createReEffect({
  strategy: TAKE_FIRST,
  handler: () =>
    axios
      .get('/api/v3/carousels/banners')
      .then((response) =>
        response.data.payload.length > 0 ? response.data.payload : null
      )
      .catch(() => null),
})

export const getCarouselsFx = createReEffect({
  strategy: TAKE_FIRST,
  handler: () =>
    axios
      .get('/api/v3/carousels/featured')
      .then((response) => response.data.payload)
      .catch(() => []),
})

// *
// * store
// *

export const $banners = createStore<any>({}).on(
  getBannersFx.doneData,
  (_, data) => data
)

export const $carousels = createStore<any[]>([]).on(
  getCarouselsFx.doneData,
  (_, data) => data
)

export const $isCarouselsEnabled = combine(
  $banners,
  $carousels,
  (banners, carousels) =>
    Boolean(banners) || (carousels && carousels.length > 0)
)
