import React from 'react'
import { connect } from 'react-redux'
import { actions as catchupActions } from './store'
import { useTranslation } from 'react-i18next'
import SearchField from '../common/SearchField'

const CatchupSearchBar = ({ setSearchQuery, search }) => {
  const { t } = useTranslation('catchup')

  return (
    <SearchField
      placeholder={t('Search channel')}
      handleSearch={setSearchQuery}
      search={search}
    />
  )
}

function mapStateToProps(state) {
  return {
    search: state.views.catchup.search,
  }
}

const mapDispatchToProps = {
  setSearchQuery: catchupActions.setSearchQuery,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export const CatchupSearchBarContainer = connector(CatchupSearchBar)
