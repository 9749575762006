import React, { useEffect, useCallback } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { HLocation, navigate } from '@reach/router'
import { CatchupSearchBarContainer } from '../CatchupSearchBar'
import { actions as catchupActions } from '../store'
import { getActiveChannel, getChannels, getDays } from '../store/selectors'
import { useTranslation } from 'react-i18next'
import { Catchup, CatchupProgram } from '../interfaces/index'
import { Channel } from '~/components/common/channel'
import { Day } from '../components/day'
import { NoContent } from '~/components/common/no-content'
import { PinLockModalContainer } from '../components/CathcupPinLockModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faClone,
  faLock,
  faSearch,
  faVideoSlash,
} from '@fortawesome/pro-regular-svg-icons'
import { Program } from '../components/program'
import { isEmpty } from '/utils/helpers'
import { usePrevious, useModal } from '/utils/hooks'
import { GEOBLOCK_MESSAGE } from '/utils/constants'

import './styles.scss'

interface CatchupHomePageProps extends ConnectedProps<typeof connector> {
  location: HLocation<{ shouldStateReset: boolean }>
}

export const CatchupHome = ({
  activeChannel,
  catchups,
  searchQ,
  selectChannel,
  days,
  activeDay,
  setActiveDay,
  setActiveProgram,
}: CatchupHomePageProps): JSX.Element => {
  const { t } = useTranslation()
  const daysList = Object.keys(days)
  const { isModalOpened, openModal, closeModal } = useModal()

  const catchupsList =
    searchQ && catchups?.length
      ? catchups.filter((catchup) => {
          const channelSearchSrc = `${
            catchup.sortOrder
          } ${catchup.channelName.toLowerCase()}`
          return channelSearchSrc.includes(searchQ.toLowerCase())
        })
      : catchups

  const prevSearchQ: string = usePrevious(searchQ)

  useEffect(() => {
    if (
      catchupsList?.length &&
      (isEmpty(activeChannel) || searchQ || prevSearchQ)
    ) {
      selectChannel(catchupsList[0])
    }
  }, [searchQ, catchupsList?.length])

  const handleDayClick = (day: string) => {
    setActiveDay(day)
  }

  const handleChannelClick = (catchup: Catchup) => {
    selectChannel(catchup).then(() => {
      if (catchup.locked && !isModalOpened) {
        openModal()
      }
    })
  }

  const handleProgram = useCallback(
    (program: CatchupProgram) => {
      setActiveProgram(program)
      navigate(
        `/catchup/${activeChannel.channelId}/${activeChannel.id}/${program.id}`
      )
    },
    [days, activeDay]
  )

  useEffect(() => {
    if (!activeChannel?.locked && isModalOpened) {
      closeModal()
    }
  }, [activeChannel?.locked])

  return (
    <div className='catchup-page'>
      <div className='page-search-breadcrumbs-wrapper'>
        <CatchupSearchBarContainer />
      </div>
      {!catchupsList.length ? (
        <NoContent
          icon={<FontAwesomeIcon icon={searchQ ? faSearch : faVideoSlash} />}
          title={searchQ ? t('No search results found') : t('No content')}
        />
      ) : (
        <div className='catchup-page__content'>
          <div className='channels catchup-page__column'>
            <h2 className='list__title'>{t('Channels')}</h2>
            <div className='channels__list'>
              {catchupsList.map((item: Catchup) => {
                const isActive = item?.id === activeChannel?.id
                const catchup = isActive ? activeChannel : item
                const cardInfo = {
                  id: catchup.id,
                  name: catchup.channelName,
                  channelNumber: catchup.sortOrder,
                  locked: catchup.locked,
                  logoUrl: catchup.logoUrl,
                }

                return (
                  <Channel
                    key={item.id}
                    handleClick={() => handleChannelClick(catchup)}
                    isActive={isActive}
                    channel={cardInfo}
                  />
                )
              })}
            </div>
          </div>
          {catchupsList.find((catchup) => catchup.id === activeChannel?.id) &&
          activeChannel?.blockedByAcl ? (
            <NoContent
              title={t(`common:message.${GEOBLOCK_MESSAGE}`, {
                item: `"${activeChannel.channelName}"`,
              })}
              className={'noDays'}
            />
          ) : activeChannel?.locked ? (
            <>
              <NoContent
                icon={<FontAwesomeIcon icon={faLock} />}
                title={t(
                  'catchup:Channel is Locked. Please enter PIN Code to watch Catchup List'
                )}
                className={'noDays'}
              />
              {isModalOpened && (
                <PinLockModalContainer
                  autoFocus={true}
                  title={`${activeChannel.sortOrder}. ${activeChannel.channelName}`}
                  closeModal={closeModal}
                />
              )}
            </>
          ) : daysList.length ? (
            <>
              <div className='days catchup-page__column'>
                <h2 className='list__title'>{t('catchup:Days')}</h2>
                <div className='days__list'>
                  {daysList.map((day) => (
                    <Day
                      key={day}
                      isActive={day === activeDay}
                      handleClick={() => handleDayClick(day)}
                      day={day}
                    />
                  ))}
                </div>
              </div>
              <div className='programs catchup-page__column'>
                <h2 className='list__title'>{t('catchup:Programs')}</h2>
                <div className='programs__list '>
                  {days[activeDay]?.length ? (
                    days[activeDay].map((program: CatchupProgram) => (
                      <Program
                        key={`${program.name}_${program.startSec}`}
                        program={program}
                        handleProgram={handleProgram}
                      />
                    ))
                  ) : (
                    <span>{'No Programs are available'}</span>
                  )}
                </div>
              </div>
            </>
          ) : (
            <NoContent
              icon={<FontAwesomeIcon icon={faClone} />}
              title={'No Programs are available'}
              className='noDays'
            />
          )}
        </div>
      )}
    </div>
  )
}

function mapStateToProps(state) {
  return {
    activeChannel: getActiveChannel(state),
    catchups: getChannels(state),
    days: getDays(state),
    searchQ: state.views.catchup.search,
    activeDay: state.views.catchup.activeDay,
    activeProgram: state.views.catchup.activeProgram,
    programs: state.views.catchup.programs,
  }
}

const mapDispatchToProps = {
  selectChannel: catchupActions.changeCatchupChannel,
  setPrograms: catchupActions.setPrograms,
  setActiveDay: catchupActions.setActiveDay,
  setActiveProgram: catchupActions.setActiveProgram,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export const CatchupHomePage = connector(CatchupHome)
