import {
  CHANGE_RADIO_SEARCH_FIELD,
  GET_RADIO_CATEGORIES_REQUEST,
  GET_RADIO_CATEGORIES_SUCCESS,
  GET_RADIO_CATEGORIES_ERROR,
  GET_RADIO_CATEGORY_REQUEST,
  GET_RADIO_CATEGORY_SUCCESS,
  GET_RADIO_CATEGORY_ERROR,
  GET_RADIO_REQUEST,
  GET_RADIO_SUCCESS,
  GET_RADIO_ERROR,
  SET_RADIO_STATUS,
} from '../../root/actionTypes'

export default function (state = {}, action) {
  switch (action.type) {
    case GET_RADIO_CATEGORIES_REQUEST:
      return state
    case GET_RADIO_CATEGORIES_SUCCESS:
    case GET_RADIO_CATEGORIES_ERROR:
      return { ...state, categories: action.categories }

    case GET_RADIO_CATEGORY_REQUEST:
      return { ...state, activeCategory: action.category }

    case GET_RADIO_CATEGORY_SUCCESS:
    case GET_RADIO_CATEGORY_ERROR:
      return { ...state, channels: action.channels }

    case GET_RADIO_REQUEST:
      return { ...state, activeChannel: action.channel }

    case GET_RADIO_SUCCESS:
    case GET_RADIO_ERROR:
      return action.payload.channelUrl
        ? {
            ...state,
            activeChannel: {
              ...state.activeChannel,
              ...action.payload,
            },
          }
        : state

    case SET_RADIO_STATUS:
      return { ...state, status: action.status }

    case CHANGE_RADIO_SEARCH_FIELD:
      return { ...state, search: action.value }

    default:
      return state
  }
}
