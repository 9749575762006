import React from 'react'

export const ChevronRightIcon = () => {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className='icon'
    >
      <path
        d='M8 3.16102L7.13043 3.97684C6.95652 4.19153 6.95652 4.53503 7.13043 4.70678L15 12.4785L7.13043 20.2932C6.95652 20.465 6.95652 20.8085 7.13043 21.0232L8 21.839C8.21739 22.0537 8.52174 22.0537 8.73913 21.839L17.8696 12.865C18.0435 12.6503 18.0435 12.3497 17.8696 12.135L8.73913 3.16102C8.52174 2.94633 8.21739 2.94633 8 3.16102Z'
        fill='currentColor'
      />
    </svg>
  )
}
