import * as service from '../../services/api/v3/channels'
import { sortByChannelNumber } from '/utils/helpers'
import {
  CHANGE_EPG_SEARCH_FIELD,
  GET_ALL_CHANNELS_REQUEST,
  GET_ALL_CHANNELS_SUCCESS,
  GET_ALL_CHANNELS_ERROR,
} from '../../root/actionTypes'
import { actions as tvActions } from '/components/tv/store'

// UI ACTIONS

export const inputValue = (value) => ({
  type: CHANGE_EPG_SEARCH_FIELD,
  value,
})

// ASYNC CHANNELS ACTIONS

export const epgChannelsRequest = () => ({
  type: GET_ALL_CHANNELS_REQUEST,
})

export const epgChannelsSuccess = (channels) => ({
  type: GET_ALL_CHANNELS_SUCCESS,
  channels,
})

export const epgChannelsError = () => ({
  type: GET_ALL_CHANNELS_ERROR,
  channels: [],
})

export const getAllChannels = () => (dispatch) => {
  dispatch(epgChannelsRequest())
  service
    .fetchChannelsByCategory(0)
    .then((payload) => {
      const channels = sortByChannelNumber(payload)
      dispatch(epgChannelsSuccess(channels))
      dispatch(tvActions.changeChannel(channels[0] || {}))
    })
    .catch((error) => dispatch(epgChannelsError(error)))
}
