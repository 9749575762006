import memoize from 'lodash/memoize'
import { condition } from 'patronum/condition'
import { createApi, domain, forward, guard, sample, attach } from '.'
import { registerServiceWorker } from './sw'
import { refreshSessionFx } from './session'
import { getFeaturesFx } from './registration'
import { $profileId, getAccountFx, setAccount, resetAccount } from './account'
import {
  $isAuthenticated,
  resetAuthenticated,
  loginFx,
  logoutFx,
  canDoLogin,
} from './login'
import { acceptToaFx, declineToaFx } from './toa'
import { getProfilesFx, createDefaultProfileFx } from './profiles'
import { getBannersFx, getCarouselsFx } from './carousels'
import { setInitialState } from '../root/configureStore'
import Storage from '/utils/storage'

// create child domain
const { createEvent, createEffect, createStore } = domain('app')

// *
// * events
// *

export const init = createEvent()
export const setView = createEvent<string>()
export const gotoView = memoize((name: string) => setView.prepend(() => name))

// *
// * stores
// *

// current view
export const $view = createStore<string | null>(null).on(
  setView,
  (_, name) => name
)

// ─────────────────────────────────────────────────────────
//
//   # login flow
//     | login page → (toa page) → (profiles page) → application
//
//   # refresh flow
//     | (toa page) → (profiles page) → application
//
// ─────────────────────────────────────────────────────────

const $flow = createStore<string | null>(null)
const flow = createApi($flow, {
  login: () => 'login', // flag, indicating that user goes through login form
  refresh: () => 'refresh', // flag, indicating that user refreshes page (or authenticated state remains)
})

// *
// * effects
// *

// prettier-ignore
const getProfilesWithDataDependingOnFlowFx = createEffect<string | null, any>((flow) =>
  flow === 'login'
    ? // in case of login flow, get profiles and data, because we already got account via auth request
      Promise.all([getProfilesFx(), getBannersFx(), getCarouselsFx()])
        .then(([response]) => response)
    : // in case of refresh flow there is no account, so we need to load it as well
      Promise.all([getProfilesFx(), getBannersFx(), getCarouselsFx(), getAccountFx()])
        .then(([response]) => response)
)

const getProfilesWithDataFx = attach({
  effect: getProfilesWithDataDependingOnFlowFx,
  source: $flow,
  mapParams: (_, flow) => flow,
})

// *
// * connections flows
// *

forward({
  from: init,
  to: registerServiceWorker,
})

//
// init
//

condition({
  source: init,
  if: $isAuthenticated,
  then: refreshSessionFx, // if user was authenticated before, try to refresh session
  else: flow.login, // if user is not authenticated, use login flow
})

// if session refresh succeded, use refresh flow
forward({
  from: refreshSessionFx.done,
  to: [flow.refresh, getProfilesWithDataFx],
})

// if session refresh failed
condition({
  source: refreshSessionFx.fail,
  if: ({ error }: any) => error?.response?.data?.errorCode === 'EC16',
  then: [flow.refresh, gotoView('toa')], // in case of EC16, use refresh flow and go→toa
  else: condition({
    if: $isAuthenticated,
    then: logoutFx,
    else: flow.login,
  }),
} as any)

// reset authentication flag in case of login flow and go→login
forward({
  from: flow.login,
  to: [resetAuthenticated, getFeaturesFx, gotoView('login')],
})

//
// login
//

// set account from succesfull login request
forward({
  from: loginFx.doneData.map((payload) => payload.data.payload),
  to: setAccount,
})

// check auth request `toa` field
condition({
  source: loginFx.doneData,
  if: ({ data }) => data.payload.toa,
  then: gotoView('toa'), // in case of `toa=true`, go→toa
  else: getProfilesWithDataFx, // otherwise, request profiles
})

// allow login only after change view
forward({
  from: setView,
  to: canDoLogin.yes,
})

//
// toa
//

// const getProfilesWithOrWithoutAccount = createEvent()

// get profiles (with or without account) on toa accept
forward({
  from: acceptToaFx.done,
  to: getProfilesWithDataFx,
})

// do instant logout on toa decline
forward({
  from: declineToaFx,
  to: logoutFx,
})

// reset flow to 'login' on each logout request
// this will automatically set view to 'login' as well
forward({
  from: logoutFx,
  to: [flow.login, resetAccount],
})

//
// profiles
//

// if there was error loading profiles or account, goto→login
forward({
  from: getProfilesWithDataFx.fail,
  to: [resetAuthenticated, gotoView('login')],
})

// `getProfilesFx.doneData` event, sampled with view and account stores
// triggered only when `view` is null (we don't want to trigger it on regular profiles requests)
const getProfilesDataSampled = guard({
  source: sample({
    source: [$view, $profileId],
    clock: getProfilesWithDataFx.doneData,
    fn: ([view, profileId], { data }) => ({ view, profileId, data }),
  }),
  filter: ({ view }) => view === null || view === 'login' || view === 'toa',
})

// if there are no profiles, create default profile
// if there is no active profile selected, create default profile
condition({
  source: getProfilesDataSampled,
  if: ({ profileId, data }) => profileId === null || data.payload.length === 0,
  then: createDefaultProfileFx,
  else: gotoView('app'),
})

// redirect to application after creating default profile
forward({
  from: createDefaultProfileFx.done,
  to: gotoView('app'),
})

//
// trigger Redux initial state, after login
//

// get inital redux state when account is loaded
gotoView('app').watch(() => {
  setInitialState()
})

// Clear URL and recently watched channels after logout request resolved or rejected

logoutFx.finally.watch(() => {
  // maybe move recentlyWatched store to effector model
  Storage.removeItem('recentTVChannelsIds')
  Storage.removeItem('recentCatchupsData')
  window.location.replace('/')
})
