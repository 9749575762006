import React, { useMemo } from 'react'
import { faLock } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Image from '../../Image'
import {
  getRandomLockedChannelPoster,
  getChannelLogo,
  getChannelLogoSmall,
} from '~/utils/helpers'
import '../styles.scss'

interface TvChannelCard {
  handleClick: () => void
  channelLogoUrl: string | null
  locked?: boolean
  className?: string
  channelTitle: string
}

export const TvChannelCard = ({
  locked,
  channelLogoUrl,
  handleClick,
  channelTitle,
  className,
}: TvChannelCard) => {
  const lockedImage = useMemo(
    () => getRandomLockedChannelPoster(),
    [channelLogoUrl]
  )

  return (
    <div
      className={`card tv-channel-card ${className ? className : ''}`}
      onClick={handleClick}
    >
      {locked ? (
        <>
          <img src={lockedImage} />
          <FontAwesomeIcon icon={faLock} className={'locked_icon'} />
        </>
      ) : channelLogoUrl ? (
        <Image
          imageUrl={getChannelLogo(channelLogoUrl)!}
          defaultUrl={'/img/noLogo.svg'}
          secondaryImageUrl={getChannelLogoSmall(channelLogoUrl)!}
        />
      ) : (
        <img src={'/img/noLogo.svg'} />
      )}
      <div className='show-on-hover'>{channelTitle}</div>
    </div>
  )
}
