import React from 'react'
import { connect } from 'react-redux'
import * as epgActionCreators from './epgActions'
import { useTranslation } from 'react-i18next'
import SearchField from '../common/SearchField'

const EpgSearchBar = ({ inputValue, search }) => {
  const { t } = useTranslation('epg')
  return (
    <SearchField
      placeholder={t('Search channel')}
      handleSearch={inputValue}
      search={search}
    />
  )
}

function mapStateToProps(state) {
  return {
    search: state.views.epg.search,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    inputValue: (value) => dispatch(epgActionCreators.inputValue(value)),
  }
}

export const EpgSearchBarContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EpgSearchBar)
