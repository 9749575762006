import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import SearchField from '../common/search-field'

import { actions as tvActions } from '~/components/tv/store'

const TvSearchBar = ({ inputValue, search, callback }) => {
  const { t } = useTranslation('tv')
  useEffect(() => {
    return () => {
      inputValue('')
    }
  }, [])

  return (
    // <div className='search-bar-wrapper search-bar-wrapper--tvShows'>
    <>
      <SearchField
        placeholder={t('Search channel')}
        handleSearch={(search) => {
          inputValue(search)
          if (typeof callback === 'function') callback(search.q)
        }}
        search={search}
      />
      {/* </div> */}
    </>
  )
}

function mapStateToProps(state) {
  return {
    search: state.views.tv.search,
  }
}

const mapDispatchToProps = {
  inputValue: tvActions.setSearch,
}

export const TvSearchBarContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TvSearchBar)
