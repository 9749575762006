import * as ezdrm from './ezdrm'
import * as unsupported from './unsupported'

export const widevine = <T>(provider: string, options: T): T =>
  ((
    {
      EZDRM: ezdrm.widevine,
    }[provider] || unsupported.widevine
  )(options))

export const playready = <T>(provider: string, options: T): T =>
  ((
    {
      EZDRM: ezdrm.playready,
    }[provider] || unsupported.playready
  )(options))

export const fairplay = <T>(provider: string, options: T): T =>
  ((
    {
      EZDRM: ezdrm.fairplay as any,
    }[provider] || unsupported.fairplay
  )(options))
