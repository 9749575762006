import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { config, customize } from '/config'
import Storage from '../../utils/storage'
import ISO6391 from './index'
import en from './en/index'
import es from './es/index'
import fr from './fr/index'
import pt from './pt/index'

export const resources = { en, es, fr, pt }
export const supportedLngs = Object.keys(resources)

/**
 * Default language
 * @type {string}
 */
export const DEFAULT_LNG = supportedLngs[0] || 'en'

/**
 * Initialization i18n
 */
i18n
  .use(initReactI18next)
  .use({
    type: 'languageDetector',
    init: () => undefined,
    detect: () => {
      if (!config.APP_LANGUAGE_ENABLED) return DEFAULT_LNG

      const language = Storage.getItem('appLanguage')
      if (language) return language

      if (customize.languages.defaultLanguage) {
        const code = customize.languages.defaultLanguage
        if (ISO6391.findLanguage(code)) return code
      }

      if (typeof navigator !== 'undefined' && navigator.language) {
        const code = navigator.language
        if (ISO6391.findLanguage(code)) return code
      }

      return DEFAULT_LNG
    },
    cacheUserLanguage: (language) => {
      Storage.setItem('appLanguage', language)
    },
  })
  .init({
    resources,
    ns: [
      'common',
      'auth',
      'home',
      'tv',
      'vod',
      'tvshow',
      'catchup',
      'epg',
      'setting',
    ],
    fallbackLng: DEFAULT_LNG,
    nsSeparator: ':',
    defaultNS: 'common',
    fallbackNS: 'common',
    interpolation: {
      escapeValue: false,
    },
  })

export default i18n
