import axios from 'axios'
import { domain, forward, sample, restore, guard } from '/models'
import { ErrorI } from '../interfaces'
import { WARNING } from '/utils/constants'

const api = '/api/v3'
const password = `${api}/account/password`

const { createEffect, createEvent, createStore } = domain('password')

// *
// * events
// *

export const initiateResetPass = createEvent<string>()
export const verifyPass = createEvent<string>()
export const resendCode = createEvent()
export const resetPass = createEvent<string>()
export const reset = createEvent()

// *
// * effects
// *

export const initiateResetPassFx = createEffect((email: string) =>
  axios.post(`${password}/initiate`, { email })
)

export const verifyPassFx = createEffect((data) =>
  axios.post(`${password}/verify`, data)
)

const resendCodeFx = createEffect((id) =>
  axios.post(`${password}/resend`, { id })
)

export const resetPassFx = createEffect((data) =>
  axios.post(`${password}/reset`, data)
)

// *
// * stores
// *

export const $sessionID = createStore<string | null>(null).on(
  initiateResetPassFx.doneData,
  (_, { data }) => {
    return data?.payload?.id || null
  }
)

export const $email = restore(initiateResetPass, null)
const $resetCode = restore(verifyPass, null)

export const $forgotPassError = createStore<ErrorI | null>(null)
  .on(
    [
      initiateResetPassFx.failData,
      verifyPassFx.failData,
      resendCodeFx.failData,
      resetPassFx.failData,
    ],
    (_, { response }: any) => ({
      code: response?.data?.errorCode,
      payload: response?.data?.payload,
      message: response?.data?.message,
    })
  )
  .reset(initiateResetPassFx, verifyPassFx, resendCodeFx, resetPassFx, reset)

const $errorWG0070 = $forgotPassError.map((error) =>
  error?.code === 'WG0070' ? error : null
)

export const $warning = createStore<string | null>(null)
  .on(resetPassFx.done, () => WARNING.PASSWORD_UPDATED)
  .reset(reset)

// counter move to separate store

const SECONDS = 60
const decreaseSeconds = createEvent()

export const $seconds = createStore(0)
  .on([initiateResetPassFx.doneData, resendCodeFx.doneData], () => SECONDS)
  .on($errorWG0070, (state, error) => error?.payload?.remainsSeconds || state)
  .on(decreaseSeconds, (state) => state - 1)

const intervalFx = createEffect(
  () => new Promise((resolve) => setTimeout(resolve, 1000))
)

guard({
  source: $seconds,
  filter: Boolean,
  target: intervalFx,
})

forward({
  from: intervalFx.done,
  to: decreaseSeconds,
})

// *
// * connections
// *

forward({
  from: initiateResetPass,
  to: initiateResetPassFx,
})

sample({
  clock: verifyPass,
  source: $sessionID,
  fn: (id, resetCode) => ({ id, resetCode }),
  target: verifyPassFx,
})

sample({
  clock: resendCode,
  source: $sessionID,
  target: resendCodeFx,
})

sample({
  clock: resetPass,
  source: [$sessionID, $resetCode],
  fn: ([id, resetCode], password) => ({ id, resetCode, password }),
  target: resetPassFx,
})
