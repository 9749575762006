import { domain } from '/models'
import { ModalI } from './interfaces'

const { createEvent, createStore } = domain('modal')

export const setModal = createEvent<ModalI>()

export const popModal = createEvent()

export const $modalsList = createStore<ModalI[] | []>([])
  .on(setModal, (state, modal) => [...state, modal])
  .on(popModal, (state) => {
    const stack = [...state]
    stack.pop()
    return stack
  })
