import type { AxiosResponse } from 'axios'
import type { Announcements } from './announcements'
import axios from 'axios'
import { domain, forward, TAKE_FIRST } from '.'
import { formatDateForAnnouncement } from '../utils/date'
import { replaceTokens } from '../utils/helpers'

// create child domain
const { createEffect, createEvent, createStore, createReEffect } = domain('toa')

// *
// * types
// *

type TOA = {
  title: string
  message: string
  type: 'OK' | 'OK_CANCEL'
}

// *
// * events
// *

export const getToa = createEvent()
export const setToa = createEvent<TOA>()

// *
// * effects
// *

export const getToaFx = createReEffect<void, AxiosResponse<Announcements>>({
  strategy: TAKE_FIRST,
  handler: () => axios.get('api/account/toa'),
})

export const acceptToaFx = createReEffect({
  strategy: TAKE_FIRST,
  handler: () => axios.post('api/account/toa', { accepted: true }),
})

export const declineToaFx = createEffect({
  handler: () => {},
})

// *
// * stores
// *

export const $toa = createStore<TOA | null>(null).on(setToa, (_, toa) => toa)

// *
// * connections
// *

// start effect on event
forward({
  from: getToa,
  to: getToaFx,
})

// fill $toa store on effect resolution
forward({
  from: getToaFx.doneData.map((payload) => payload.data),
  to: setToa.prepend(({ announcements, tokens }) => {
    const announcement = announcements
      .filter((message) => message.type === 'Activation_TOA')
      .pop()

    tokens.$expirationDate = formatDateForAnnouncement(tokens.$expirationDate)

    return {
      title: replaceTokens(announcement!.subject, tokens),
      message: replaceTokens(announcement!.message, tokens),
      type: announcement!.buttonType,
    }
  }),
})
