import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { fetchEPG, fetchTvLiveRewindUrl } from '/services/api/channels'
import { fetchFeaturedCarousels } from '/services/api/v3/carousels'
import {
  fetchChannelsByCategory,
  fetchChannelUrl,
  fetchCategories,
} from '/services/api/v3/channels'

import { actions as tvActions } from '~/components/tv/store'
import { sortArray, parseJson } from '/utils/helpers'
import Storage from '/utils/storage'
import { TvChannel } from '/components/tv/interfaces'
import { Movie } from '~/components/vod/interfaces'
import { TvShow } from '~/components/tvShows/interfaces'

export const getChannelsCategories = createAsyncThunk(
  'tv/getChannelsCategoriesRequest',
  async (_, { rejectWithValue }) => {
    try {
      return await fetchCategories()
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

export const getCarousels = createAsyncThunk(
  'tv/getCarouselsRequest',
  async (_, { rejectWithValue }) => {
    try {
      const result = await fetchFeaturedCarousels()
      return result.payload
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

export const changeChannelsCategory = createAsyncThunk(
  'tv/changeChannelsCategoryRequest',
  async (categoryId: number, { dispatch, rejectWithValue }) => {
    try {
      dispatch(tvActions.setActiveCategory(categoryId))
      return await fetchChannelsByCategory(categoryId)
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

export const getChannelEPG = createAsyncThunk(
  'tv/getChannelEPGRequest',
  async (channel: any, { dispatch, rejectWithValue }) => {
    try {
      const { EPG, activeProgram } = await fetchEPG(channel)
      dispatch(tvActions.setActiveProgram(activeProgram))
      return EPG
    } catch (e) {
      dispatch(tvActions.setActiveProgram(null))
      return rejectWithValue(e)
    }
  }
)

export const changeChannel = createAsyncThunk(
  'tv/changeChannelRequest',
  async (channel: TvChannel, { dispatch, rejectWithValue }) => {
    try {
      dispatch(tvActions.setActiveChannel(channel))
      dispatch(tvActions.setEPG(null))
      const result = await fetchChannelUrl(channel.id)
      dispatch(getChannelEPG(channel))
      return result.payload
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

export const rewindTvChannel = createAsyncThunk(
  'tv/rewindTvChannelRequest',
  async (
    { channel, startTime }: { channel: TvChannel; startTime: number },
    { rejectWithValue }
  ) => {
    try {
      return fetchTvLiveRewindUrl(channel, startTime)
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

export const getRecentlyWatched = createAsyncThunk(
  'tv/getRecentlyWatched',
  async (ids: number[], { rejectWithValue }) => {
    try {
      const channels = await fetchChannelsByCategory(0, ids)
      const storedIds = Storage.getItem('recentTVChannelsIds')
      let list = []
      if (storedIds) {
        list = parseJson(storedIds) || []
      }
      return sortArray(channels, list)
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

export const updateFeaturedVodIsFavorite = createAction<Movie>(
  'tv/featuredVodUpdate'
)

export const updateFeaturedShowIsFavorite = createAction<TvShow>(
  'tv/featuredShowUpdate'
)
