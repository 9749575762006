import { useEffect, useMemo } from 'react'
import ReactDOM from 'react-dom'

interface PortalProps {
  className: string
  children: JSX.Element | JSX.Element[]
}
export const Portal = ({ className, children }: PortalProps): JSX.Element => {
  const el = useMemo(() => document.createElement('div'), [])

  useEffect(() => {
    const classList = ['portal']
    if (className) className.split(' ').forEach((item) => classList.push(item))
    classList.forEach((item) => el.classList.add(item))

    document.body.appendChild(el)
    return () => {
      document.body.removeChild(el)
    }
  }, [el])

  return ReactDOM.createPortal(children, el)
}
