import { createSlice, original } from '@reduxjs/toolkit'
import * as catchupActions from '/components/catchup/store/actions'
import {
  getCatchupsList,
  changeCatchupChannel,
  getProgramData,
  getRecentlyWatched,
} from '/components/catchup/store/actions'
import { Catchup, CatchupProgram, CatchupCard } from '../interfaces/index'
import { mapCatchupsToDays } from '../../../utils/catchups'

interface SliceState {
  search: string
  activeChannel: Catchup | Record<string, never>
  activeDay: string
  activeProgram: CatchupProgram | Record<string, never>
  url: string

  channels: Catchup[]
  days: { [key: string]: CatchupProgram[] }
  programs: CatchupProgram[]
  recentlyWatched: CatchupCard[]
}

const initialState: SliceState = {
  search: '',
  activeChannel: {},
  activeDay: '',
  activeProgram: {},
  url: '',

  channels: [],
  days: {},
  programs: [],
  recentlyWatched: [],
}

const catchupSlice = createSlice({
  name: 'catchup',
  initialState,
  reducers: {
    setActiveChannel: (state, { payload }) => {
      state.activeChannel = payload
    },
    setPrograms: (state, { payload }) => {
      state.programs = payload
    },
    setActiveProgram: (state, { payload }) => {
      state.activeProgram = payload
    },
    setDays: (state, { payload }) => {
      state.days = payload
    },
    setActiveDay: (state, { payload }) => {
      state.activeDay = payload
    },
    setSearchQuery: (state, { payload }) => {
      state.search = payload
    },
    unlockChannel: (state) => {
      const channel = original(state.activeChannel)
      if (channel) {
        state.activeChannel = {
          ...channel,
          locked: false,
          unlocked: true,
        }
      }
    },
  },
  extraReducers: {
    [getCatchupsList.fulfilled.toString()]: (state, { payload }) => {
      state.channels = payload
    },
    [getCatchupsList.rejected.toString()]: (state) => {
      state.channels = initialState.channels
    },
    [changeCatchupChannel.fulfilled.toString()]: (state, { payload }) => {
      state.days = mapCatchupsToDays(payload)
    },
    [changeCatchupChannel.rejected.toString()]: (state) => {
      state.days = initialState.days
    },
    [getProgramData.fulfilled.toString()]: (state, { payload }) => {
      state.activeProgram = payload
    },
    [getProgramData.rejected.toString()]: (state) => {
      state.activeProgram = initialState.activeProgram
    },
    [getRecentlyWatched.fulfilled.toString()]: (state, { payload }) => {
      state.recentlyWatched = payload
    },
    [getRecentlyWatched.rejected.toString()]: (state) => {
      state.recentlyWatched = initialState.recentlyWatched
    },
  },
})

export const actions = {
  ...catchupSlice.actions,
  ...catchupActions,
}

export const reducer = catchupSlice.reducer
