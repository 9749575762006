import React, { useEffect, useState } from 'react';

const Image = ({
                 imageUrl,
                 defaultUrl,
                 className = '',
                 secondaryImageUrl = '',
                 debugID,
               }: {
  imageUrl: string
  defaultUrl: string
  secondaryImageUrl?: string
  className?: string,
  debugID?: string
}) => {
  const [logoUrl, setLogoUrl] = useState<string>();
  const imagesQueue = [imageUrl, secondaryImageUrl, defaultUrl];
  let imagesPointer = 0;

  const loadImage = (url = '') => {
    const img = new window.Image();
    img.addEventListener('load', () => setLogoUrl(url), false);
    img.addEventListener('error', () => {
      imagesPointer++ < imagesQueue.length && loadImage(imagesQueue[imagesPointer]);
      imagesPointer === imagesQueue.length && setLogoUrl('');
    }, false);
    img.src = url;
  };

  useEffect(() => {
    loadImage(imageUrl);
  }, [imageUrl]);


  return (
    logoUrl !== defaultUrl ? (
      <img src={logoUrl} className={className || ''} />
    ) : (
      <div className={'no-content-image'}>
        <img src={logoUrl} />
      </div>
    )
  );
};

export default Image;
