import React from 'react'
import { useStore } from 'effector-react'
import { $announcements, removeAnnouncement } from '/models/announcements'
import { useTranslation } from 'react-i18next'

export const Notifications = () => {
  const announcements = useStore($announcements)
  const { t } = useTranslation('settings')
  return (
    <div className='notifications'>
      {announcements.map((notification, i) => (
        <div key={i} className='notification-box'>
          <div className='notification-header'>
            {t(notification.type)}
            <div
              className='notification-close'
              onClick={() => removeAnnouncement(i)}
            >
              x
            </div>
          </div>
          <div className='notification-title'>{notification.subject}</div>
          <div className='notification-body'>
            <div className='notification-message'>
              {notification?.message?.split(/<br \/>|\n/g).map((item, j) => (
                <div key={j}>{item}</div>
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}
