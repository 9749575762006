import axios from 'axios'
import { domain } from '.'

// create child domain
const { createStore, createEffect } = domain('continueWatching')

// *
// * effects
// *

export const getContinueWatchingFx = createEffect({
  handler: () =>
    axios
      .get('/api/v3/continue-watching')
      .then((response) => response.data.payload.content)
      .catch(() => []),
})

// *
// * store
// *

export const $continueWatching = createStore<any[]>([]).on(
  getContinueWatchingFx.doneData,
  (_, data) => data
)
