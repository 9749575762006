import { faTimes, faUser } from '@fortawesome/pro-regular-svg-icons'
import {
  faExclamationTriangle,
  faUserCircle,
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useStore } from 'effector-react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { $profiles } from '~/models/profiles'
import { useModal } from '..'
import s from '../dialogs.module.scss'

export const EditContentModal = ({
  name = '',
  title,
  message,
  confirmButtonTitle = 'OK',
  dismissButtonTitle = 'Cancel',
  onConfirm,
}: {
  name?: string
  title?: string
  message?: string | JSX.Element
  confirmButtonTitle?: string
  dismissButtonTitle?: string
  onConfirm: (val: string) => Promise<void>
}) => {
  const profiles = useStore($profiles)
  const [value, setValue] = useState('')
  const [valid, setValid] = useState(true)
  const { t } = useTranslation('settings')

  const { unSetModal } = useModal()
  useEffect(() => setValue(name), [])

  useEffect(() => setValid(checkIsValid(value)), [value])

  const handleChange = (e) => {
    setValue(e.target.value)
  }

  const checkIsValid = (val) => {
    const isValid =
      val !== name
        ? !profiles.find((profile) => profile.name === val) &&
          /^[a-zA-Z\d]{3,16}$/.test(val)
        : true
    return isValid
  }
  const confirm = () => {
    if (valid) onConfirm(value).then(() => unSetModal())
  }

  return (
    <div className={s.editContentWrapper}>
      <FontAwesomeIcon
        icon={faTimes}
        onClick={unSetModal}
        className={s.closeButton}
      />
      <div className={s.editContentModal}>
        <h3 className={s.title}>{title}</h3>
        <div className={s.userAvatar}>
          {name?.[0] || <FontAwesomeIcon icon={faUser} />}
        </div>
        <div className={s.content}>{message}</div>
        <div className={s.inputWrapper}>
          <FontAwesomeIcon icon={faUserCircle} />
          <div className={s.label}>
            <input
              id='name'
              type='text'
              className={`form-profile__input ${!valid ? s.invalid : ''}`}
              value={value}
              onChange={(e) => handleChange(e)}
              autoFocus={true}
            />
            <label htmlFor='name' className={`${value.length && s.active}`}>
              {t('Profile Name')}
            </label>
          </div>
        </div>
        <div className={s.actions}>
          <button
            className={'button-base button-ghost'}
            onClick={() => unSetModal()}
          >
            {t(`common:button.${dismissButtonTitle}`)}
          </button>
          <button className={'button-base button-primary'} onClick={confirm}>
            {t(`common:button.${confirmButtonTitle}`)}
          </button>
        </div>
        {!valid && (
          <div className={s.error}>
            <FontAwesomeIcon icon={faExclamationTriangle} />
            <span>
              {t(
                `Profile name must be from 3 to 16 characters, contain letters or numbers, and should be unique.`
              )}
            </span>
          </div>
        )}
      </div>
    </div>
  )
}
