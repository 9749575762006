import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Portal } from '~/components/common/Portal'
import { convertUnixTimeToDate } from '~/utils/date'
import { TvShowEpisode, TvShowSeason } from '../../interfaces'
import { useTooltip } from './context'

interface TooltipProps {
  closeTooltip: () => void
  coords: { x: number; y: number }
  content: Record<string, number | string | null> | undefined
}

export const Tooltip = ({
  closeTooltip,
  coords,
  content,
}: TooltipProps): JSX.Element => {
  const [position, setPosition] = useState<
    { left: number; top: number } | undefined
  >(undefined)
  const tooltipRef = useRef<HTMLDivElement>(null)
  const { t } = useTranslation('tvshow')

  useEffect(() => {
    tooltipRef.current
    let left, top, height, width
    if (tooltipRef.current) {
      height = tooltipRef.current.clientHeight
      width = tooltipRef.current.clientWidth
      left = coords.x - width > 24 ? coords.x - width - 15 : 24
      top =
        coords.y + height > window.innerHeight
          ? window.innerHeight - height - 25
          : coords.y
    }
    left && top && setPosition({ left: left, top: top })
  }, [content])

  useEffect(() => {
    document.addEventListener('click', handleClick)
    document.addEventListener('scroll', closeTooltip)

    return () => {
      document.removeEventListener('click', handleClick)
      document.removeEventListener('scrll', closeTooltip)
    }
  }, [])

  useEffect(() => {
    window.addEventListener('resize', closeTooltip)

    return () => window.removeEventListener('resize', closeTooltip)
  }, [])

  const handleClick = (event) => {
    event.preventDefault()
    closeTooltip()
  }
  return content ? (
    <div
      ref={tooltipRef}
      className={`tooltip-popup ${position ? 'open' : 'closed'}`}
      style={{
        left: position?.left,
        top: position?.top,
      }}
    >
      <div className='tooltip-popup__content'>
        <h3 className='tooltip-popup__title'>{`${content.displayNumber}. ${content.name}`}</h3>
        {content.year ? (
          <p className='tooltip-popup__paragraph'>
            {t('Release {{year}}', {
              year: content.year,
            })}
          </p>
        ) : null}
        {content.directors ? (
          <>
            <h4 className='tooltip-popup__title'>{t('Directors')}</h4>
            <p className='tooltip-popup__paragraph'>{content.directors}</p>
          </>
        ) : null}
        {content.stars ? (
          <>
            <h4 className='tooltip-popup__title'>{t('Stars')}</h4>
            <p className='tooltip-popup__paragraph'>{content.stars}</p>
          </>
        ) : null}
        {content.description ? (
          <>
            <h4 className='tooltip-popup__title'>{t('Description')}</h4>
            <p className='tooltip-popup__paragraph'>{content.description}</p>
          </>
        ) : null}
      </div>
    </div>
  ) : (
    <React.Fragment />
  )
}
