import React, { ReactNode } from 'react'
import cn from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/pro-regular-svg-icons'

import './styles.scss'

interface IButtonProps {
  icon?: ReactNode
  active?: boolean
  isDisabled?: boolean
  ticked?: boolean
  handleClick?: () => void
  className?: string
  title?: string | JSX.Element
  type?: 'button' | 'submit' | 'reset' | undefined
  loading?: boolean
}

export const Button = ({
  className,
  active,
  ticked,
  handleClick,
  title,
  type,
  icon,
  isDisabled,
  loading,
}: IButtonProps): JSX.Element => {
  return (
    <button
      className={cn(className, {
        'button--active': active,
        'button--loading': loading,
      })}
      type={type}
      disabled={isDisabled}
      onClick={handleClick}
    >
      {!loading &&
        (typeof title === 'string' ? (
          <>
            <span className='button__title'>{title}</span>
            {icon}
          </>
        ) : (
          title
        ))}
      {ticked && <FontAwesomeIcon icon={faCheck} />}
    </button>
  )
}
