import { faCheck } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useStore } from 'effector-react'
import React from 'react'
import { customize } from '/config'
import { $appLanguage, setAppLanguage } from '~/models/settings'
import { useTranslation } from 'react-i18next'
import ISO6391 from '~/services/locales'
import i18n from '~/services/locales/i18n'
import s from '../../pages/settings.module.scss'

export const AppLanguage = () => {
  const appLanguage = useStore($appLanguage)
  const languages = ISO6391.getLanguages(customize.languages.availableLanguages)
  const { t } = useTranslation('settings')

  const handleAppLanguage = (code: string) => {
    if (appLanguage !== code) {
      i18n.changeLanguage(code).then(() => {
        setAppLanguage(code)
      })
    }
  }
  return (
    <div className={s.language}>
      <h3 className={s.title}>{t('App Language')}</h3>
      {languages.map(({ codes, nativeName }, index) => {
        const code = codes[0]
        return (
          <div
            key={`${index}${code}`}
            className={`${s.settingsCard}  ${
              appLanguage === code ? s.selected : ''
            }`}
            onClick={() => handleAppLanguage(code)}
          >
            <span className={s.cardTitle}>
              <span>{nativeName}</span>
            </span>
            <FontAwesomeIcon icon={faCheck} className={s.selectedIcon} />
          </div>
        )
      })}
    </div>
  )
}
