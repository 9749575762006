import React, {
  useState,
  useEffect,
  useRef,
  MouseEvent,
  useContext,
} from 'react'
import { useTranslation } from 'react-i18next'

import {
  CarouselContextType,
  CarouselsContext,
} from '~/components/common/carousel/carousels-context/context'

import { ChevronRightIcon } from '../icons/chevron-right-icon'
import { ChevronLeftIcon } from '../icons/chevron-left-icon'
import { Button } from '../button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLongArrowAltRight } from '@fortawesome/pro-regular-svg-icons'

import './Carousel.scss'

interface ICarouselProps {
  id: string
  carouselTitle: string
  showAllOption?: boolean
  className?: string
  children: JSX.Element[]
  handleCategoryClick?: () => Promise<void>
  scrollPosition?: number
}

export const Carousel = (props: ICarouselProps): JSX.Element => {
  const {
    id,
    carouselTitle,
    children,
    className = 'category-list',
    handleCategoryClick,
    showAllOption,
  } = props

  const { updateCarousel, addCarousel, getCarouselActivePosition } = useContext(
    CarouselsContext
  ) as CarouselContextType

  const activePosition = getCarouselActivePosition(id)
  const [scrollForwardDisabled, setScrollForwardDisabled] = useState(false)
  const [showNavigationButtons, setShowNavigationButtons] = useState(false)

  const [carouselItemWidth, setCarouselItemWidth] = useState(0)
  const [cardsOnScreen, setCardsOnScreen] = useState(0)

  const carouselRef = useRef(null)
  const { t } = useTranslation()

  let currentCarousel

  let draggable = false
  let position
  let startPosition

  useEffect(() => {
    activePosition === undefined && addCarousel(id)
  })

  useEffect(() => {
    if (currentCarousel && activePosition && carouselItemWidth)
      currentCarousel.scrollLeft = activePosition * carouselItemWidth
  }, [activePosition])

  useEffect(() => {
    currentCarousel = carouselRef.current
    if (currentCarousel) {
      setCarouselItemWidth(currentCarousel?.children[0]?.offsetWidth)
      setCardsOnScreen(
        Math.round(currentCarousel.offsetWidth / carouselItemWidth)
      )

      setShowNavigationButtons(currentCarousel.children?.length > cardsOnScreen)
      setScrollForwardDisabled(
        currentCarousel.children?.length - cardsOnScreen <= activePosition!
      )
    }
  })

  useEffect(() => {
    if (currentCarousel && activePosition) {
      currentCarousel.scrollLeft = activePosition * carouselItemWidth
    }
  }, [cardsOnScreen, carouselItemWidth])

  const handleClick = (direction) => {
    direction === 'prev'
      ? (currentCarousel.scrollLeft -= (cardsOnScreen - 1) * carouselItemWidth)
      : (currentCarousel.scrollLeft += (cardsOnScreen - 1) * carouselItemWidth)
  }

  const handleScroll = () => {
    if (currentCarousel && carouselItemWidth)
      updateCarousel({
        id: id,
        position: Math.round(currentCarousel.scrollLeft / carouselItemWidth),
      })
  }

  const handleMouseDown = (event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault()
    draggable = true
    startPosition = event.pageX
    position = currentCarousel.scrollLeft
  }

  const handleMouseUp = (event: React.MouseEvent<HTMLDivElement>) => {
    draggable = false
  }

  const handleMouseMoove = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!draggable) return
    event.preventDefault()
    event.stopPropagation()
    const x = event.pageX
    const step = (x - startPosition) * 2
    currentCarousel.scrollLeft = position - step
  }

  return children?.length ? (
    <div className={`carousel ${className}`}>
      <div className='carousel__title_row'>
        <h3 className='carousel__title'>{carouselTitle}</h3>
        <div className={'carousel__navigation'}>
          {showNavigationButtons && (
            <>
              <div className='carousel__navigation-arrows'>
                <Button
                  handleClick={() => handleClick('prev')}
                  isDisabled={activePosition === 0}
                  className={'carousel__navigation-button'}
                  title={<ChevronLeftIcon />}
                />
                <Button
                  handleClick={() => handleClick('next')}
                  isDisabled={scrollForwardDisabled}
                  className={'carousel__navigation-button '}
                  title={<ChevronRightIcon />}
                />
              </div>
              {showAllOption && (
                <Button
                  className='carousel__navigation-button view-all__button'
                  title={
                    <>
                      <span className='button__text'>{t('View All')}</span>
                      <FontAwesomeIcon icon={faLongArrowAltRight} />
                    </>
                  }
                  handleClick={handleCategoryClick as () => Promise<void>}
                />
              )}
            </>
          )}
        </div>
      </div>
      <div
        className={`carousel__content_wrapper compact `}
        ref={carouselRef}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMoove}
        onMouseLeave={handleMouseUp}
        onScroll={handleScroll}
      >
        {children.map((child, index) => (
          <div
            className={`carousel__item ${
              index === activePosition ? 'active' : ''
            }`}
            key={`${index}__${index}`}
            // onClick={() => handleCarouselCard(categoryId, activePosition)}
          >
            {child}
          </div>
        ))}
      </div>
    </div>
  ) : (
    <React.Fragment />
  )
}
