import { persist } from 'effector-storage/local/fp'
import { domain } from '.'

// create child domain
const { createEvent, createStore } = domain('player')

// *
// * events
// *

export const setVolume = createEvent<{ level: number; mute: boolean }>()
export const enterPip = createEvent<Event | undefined>()
export const leavePip = createEvent<Event | undefined>()

// *
// * stores
// *

export const $volume = createStore({ level: 0.5, mute: false })
  .on(setVolume, (_, volume) => volume)
  .thru(
    persist({
      key: 'volume',
      deserialize: (value) => JSON.parse(value) || $volume.defaultState,
    })
  )

export const $pip = createStore(false)
  .on(enterPip, () => true)
  .reset(leavePip)
