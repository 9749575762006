import React, { useEffect } from 'react'
import { Link } from '@reach/router'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Player } from '~/components/common/player/Player'
import { getChannelLogo, parseJson } from '~/utils/helpers'
import { actions as catchupActions } from '../store/'
import { getActiveChannel } from '/components/catchup/store/selectors'
import { formatDateForEPG } from '~/utils/date'
import { PlayerPreloader } from '~/components/common/Preloaders'
import Storage from '/utils/storage'
import { GEOBLOCK_MESSAGE } from '/utils/constants'

const noLogo = '/img/noLogo.svg'

export const CatchupPlayerPageContainer = (props: {
  activeProgram
  activeChannel
  channels
  channelId
  catchupId
  programId
  setActiveProgram
  getProgramData
}): JSX.Element | null => {
  const {
    activeProgram,
    activeChannel,
    channels,
    channelId,
    programId,
    setActiveProgram,
    getProgramData,
  } = props
  const { t } = useTranslation()

  useEffect(() => {
    const channel = channels.find(
      (item) => Number(channelId) === item.channelId
    )
    if (channel) {
      getProgramData({ channel, programId })
    }
  }, [channels, channelId, programId])

  useEffect(() => {
    return () => {
      setActiveProgram({})
    }
  }, [])

  const saveRecentCatchupsData = (catchup, programId) => {
    if (catchup.unlocked) return

    const data = {
      id: catchup.id,
      channelId: catchup.channelId,
      programId: programId,
    }

    const existedList = Storage.getItem('recentCatchupsData')
    let list = []
    if (existedList) {
      list = parseJson(existedList) || []
    }
    const unique = [data]
      .concat(list)
      .filter(
        (item, i, arr) =>
          i === arr.findIndex((c) => c.programId === item.programId)
      )

    Storage.setItem('recentCatchupsData', unique)
  }

  useEffect(() => {
    if (activeChannel?.id && activeProgram?.id) {
      saveRecentCatchupsData(activeChannel, activeProgram.id)
    }
  }, [activeProgram?.id])

  return activeProgram ? (
    <div className='player-page'>
      <div className='breadcrumbs'>
        <Link to='/catchup'>{t('Catchups')}</Link>
        <span>{activeProgram.name}</span>
      </div>
      <div className='player-page__content'>
        <div className='player-page__player'>
          {activeChannel?.blockedByAcl ? (
            <div className='player-page__info-box'>
              <h1 className='player-page__title'>{t('common:Sorry')}!</h1>
              <p className='player-page__message'>
                {t(`common:message.${GEOBLOCK_MESSAGE}`, {
                  item: `"${activeChannel.channelName}"`,
                })}
              </p>
            </div>
          ) : activeProgram.playbackUrl ? (
            <Player
              src={activeProgram.playbackUrl}
              skin='vod'
              contentId={activeProgram.id}
              contentTitle={activeProgram.name}
              contentLive={false}
            />
          ) : (
            <PlayerPreloader />
          )}
        </div>
        <div className='channel-info'>
          <div className='channel__logo'>
            <img src={getChannelLogo(activeChannel.logoUrl) || noLogo} alt='' />
          </div>
          <div>
            <div className='channel__title'>{activeChannel.channelName}</div>
            <div className='channel__short-info'>
              <p>{activeProgram.name}</p>
              {activeProgram.startSec && activeProgram.endSec ? (
                <p>
                  {`${formatDateForEPG(
                    activeProgram.startSec * 1000
                  )}-${formatDateForEPG(activeProgram.endSec * 1000)}`}
                </p>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null
}

function mapStateToProps(state) {
  return {
    activeProgram: state.views.catchup.activeProgram,
    activeChannel: getActiveChannel(state),
    channels: state.views.catchup.channels,
    activeDay: state.views.catchup.activeDay,
  }
}

const mapDispatchToProps = {
  setActiveProgram: catchupActions.setActiveProgram,
  getProgramData: catchupActions.getProgramData,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export const CatchupPlayerPage = connector(CatchupPlayerPageContainer)
