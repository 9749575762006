import axios from 'axios'
import { isServerUnableProcess, repeatRequest, api } from '.'

import { generateEPGTimeInterval } from '../../utils/date'
import { sortEPGListByStartDate, getEPGInfoHelper } from '../../utils/epg'

function getEPGPostData(channel) {
  const hours = 24 * 7
  const { fromDate, toDate } = generateEPGTimeInterval(hours)

  return {
    channelEpgIds: [channel.epgId],
    fromDate,
    toDate,
  }
}

/**
 * Find EPG by channel
 * @param channel
 * @returns {Promise<T>}
 */
export const fetchEPG = (channel) => {
  const data = getEPGPostData(channel)
  return axios
    .post(`${api}/tv/epg`, data)
    .then((response) => {
      const EPG = sortEPGListByStartDate(response.data[channel.epgId])
      const activeProgram = getEPGInfoHelper(EPG).currentProgram

      return { EPG, activeProgram }
    })
    .catch((error) => {
      const { status } = error.response
      if (isServerUnableProcess(status)) {
        return repeatRequest(() => fetchEPG(channel))
      } else {
        return error
      }
    })
}

/**
 * Get live rewind url
 * @param channel
 * @param startTime
 * @return {Promise<T>}
 */
export const fetchTvLiveRewindUrl = (channel, startTime) =>
  axios
    .post(`/api/media/channels/${channel.id}/rewind`, {
      startTime: Math.round(startTime / 1000),
    })
    .then((response) => response.data)
    .catch((error) => {
      const { status } = error.response
      if (isServerUnableProcess(status)) {
        return repeatRequest(() => fetchTvLiveRewindUrl(channel, startTime))
      } else {
        throw error
      }
    })
