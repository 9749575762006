import type { KeySystems } from '../types'
import { uint8ArrayToString } from '../tools'

// use common widevine and playready settings
export { widevine, playready } from './common'

// adjust fairplay license acquisition
export const fairplay = <
  T extends NonNullable<KeySystems['com.apple.fps.1_0']>
>(
  options: T
): T => ({
  ...options,

  getContentId: function (emeOptions, initData) {
    const skd = uint8ArrayToString(initData)
    return skd.split(';', 2)[1]
  },

  getLicense: function (emeOptions, contentId, keyMessage, callback) {
    const licenseUrl = options.licenseUri.replace('{asset_id}', contentId)
    fetch(licenseUrl, {
      method: 'POST',
      headers: {
        'Content-type': 'application/octet-stream',
      },
      body: keyMessage,
    })
      .then((response) => {
        if (response.ok) {
          return response
        } else {
          throw response
        }
      })
      .then((response) => response.arrayBuffer())
      .then((responseBody) => callback(null, responseBody))
      .catch((error) => callback(error))
  },
})
