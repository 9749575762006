import React, { useState } from 'react'

export interface GridlInfo {
  id: string | number
  position: number
}

export type GridContextType = {
  gridPositions: GridlInfo[]
  updateGrid: (grid: GridlInfo) => void
  getGridActivePosition: (id) => number
  addGrid: (id) => void
}

export const GridContext = React.createContext({})

export const GridsProvider = ({ children }) => {
  const [gridPositions, setGridPositions] = useState<GridlInfo[]>([])

  const addGrid = (id) => {
    if (gridPositions.find((g) => g.id === id)) {
      return
    }
    setGridPositions([...gridPositions, { id: id, position: 0 }])
  }
  const updateGrid = (g) => {
    gridPositions.find((grid) => {
      if (grid.id === g.id) grid.position = g.position
    })
    setGridPositions([...gridPositions])
  }
  const getGridActivePosition = (id: string) => {
    if (gridPositions.find((g) => g.id === id))
      return gridPositions.find((g) => g.id === id)?.position
  }

  return (
    <GridContext.Provider
      value={{
        gridPositions,
        updateGrid,
        getGridActivePosition,
        addGrid,
      }}
    >
      {children}
    </GridContext.Provider>
  )
}
