import React from 'react'
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Accordion } from '../accordion'
import { useStore } from 'effector-react'
import { $account } from '~/models/account'
import { useTranslation } from 'react-i18next'
import { getExpirationTime } from '~/utils/date'
import s from '../../pages/settings.module.scss'

export const AccountInfo = () => {
  const acc = useStore($account)
  const { t } = useTranslation('settings')

  const content = {
    'Account ID': acc.accountNumber,
    Package: acc.packageName,
    'Expiration Time': getExpirationTime(acc.expirationTime),
    Devices: acc.devices,
    'WebApp Version': __APP_VERSION__,
    'Nora Version': acc.version,
    'External IP': acc.deviceExternalIP,
    Timezone: acc.timezone,
  }
  if (acc.deviseISP) content['IPS'] = acc.deviseISP

  return (
    <Accordion
      className={s.appInfo}
      toggler={
        <>
          <FontAwesomeIcon icon={faInfoCircle} />
          <span>{t('App Information')}</span>
        </>
      }
      content={content}
      title={t('App Information')}
    />
  )
}
