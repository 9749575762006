import axios from 'axios'
import { tv as api } from '.'
import { isServerUnableProcess, repeatRequest } from '..'
import {
  CatchupFromResponse,
  CatchupProgram,
  CatchupUrls,
} from '/components/catchup/interfaces'

export const fetchCatchups = (
  channelId?: number | undefined
): Promise<{ payload: CatchupFromResponse[] }> => {
  let url = `${api}/catchups`
  if (channelId) url = `${api}/${channelId}/catchups`

  return axios
    .get(url)
    .then((response) => response.data)
    .catch((error) => {
      const { status } = error.response
      if (isServerUnableProcess(status)) {
        repeatRequest(() => fetchCatchups(channelId))
      } else {
        throw error
      }
    })
}

export const fetchCatchupPrograms = (
  channelId: number | string,
  catchupId: number | string
): Promise<{ payload: CatchupProgram[] }> => {
  return axios
    .get(`${api}/${channelId}/catchups/${catchupId}/programmes`)
    .then((response) => response.data)
    .catch((error) => {
      const { status } = error.response
      if (isServerUnableProcess(status)) {
        repeatRequest(() => fetchCatchupPrograms(channelId, catchupId))
      } else {
        throw error
      }
    })
}

export const fetchCatchupUrl = (
  channelId: number | string,
  catchupId: number | string,
  programmeId: number | string
): Promise<{ payload: CatchupUrls[] }> => {
  return axios
    .get(
      `${api}/${channelId}/catchups/${catchupId}/programmes/${programmeId}/url`
    )
    .then((response) => response.data)
    .catch((error) => {
      const { status } = error.response
      if (isServerUnableProcess(status)) {
        repeatRequest(() => fetchCatchupUrl(channelId, catchupId, programmeId))
      } else {
        throw error
      }
    })
}
