import axios from 'axios'
import { carousels as api } from '.'

/**
 * Get banner carousel data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const fetchBannerCarousel = () => {
  return axios.get(`${api}/banners`).then((response) => response.data)
}

// export const fetchBannerCarousel = (dispatch) =>
//   axios
//     .get(`${api}/banners`)
//     .then((response) => {
//       if (response) {
//         const { payload } = response.data
//         dispatch(homeActionCreators.bannerCarouselSuccess(payload[0]))
//       }
//     })
//     .catch(() => dispatch(homeActionCreators.bannerCarouselError()))

/**
 * Get featured carousels data
 * @returns {Promise<T>}
 */
export const fetchFeaturedCarousels = () =>
  axios
    .get(`${api}/featured`)
    .then((response) => response.data)
    .catch((error) => error)

/*
export const fetchFeaturedCarousels = (dispatch) =>
  axios
    .get(`${api}/featured`)
    .then((response) => {
      if (response) {
        const { payload } = response.data
        dispatch(tvActionCreators.featuredCarouselsSuccess(payload))
      }
    })
    .catch(() => dispatch(tvActionCreators.featuredCarouselsError()))
*/
