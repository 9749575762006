import React from 'react'
import { useStore } from 'effector-react'
import { combine } from 'effector'
import pickBy from 'lodash/pickBy'
import {
  geoBlock,
  registrationFailed,
  $registrationDone,
} from '/models/registration'
import { geoBlockWBS } from '/models/login'
import { FormBox } from './components/forms'
import { Confirmation } from './components/confirmation'
import { ErrorScreen } from './components/error_components/ErrorScreen'
import { isEmpty } from '/utils/helpers'

import s from './login.module.scss'

export const $globalError = combine(
  geoBlockWBS,
  geoBlock,
  registrationFailed,
  (geoBlockWBS, geoBlock, registrationFailed) => {
    const error = pickBy({ geoBlockWBS, geoBlock, registrationFailed })
    return isEmpty(error) ? null : error
  }
)

export const Register = (): JSX.Element => {
  const globalError = useStore($globalError)
  const registrationDone = useStore($registrationDone)

  return (
    <div className={s.loginPage}>
      {globalError ? (
        <ErrorScreen error={globalError} />
      ) : registrationDone ? (
        <Confirmation />
      ) : (
        <FormBox />
      )}
    </div>
  )
}
