import { useEffect, useRef, useState } from 'react'

export function usePrevious<T>(value: T): T | undefined {
  const ref = useRef<T>()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export function useModal(): {
  isModalOpened: boolean
  openModal: () => void
  closeModal: () => void
} {
  const [isModalOpened, setModalOpen] = useState<boolean>(false)

  const openModal = () => setModalOpen(true)
  const closeModal = () => setModalOpen(false)

  return { isModalOpened, openModal, closeModal }
}

/**
 * IntersectionObserver hook
 */
interface IntersectionObserverProps {
  root?: HTMLDivElement | null
  rootMargin?: string
  threshold?: number
  trackVisibility?: boolean
  delay?: number
}
export const useIntersectionObserver = ({
  root = null,
  rootMargin = '0px',
  threshold = 0,
  trackVisibility = false,
  delay = 0,
}: IntersectionObserverProps) => {
  const [node, setNode] = useState<HTMLElement | null>(null)
  const [entry, updateEntry] = useState<IntersectionObserverEntry | null>(null)
  const [dependence, setDependence] = useState<number | null>(null)

  const observer = new IntersectionObserver(
    ([entry]) => {
      updateEntry(entry)
    },
    {
      root,
      rootMargin,
      threshold,
      trackVisibility,
      delay,
    }
  )

  useEffect(() => {
    if (observer && node) {
      observer.observe(node)
    }

    return () => {
      if (observer && node) {
        observer.unobserve(node)
      }
    }
  }, [node, dependence])

  return [setNode, entry, setDependence]
}
